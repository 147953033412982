
import { defineComponent, ref } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ["update"],
	setup(props, { emit }) {
		//variables
		const elementRef = ref(props.element);
		const edit = ref(false);
		function editElement($event: any) {
			edit.value = true;
		}
		return {
			editElement,
			elementRef,
			edit
		};
	}
});
