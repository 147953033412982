import { prizeStore } from "@/store/prizeStore";
import { Prize } from "@/types/prize";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { blankContainer } from "./use-containers";
import { vats } from "../../templates/vatTemplates";
import { RequestByCampaignID } from "@/types/requests";

export function allPrizes(): ComputedRef<Prize[]> {
	const items = prizeStore.getItems;
	return items;
}
export function selectPrize(itemID: string): void {
	prizeStore.commitSelectedItemID(itemID);
}
export function selectedPrize(): ComputedRef<Prize | undefined> {
	return prizeStore.getSelectedItem;
}
export async function getPrizes(params: RequestByCampaignID): Promise<boolean> {
	return prizeStore.dispatchGetItems(params);
}
export async function createPrize(
	item: CampaignObjectRequest
): Promise<Response> {
	return await prizeStore.dispatchCreateItem(item);
}

export async function updatePrize(item: Prize): Promise<Response> {
	return await prizeStore.dispatchUpdateItem(item);
}

export async function deletePrize(delObj: RequestByID): Promise<Response> {
	return await prizeStore.dispatchDeleteItem(delObj);
}

export function blankPrize(): Prize {
	const prize: Prize = {
		name: "",
		redemptionWalletID: "",
		isWinningPrize: true,
		gameMechanicType: "spin-to-win",
		redemptionType: "scan-qr-code",
		allocatedComponent: vats.prize,
		redeemedComponent: vats.redeemed,
		expiredComponent: vats.expired,
		gameplayFaceCard: vats.gamplay,
		outcomeFaceCard: blankContainer(),
		redemptionFaceCard: vats.redemption,
		apiKey: "",
		nftTemplateID: "",
		nftTemplates: [],
		nftURL: "https://api.momint.so",
		expiryDate: new Date().toISOString()
	};
	return prize;
}

export function validationRules() {
	const rules = {
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 1,
				max: 50,
				message: "Length should be 1 to 50",
				trigger: "blur"
			}
		]
	};
	return rules;
}
