import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a88b343a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "content-main",
  style: {"padding-top":"20px"}
}
const _hoisted_2 = { class: "bread-crumb" }
const _hoisted_3 = { class: "panel stretch-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNavigation = _resolveComponent("SideNavigation")!
  const _component_AssetManagerComponent = _resolveComponent("AssetManagerComponent")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_ctx.campaignID && _ctx.campaignRef)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SideNavigation),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, "Campaigns / " + _toDisplayString(_ctx.campaignRef.name) + " / Assets", 1)
        ]),
        _createVNode(_component_a_row, {
          gutter: 10,
          style: {"height":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_AssetManagerComponent, { campaignID: _ctx.campaignID }, null, 8, ["campaignID"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}