
import { selectedContainer } from "@/composables/models/use-containers";
import { selectedCustomPage } from "@/composables/models/use-customPages";
import { Asset } from "@/types/asset";
import { Container, CustomPage } from "@/types/customPage";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";

export default defineComponent({
	props: {},
	components: { AssetManagerComponent },
	emits: ["update"],

	setup(props, { emit }) {
		//variables
		let container = selectedContainer();
		let selectedPage = selectedCustomPage();
		let containerRef = ref<Container | null>();
		let selectedPageRef = ref<CustomPage | null>();
		const showModal = ref(false);
		const route = useRoute();

		const campaignID = ref(route.params.campaignID.toString());

		onMounted(() => {
			containerRef.value = container.value;
			selectedPageRef.value = selectedPage.value;
		});

		watch(container, () => {
			containerRef.value = container.value;
		});

		function chooseAsset() {
			showModal.value = true;
		}
		function setAsset(asset: Asset) {
			containerRef.value!.attributes.src = asset.url;
			showModal.value = false;
		}
		function updatePage() {
			emit("update");
		}

		//dispatchers

		//getters

		//functions

		return {
			containerRef,
			chooseAsset,
			setAsset,
			updatePage,
			showModal,
			campaignID
		};
	}
});
