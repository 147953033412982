<template>
	<div>
		<a-row :gutter="10">
			<a-col :span="12"><small>Prize</small></a-col>
			<a-col
				:span="6"
				:style="{ color: total == 100 ? '#1890ff' : 'red' }"
				><small
					>{{ total }}% ({{ (100 - total).toFixed(3) }})</small
				></a-col
			>
			<a-col class="align-right" :span="6"><small>VATs</small></a-col>
		</a-row>
		<!-- <a-row :gutter="10">
			<a-col :span="12"> </a-col>
			<a-col
				:span="12"
				:style="{ color: total == 100 ? '#1890ff' : 'red' }"
				>{{ total }}% ({{ 100 - total }})</a-col
			>
		</a-row> -->
		<a-row
			:gutter="10"
			v-for="(prize, index) in prizeSplitRef"
			:key="prize.categoryID"
		>
			<a-col :span="12">
				<p>{{ prize.name }}</p>
			</a-col>
			<a-col :span="6">
				<a-input-number
					class="custom-input-style"
					v-model:value="prize.percent"
					placeholder="%"
					step="0.001"
					@change="changed($event, index)"
				/>
			</a-col>
			<a-col class="align-right" :span="6">
				{{ prize.number }}
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { defineComponent, computed, ref, watchEffect, onMounted } from "vue";

export default defineComponent({
	props: {
		prizeSplit: {
			type: Object,
			required: true
		},
		categoryNumber: {
			type: Number,
			default: 10
		}
	},
	setup(props) {
		let prizeSplitRef = computed(() => props.prizeSplit);
		let total = ref(0);
		watchEffect(() => {
			// prizeSplitRef.value = props.prizeSplit;
			calculatePercentages();
		});

		function calculatePercentages() {
			const splits = prizeSplitRef.value;
			total.value = 0;

			for (let i = 0; i < splits.length; i++) {
				const percent = splits[i].percent;

				if (percent) {
					total.value += percent;
					splits[i].number = Math.round(
						(percent / 100) * props.categoryNumber
					);
				}
			}
		}

		const changed = ($event, i) => {
			const splits = prizeSplitRef.value;
			splits[i].percent = $event;
			splits[i].number = Math.round(
				($event / 100) * props.categoryNumber
			);
			total.value = 0;
			for (let i = 0; i < splits.length; i++) {
				const percent = splits[i].percent;
				total.value += percent;
			}

			// console.log($event);
			// console.log(i);
		};

		return { prizeSplitRef, changed, total };
	}
});
</script>

<style scoped>
.align-right {
	text-align: right;
}
.custom-input-style {
	width: 60px;
}
</style>
