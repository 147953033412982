
import { defineComponent } from "vue";
import { RequestByID } from "@/types/requests";
import { message } from "ant-design-vue";
import store from "@/store";
import UpdateCreateArTemplate from "@/components/forms/UpdateCreateARTemplateForm.vue";

export default defineComponent({
	props: {
		arTemplate: {
			type: Object,
			required: true
		}
	},
	emits: ["delete", "update"],
	data() {
		return {
			showModal: false,
			showDeleteModal: false,
			method: "Update"
		};
	},
	methods: {
		selectTemplate(id: string) {
			store.commit("arTemplateStore/commitSelectItemID", id);
		},
		closeModal() {
			this.showModal = false;
		},
		onCancel() {
			this.showModal = false;
		},
		onCancelDelete() {
			this.showDeleteModal = false;
		},
		onDelete() {
			this.showDeleteModal = true;
		},
		async handleDelete(id: string, campaignID: string) {
			this.showDeleteModal = false;
			const delCustomComponent: RequestByID = {
				id: id,
				campaignID: campaignID
			};
			const response = await store.dispatch(
				"arTemplateStore/dispatchDeleteItem",
				delCustomComponent
			);
			if (response) {
				if (response.success) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
			} else {
				message.error(response.message);
			}
			this.$emit("delete");
		},
		changesSaved() {
			this.showModal = false;
			this.$emit("update");
		},
		onDuplicate() {
			this.method = "Duplicate";
			this.showModal = true;
		},
		onEdit() {
			this.method = "Update";
			this.showModal = true;
		}
	},
	components: {
		UpdateCreateArTemplate
	}
});
