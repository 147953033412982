
import { ref, computed } from "vue";
import { defineComponent } from "vue";
import UpdateCreatePrizeForm from "@/components/forms/UpdateCreatePrizeForm.vue";
import { deletePrize, selectPrize } from "@/composables/models/use-prizes";
import { RequestByID } from "@/types/requests";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { selectLayout } from "@/composables/models/use-containers";

export default defineComponent({
	props: {
		prize: {
			type: Object,
			required: true
		}
	},
	emits: ["delete", "update"],
	setup(props, { emit }) {
		//variables
		const showModal = ref(false);
		const router = useRouter();
		const prizeRef = computed(() => props.prize);
		let showDeleteModal = ref(false);
		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			showModal.value = false;
		};
		const onCancelDelete = () => {
			showDeleteModal.value = false;
		};
		const onDelete = () => {
			showDeleteModal.value = true;
		};
		const handleDelete = (id: string, campaignID: string) => {
			showDeleteModal.value = false;
			const delPrize: RequestByID = {
				id: id,
				campaignID: campaignID
			};
			deletePrize(delPrize).then((response: Response) => {
				if (response.success === true) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				emit("delete");
			});
		};
		const changesSaved = () => {
			showModal.value = false;
			emit("update");
		};

		function gotoPrizeConfigurator(prizeID: string) {
			selectPrize(prizeID);
			selectLayout(prizeRef.value!.unAllocatedComponent!);

			// router.push({
			// 	name: "Prizes",
			// 	params: { campaignID: prizeRef.value.campaignID }
			// });
		}

		return {
			onCancel,
			showModal,
			prizeRef,
			showDeleteModal,
			handleDelete,
			onDelete,
			onCancelDelete,
			changesSaved,
			gotoPrizeConfigurator
		};
	},
	components: {
		UpdateCreatePrizeForm
	}
});
