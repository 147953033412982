
import { defineComponent, onBeforeMount, ref } from "vue";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import { useRoute } from "vue-router";
import store from "@/store";

export default defineComponent({
	setup() {
		//variables
		const route = useRoute();
		const campaignID = ref();
		const campaignRef = ref();
		//dispatchers

		//getters

		//functions
		onBeforeMount(async () => {
			campaignID.value = route.params.campaignID;
			// campaignRef.value = selectCampaign(campaignID.value).value;
			campaignRef.value = store.getters["campaignStore/getSelectedItem"];
		});

		return { campaignID, campaignRef };
	},
	components: {
		AssetManagerComponent,
		SideNavigation
	}
});
