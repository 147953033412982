
import { selectedContainer } from "@/composables/models/use-containers";
import { selectedCustomPage } from "@/composables/models/use-customPages";
import { allDropPools, getDropPool } from "@/composables/models/use-dropPools";
import { Container, CustomPage } from "@/types/customPage";
import { DropPool } from "@/types/dropPool";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	props: {},
	components: {},
	emits: ["update"],
	setup(props, { emit }) {
		//variables
		const route = useRoute();
		let container = selectedContainer();
		let selectedPage = selectedCustomPage();
		let containerRef = ref<Container | null>();
		let selectedPageRef = ref<CustomPage | null>();
		let dropPools = allDropPools();
		let dropPoolsRef = ref<DropPool[] | []>();
		const disabled = ref(false);

		onMounted(() => {
			getDropPool({ campaignID: route.params.campaignID.toString() });
			containerRef.value = container.value;
			selectedPageRef.value = selectedPage.value;
		});

		watch(container, () => {
			containerRef.value = container.value;
		});
		watch(dropPools, () => {
			dropPoolsRef.value = dropPools.value.filter(
				e => e.dropType == "shareReward"
			);
		});

		//dispatchers

		//getters

		//functions
		function actionChanged($event: any) {
			console.log($event);
			containerRef.value!.attributes.id = "";

			if (containerRef.value?.action.type == "drop-items") {
				containerRef.value.action.value = "drop-items";
				containerRef.value.attributes.id = "drop-items";
			} else if (containerRef.value?.action.type == "claim-item") {
				containerRef.value.action.value = "claim-item";
				containerRef.value.attributes.id = "claim-item";
			} else if (containerRef.value?.action.type == "redeem-item") {
				containerRef.value.action.value = "redeem-item";
				containerRef.value.attributes.id = "redeem-item";
			} else if (containerRef.value?.action.type == "redeem-momint-nft") {
				containerRef.value.action.value = "redeem-momint-nft";
				containerRef.value.attributes.id = "redeem-momint-nft";
			} else if (containerRef.value?.action.type == "redeem-url-item") {
				containerRef.value.action.value =
					"url=someurl.com?voucher={voucher-code}, target=_blank";
			} else if (containerRef.value?.action.type == "open-popup") {
				containerRef.value.action.value = "";
				containerRef.value.attributes.id = "";
			} else if (containerRef.value?.action.type == "close-popup") {
				containerRef.value.action.value = "close-popup";
				containerRef.value.attributes.id = "";
			} else if (containerRef.value?.action.type == "center-map") {
				containerRef.value.action.value = "center-map";
				containerRef.value.attributes.id = "center-map";
			} else if (containerRef.value?.action.type == "share-link") {
				containerRef.value.action.value = "Select droppool";
				containerRef.value.attributes.id = "share-link";
			} else if (containerRef.value?.action.type == "signup") {
				containerRef.value.action.value = "signup";
				containerRef.value.attributes.id = "sign-up";
			} else if (
				containerRef.value?.action.type == "signin-request-code"
			) {
				containerRef.value.action.value = "signin-request-code";
				containerRef.value.attributes.id = "signin-btn";
			} else if (containerRef.value?.action.type == "show-scanner") {
				containerRef.value.action.value = "show-scanner";
				containerRef.value.attributes.id = "show-scanner";
			} else if (containerRef.value?.action.type == "submit-pin") {
				containerRef.value.action.value = "submit-pin";
				containerRef.value.attributes.id = "submit-pin";
			} else if (containerRef.value?.action.type == "go-to-url") {
				containerRef.value.action.value = "url=URL here, target=_blank";
			} else if (containerRef.value?.action.type == "go-to-page") {
				containerRef.value.action.value = "";
			} else if (containerRef.value?.action.type == "log-out") {
				containerRef.value.action.value = "log-out";
				containerRef.value.attributes.id = "log-out";
			} else if (containerRef.value?.action.type == "resend-pin") {
				containerRef.value.action.value = "resend-pin";
				containerRef.value.attributes.id = "resend-pin";
			}
			// disabled.value = checkDisabled();
			updatePage();
		}

		function selectPoolID() {
			emit("update");
		}

		function updatePage() {
			emit("update");
		}

		return {
			containerRef,
			updatePage,
			actionChanged,
			selectPoolID,
			dropPoolsRef
			// disabled
		};
	}
});
