import { v4 as uuidv4 } from "uuid";
import { Ref, ref } from "vue";
import { customElementStore } from "@/store/containerStore";
import { Container, Action } from "@/types/customPage";
import { ComputedRef } from "vue";
import { selectedCustomPage, selectedCustomPageByURL } from "./use-customPages";
import { customPageStore } from "@/store/customPageStore";
import { selectedPrize } from "./use-prizes";

export function allCustomElements(): ComputedRef<Container[]> {
	const items = customElementStore.getItems;
	return items;
}

export async function getCustomElement(): Promise<boolean> {
	return customElementStore.dispatchGetItems();
}

export async function createCustomElement(item: Container): Promise<Container> {
	const uuid = uuidv4();
	item._id = uuid;
	customElementStore.commitSaveItem(item);
	return item;
}

export async function updateCustomElement(item: Container): Promise<Container> {
	customElementStore.commitSaveItem(item);
	return item;
}

export async function deleteCustomElement(itemID: string): Promise<boolean> {
	customElementStore.commitDeleteItem(itemID);
	return true;
}

export function blankContainer(): Container {
	const container: Container = {
		_id: uuidv4(),
		type: "Container",
		active: false,
		action: { type: "none", value: "" },
		styles: {
			display: "flex",
			flexDirection: "column",
			flexWrap: "no-wrap",
			width: "100%",
			height: "100%",
			position: "relative",
			backgroundRepeat: "no-repeat",
			borderStyle: "none",
			fontStyle: "normal",
			textAlign: "left"
		},
		attributes: {
			id: ""
		},
		containers: [],
		elements: []
	};
	return container;
}

export function blankElement(
	name: string,
	type: string,
	text: string,
	action: Action,
	attributes: { [key: string]: string },
	styles: { [key: string]: string }
): Container {
	const element: Container = {
		_id: uuidv4(),
		name: name,
		active: false,
		type: type,
		text: text,
		action: action,
		styles: styles,
		attributes: attributes,
		containers: [],
		elements: []
	};
	return element;
}

export function selectLayout(item: Container) {
	// getSelectedLayout
	customElementStore.commitSelectLayout(item);
	// console.log(customElementStore.selectedLayout);
	// console.log(customElementStore.selected);
}
export function selectedLayout(): ComputedRef<Container | undefined> {
	// getSelectedLayout
	return customElementStore.getSelectedLayout;
}

export function selectContainer(item: Container) {
	const selectedPage = ref(selectedCustomPageByURL().value);
	const layout = selectedLayout();
	const allContainers: Container[] = [];
	if (layout.value) {
		setAllContainersInactive(layout.value, allContainers);
		item.active = true;
		customPageStore.commitSelectContainer(item);
	}
}

export function selectActiveContainer(obj: Container) {
	if (obj.active) {
		selectContainer(obj);
	}
	obj.containers!.forEach(child => selectActiveContainer(child));
}

export function setAllContainersInactive(
	obj: Container,
	resultArray: Container[]
) {
	obj.active = false;
	resultArray.push(obj);
	obj.containers!.forEach(child =>
		setAllContainersInactive(child, resultArray)
	);
	obj.elements!.forEach(child =>
		setAllContainersInactive(child, resultArray)
	);
}

export function selectedContainer(): ComputedRef<Container | null> {
	const item = customPageStore.getSelectedContainer;
	return item;
}

export function saveSelectedContainer(container: Container | null) {
	customPageStore.commitSaveSelectContainer(container);
}

export function deleteSeclectedContainer(id: string) {
	const layout = selectedLayout();
	findAndDeleteContainer(layout!.value!, id);
	findAndDeleteElement(layout!.value!, id);
}
function findAndDeleteContainer(obj: Container, id: string) {
	const parent = obj;
	let index = 0;
	obj.containers!.forEach(child => {
		if (child._id == id) {
			parent.containers?.splice(index, 1);
		} else {
			index++;
			findAndDeleteContainer(child, id);
		}
	});
	let elementIndex = 0;
	obj.elements!.forEach(child => {
		if (child._id == id) {
			parent.elements?.splice(elementIndex, 1);
			elementIndex = 0;
		} else {
			elementIndex++;
			findAndDeleteContainer(child, id);
		}
	});
}
function findAndDeleteElement(obj: Container, id: string) {
	const parent = obj;
	let elementIndex = 0;
	obj.elements!.forEach(child => {
		if (child._id == id) {
			parent.elements?.splice(elementIndex, 1);
			elementIndex = 0;
		} else {
			elementIndex++;
			findAndDeleteElement(child, id);
		}
	});
}
