import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f41dd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "content-main",
  style: {"padding-top":"20px"}
}
const _hoisted_2 = {
  key: 0,
  class: "bread-crumb"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "panel stretch-height" }
const _hoisted_5 = { class: "panel stretch-height flex" }
const _hoisted_6 = { class: "flex-code" }
const _hoisted_7 = { class: "flex-code-footer" }
const _hoisted_8 = { class: "submit-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNavigation = _resolveComponent("SideNavigation")!
  const _component_ARTemplateList = _resolveComponent("ARTemplateList")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_v_ace_editor = _resolveComponent("v-ace-editor")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideNavigation),
    (_ctx.selectedCampaign)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, "Campaigns / " + _toDisplayString(_ctx.selectedCampaign.name) + " / AR Templates", 1),
          (_ctx.selectedTemplate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.selectedTemplate.name ? ` / ${_ctx.selectedTemplate.name}` : ``), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, {
      gutter: 10,
      style: {"height":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ARTemplateList)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 16 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.selectedTemplate)
                  ? (_openBlock(), _createBlock(_component_v_ace_editor, {
                      key: 0,
                      readonly: _ctx.readonly,
                      value: _ctx.selectedTemplate.code,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTemplate.code) = $event)),
                      lang: "ejs",
                      theme: "chrome",
                      style: _normalizeStyle({
								height: '100%',
								opacity: _ctx.readonly ? 0.7 : 1
							})
                    }, null, 8, ["readonly", "value", "style"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_button, {
                  disabled: !_ctx.selectedTemplate,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.save())),
                  loading: _ctx.saving,
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Save")
                  ]),
                  _: 1
                }, 8, ["disabled", "loading"]),
                _createVNode(_component_a_button, {
                  disabled: !_ctx.selectedTemplate,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.edit())),
                  type: _ctx.readonly ? 'danger' : 'primary'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.readonly ? "Edit code" : "Lock code"), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "type"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      footer: null,
      visible: _ctx.showModal,
      title: 'Danger Zone!',
      onCancel: _cache[5] || (_cache[5] = ($event: any) => {
				_ctx.showModal = false;
				_ctx.readonly = true;
			})
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_alert, {
          message: "Warning",
          description: "Modify code at own risk",
          type: "warning",
          "show-icon": ""
        }),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_a_button, {
            style: {"margin-right":"10px"},
            type: "default",
            onClick: _cache[3] || (_cache[3] = ($event: any) => {
						_ctx.showModal = false;
						_ctx.readonly = true;
					})
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_component_a_button, {
            type: "danger",
            onClick: _cache[4] || (_cache[4] = ($event: any) => {
						_ctx.showModal = false;
						_ctx.readonly = false;
					})
          }, {
            default: _withCtx(() => [
              _createTextVNode("Edit code")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}