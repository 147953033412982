import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e01c570e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100%","background-color":"#000","display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"width":"100%","height":"100%","position":"absolute","top":"0","left":"0"},
    class: _normalizeClass({ selected: _ctx.elementRef.active && _ctx.editable })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_font_awesome_icon, {
        style: {"color":"#fff"},
        icon: "video"
      })
    ])
  ], 2))
}