import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { Category } from "../types/vItemCategory";
import ApiProtected from "../utils/api-protected";
import { ComputedRef, computed } from "vue";

VuexModule.__useHotUpdate = true;

@Module
class VItemCategoryStore extends VuexModule {
	api = ApiProtected.getInstance();
	//state
	items: Category[] = [];
	selectedItemID = "";

	//getter
	get getItems(): ComputedRef<Category[]> {
		return computed(() => this.items);
	}
	get getSelectedItem(): ComputedRef<Category | undefined> {
		return computed(() =>
			this.items.find(e => e.id == this.selectedItemID)
		);
	}

	// mutations
	@Mutation
	commitSaveItems(items: Category[]) {
		this.items = items;
	}
	@Mutation
	commitSaveItem(item: Category) {
		const index = this.items.findIndex(e => e.id == item.id);
		if (index == -1) {
			this.items.push(item);
		} else {
			this.items[index] = item;
		}
	}
	@Mutation
	commitDeleteItem(itemID: string) {
		const index = this.items.findIndex(e => e.id == itemID);
		if (index != -1) {
			this.items.splice(index, 1);
		}
	}
	@Mutation
	commitSelectedItemID(itemID: string) {
		this.selectedItemID = itemID;
	}

	//actions
	@Action
	async dispatchGetItems(params: RequestByCampaignID): Promise<boolean> {
		const response: Response = await this.api.getCategories(params);
		if (response.success === true) {
			const items: Category[] = response.data;
			this.commitSaveItems(items);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchGetItemByID(params: RequestByID): Promise<boolean> {
		const response: Response = await this.api.getCategoryByID(params);
		if (response.success === true) {
			const item: Category = response.data;
			this.commitSaveItem(item);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchCreateItem(item: CampaignObjectRequest): Promise<Response> {
		console.log(item);
		const itemCreated: Response = await this.api.createCategory(item);
		if (itemCreated.success === true) this.commitSaveItem(itemCreated.data);
		return itemCreated;
	}
	@Action
	async dispatchUpdateItem(item: Category): Promise<Response> {
		const itemUpdated = await this.api.updateCategory(item);
		if (itemUpdated.success === true) this.commitSaveItem(itemUpdated.data);
		return itemUpdated;
	}
	@Action
	async dispatchDeleteItem(delObject: RequestByID): Promise<Response> {
		const itemDeleted = await this.api.deleteCategory(delObject);
		if (itemDeleted.success === true) this.commitDeleteItem(delObject.id);
		return itemDeleted;
	}
}

// register module (could be in any file)
import store from "./";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { RequestByCampaignID } from "@/types/requests";
export const vItemCategoryStore = new VItemCategoryStore({
	store,
	name: "vItemCategory"
});
