
import { defineComponent, onBeforeMount, ref } from "vue";
import SigninComponent from "@/components/login/SigninComponent.vue";
import SignupComponent from "@/components/login/SignupComponent.vue";
import NewPasswordComponent from "@/components/login/NewPasswordComponent.vue";
import { allManagers, getManagers } from "@/composables/models/use-managers";
import { ChallengeRequest } from "@/types/requests";

export default defineComponent({
	setup() {
		//variables
		let showLogin = ref(true);
		let passwordChange = ref(false);
		let challengeData = ref();
		//dispatchers

		//getters

		//functions
		const refresh = () => {
			showLogin.value = true;
			passwordChange.value = false;
		};
		const gotoSignup = () => {
			showLogin.value = false;
			passwordChange.value = false;
		};
		const gotoSignin = () => {
			showLogin.value = true;
			passwordChange.value = false;
		};
		const gotoNewPassword = (data: ChallengeRequest) => {
			challengeData.value = data;
			passwordChange.value = true;
		};

		return {
			showLogin,
			refresh,
			gotoSignup,
			gotoSignin,
			passwordChange,
			gotoNewPassword,
			challengeData
		};
	},
	components: { SigninComponent, SignupComponent, NewPasswordComponent }
});
