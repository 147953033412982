import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "submit-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_UpdateCreateCustomPageForm = _resolveComponent("UpdateCreateCustomPageForm")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, { gutter: 10 }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newCustomPage())),
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add Page")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 12 })
      ]),
      _: 1
    }),
    _createVNode(_component_a_list, {
      class: "demo-loadmore-list",
      "item-layout": "horizontal",
      "data-source": _ctx.pages
    }, {
      renderItem: _withCtx(({ item }) => [
        _createVNode(_component_a_list_item, {
          onClick: ($event: any) => (_ctx.gotoPage(item))
        }, {
          actions: _withCtx(() => [
            (_ctx.isCustom(item))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.onEditCustomPage(item))
                }, "edit", 8, _hoisted_1))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.onDuplicateCustomPage(item))
            }, "duplicate", 8, _hoisted_2),
            (_ctx.isCustom(item))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.onSelectDeleteCustomPage(item))
                }, "delete", 8, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_list_item_meta, {
              description: item.url
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["description"])
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["data-source"]),
    _createVNode(_component_a_modal, {
      footer: null,
      visible: _ctx.showModal,
      title: `${_ctx.methodRef} Custom Page`,
      onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCancel()))
    }, {
      default: _withCtx(() => [
        (_ctx.showModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_UpdateCreateCustomPageForm, {
                method: _ctx.methodRef,
                customPage: _ctx.page,
                campaignID: _ctx.campaignID,
                onSaved: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modifiedCustomPage($event)))
              }, null, 8, ["method", "customPage", "campaignID"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    _createVNode(_component_a_modal, {
      footer: null,
      visible: _ctx.showDeleteModal,
      title: 'Delete Page',
      onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onCancel()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_alert, {
          message: "Warning",
          description: "This Page will be removed permanently.",
          type: "warning",
          "show-icon": ""
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_button, {
            style: {"margin-right":"10px"},
            type: "default",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCancel()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onDeleteCustomPage()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Delete")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}