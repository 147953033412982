import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mapbox_map = _resolveComponent("mapbox-map")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"width":"100%","height":"100%","position":"relative"},
    class: _normalizeClass({ selected: _ctx.elementRef.active && _ctx.editable })
  }, [
    _createVNode(_component_mapbox_map, {
      style: {"width":"100%","height":"100%","position":"relative"},
      accessToken: 
				'pk.eyJ1IjoiY2hyaXMtZ3JlZW4iLCJhIjoiY2t0Z2F3M2M1MGdkbDMwcGU3MGZueXpwOSJ9.ONbWrh7EhHzErxjapxkVgg'
			,
      mapStyle: "light-v10"
    }, null, 8, ["accessToken"])
  ], 2))
}