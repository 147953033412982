
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { Asset } from "@/types/asset";
import { defineComponent, ref, watch } from "vue";
import {
	blankAsset,
	createAsset,
	updateAsset,
	uploadFile,
	validationRules
} from "@/composables/models/use-assets";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { CampaignObjectRequest } from "@/types/requests";

export default defineComponent({
	components: {},
	props: {
		asset: {
			type: Object,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		},
		method: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		const categories = ["Image", "Video", "3D Asset", "Audio"];
		let assetRef = ref(JSON.parse(JSON.stringify(props.asset)));
		let fileUpload: {
			fileName: string;
			base: string | ArrayBuffer | null;
		} = { fileName: "", base: null };
		const loading = ref(false);
		const rules = validationRules();
		//dispatchers

		//getters

		//functions

		watch(assetRef.value, () => {
			formRef.value.validate();
		});

		const onCancel = () => {
			if (props.method == "Create") assetRef.value = blankAsset();
			formRef.value.resetFields();
			emit("saved");
		};
		const onSubmit = () => {
			formRef.value
				.validate()
				.then(async () => {
					if (props.method == "Create") {
						loading.value = true;
						const upload = await uploadFile({
							fileName: fileUpload.fileName,
							base: fileUpload.base,
							campaignID: props.campaignID
						});
						console.log(upload);
						assetRef.value.url = upload.data.url;
						assetRef.value.key = upload.data.key;
						const campaignObject: CampaignObjectRequest = {
							campaignID: props.campaignID,
							data: assetRef.value
						};
						createAsset(campaignObject).then(
							(response: Response) => {
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.ErrorMessage);
								}
								emit("saved");
							}
						);
						loading.value = false;
						assetRef.value = blankAsset();
					} else if (props.method == "Update") {
						const campaignObject: CampaignObjectRequest = {
							campaignID: props.campaignID,
							data: assetRef.value
						};
						updateAsset(campaignObject).then(
							(response: Response) => {
								console.log(response);
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.ErrorMessage);
								}
								emit("saved");
							}
						);
						loading.value = false;
						assetRef.value = blankAsset();
					}
					emit("saved");
				})
				.catch((error: ValidateErrorEntity<Asset>) => {
					console.log("error", error);
				});
		};
		const handleChange = (value: number) => {
			assetRef.value.category = categories[value];
		};
		// const previewFile = async (file: any): Promise<Response> => {
		// 	console.log("Your upload file:", file);
		// 	// Your process logic. Here we just mock to the same file
		// 	const res = await fetch(
		// 		"https://next.json-generator.com/api/json/get/4ytyBoLK8",
		// 		{
		// 			method: "POST",
		// 			body: file,
		// 		}
		// 	);
		// 	const { thumbnail } = await res.json();
		// 	return thumbnail;
		// };
		const previewFile = async (file: any) => {
			console.log(file);
			fileUpload.fileName = file.name;
			assetRef.value.type = file.type;
			assetRef.value.url = file.name;
			// Your process logic. Here we just mock to the same file
		};
		const customRequest = (data: any) => {
			// Upload and submit
			const formData = new FormData();
			formData.append("file", data.file);
			//handle file
			var reader = new FileReader();
			reader.readAsDataURL(data.file);
			reader.onloadend = function(e) {
				console.log(reader.result);
				fileUpload.base = reader.result;
			};
		};
		return {
			categories,
			onCancel,
			onSubmit,
			assetRef,
			handleChange,
			previewFile,
			customRequest,
			formRef,
			rules,
			loading
		};
	}
});
