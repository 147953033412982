import { Response } from "@/utils/response";
import ApiProtected from "@/utils/api-protected";
import { DropPool } from "@/types/dropPool";
import {
	CampaignObjectRequest,
	RequestByCampaignID,
	RequestByID
} from "@/types/requests";
import store from "..";

const api = ApiProtected.getInstance();

export const dropPoolStore = {
	namespaced: true,
	state: {
		items: [] as DropPool[],
		selectedItemID: ""
	},
	mutations: {
		commitSaveItems(state: any, items: DropPool[]) {
			state.items = items;
		},
		commitSaveItem(state: any, item: DropPool) {
			const index = state.items.findIndex(
				(e: DropPool) => e.id == item.id
			);
			if (index == -1) {
				state.items.push(item);
			} else {
				state.items[index] = item;
			}
			console.log(state.items);
		},

		commitDeleteItem(state: any, itemID: string) {
			const index = state.items.findIndex(
				(e: DropPool) => e.id == itemID
			);
			if (index != -1) {
				state.items.splice(index, 1);
			}
		},

		commitSelectedItemID(state: any, itemID: string) {
			state.selectedItemID = itemID;
		}
	},
	getters: {
		getItems(state: any): DropPool[] {
			return state.items;
		},
		getSelectedItem(state: any): DropPool | undefined {
			return state.items.find(
				(e: DropPool) => e.id == state.selectedItemID
			);
		},
		getSelectedItemID(state: any): string | undefined {
			return state.selectedItemID;
		}
	},
	actions: {
		async dispatchGetItems({ commit }: any): Promise<boolean> {
			const campaignID: RequestByCampaignID = {
				campaignID: store.getters["campaignStore/getSelectedItem"].id
			};
			const response: Response = await api.getDropPools(campaignID);
			if (response.success === true) {
				const items: DropPool[] = response.data;
				commit("commitSaveItems", items);
				return true;
			} else {
				return false;
			}
		},

		async dispatchGetItemByID(
			{ commit }: any,
			params: RequestByID
		): Promise<Response> {
			const response: Response = await api.getDropPoolByID(params);
			if (response.success === true) {
				const item: DropPool = response.data;
				commit("commitSaveItem", item);
				commit("commitSelectedItemID", params.id);
			}
			return response;
		},

		async dispatchCreateItem(
			{ commit }: any,
			item: CampaignObjectRequest
		): Promise<Response> {
			const itemCreated = await api.createDropPool(item);
			console.log(itemCreated);
			if (itemCreated.success === true) {
				commit("commitSaveItem", itemCreated.data);
			}

			return itemCreated;
		},

		async dispatchUpdateItem(
			{ commit }: any,
			item: DropPool
		): Promise<Response> {
			const itemUpdated = await api.updateDropPool(item);
			console.log(itemUpdated);
			if (itemUpdated.success === true)
				commit("commitSaveItem", itemUpdated.data);
			return itemUpdated;
		},
		async dispatchDeleteItem(
			{ commit }: any,
			params: RequestByID
		): Promise<Response> {
			const itemDeleted = await api.deleteDropPool(params);
			if (itemDeleted.success === true)
				commit("commitDeleteItem", params.id);
			return itemDeleted;
		}
	}
};
