
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import {
	blankCategory,
	createCategory,
	updateCatgory,
	validationRules
} from "@/composables/models/use-vItemCategories";
import { Category } from "@/types/vItemCategory";
import { defineComponent, ref, watch } from "vue";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { CampaignObjectRequest } from "@/types/requests";
import { Asset } from "@/types/asset";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";

export default defineComponent({
	components: { AssetManagerComponent },
	props: {
		category: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		let categoryRef = ref(JSON.parse(JSON.stringify(props.category)));
		const rules = validationRules();
		const showAssetManager = ref(false);
		const type = ref("image");

		//dispatchers

		//getters

		//functions
		watch(categoryRef.value, () => {
			formRef.value.validate();
		});

		const onCancel = () => {
			if (props.method == "Create") categoryRef.value = blankCategory();
			formRef.value.resetFields();
			emit("saved");
		};
		const onSubmit = () => {
			formRef.value
				.validate()
				.then(() => {
					if (props.method == "Create") {
						const campaignObj: CampaignObjectRequest = {
							campaignID: props.campaignID,
							data: categoryRef.value as Category
						};
						console.log(campaignObj);
						createCategory(campaignObj).then(
							(response: Response) => {
								console.log(response);
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								categoryRef.value = blankCategory();
								emit("saved");
							}
						);
					} else if (props.method == "Update") {
						const updatedCategory = {
							id: categoryRef.value.id,
							campaignID: categoryRef.value.campaignID,
							name: categoryRef.value.name,
							redemptionWalletID:
								categoryRef.value.redemptionWalletID,
							description: categoryRef.value.description,
							value: categoryRef.value.value,
							asset: categoryRef.value.asset,
							assetSize: categoryRef.value.assetSize,
							image: categoryRef.value.image,
							imageSize: categoryRef.value.imageSize
						};
						updateCatgory(updatedCategory as Category).then(
							(response: Response) => {
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								emit("saved");
							}
						);
					}
				})
				.catch((error: ValidateErrorEntity<Category>) => {
					console.log("error", error);
				});
		};
		function defineAsset(t: string) {
			type.value = t;
			console.log(type.value);
			showAssetManager.value = true;
		}

		function setAsset(asset: Asset) {
			console.log(type.value);
			if (type.value == "image") {
				categoryRef.value.image = asset.url;
			} else if (type.value == "asset") {
				categoryRef.value.asset = asset.url;
			}
			showAssetManager.value = false;
		}

		return {
			onCancel,
			onSubmit,
			setAsset,
			defineAsset,
			categoryRef,
			formRef,
			rules,
			showAssetManager,
			type
		};
	}
});
