
import {
	defineComponent,
	ref,
	computed,
	ComputedRef,
	onBeforeMount,
	watchEffect
} from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import PrizeItemComponent from "@/components/prize/PrizeItemComponent.vue";
import UpdateCreatePrizeForm from "@/components/forms/UpdateCreatePrizeForm.vue";
import {
	allPrizes,
	blankPrize,
	getPrizes,
	selectPrize
} from "@/composables/models/use-prizes";
import Search from "@/components/utils/Search.vue";
import { Prize } from "@/types/prize";
import { useRoute } from "vue-router";

export default defineComponent({
	setup() {
		//variables
		const showModal = ref(false);
		const route = useRoute();
		let campaignId = ref(route.params.campaignID.toString());
		let prizes: ComputedRef<Prize[]> = computed(() => []);
		let filteredPrizes = ref(prizes.value);
		let newPrize = blankPrize();
		const prize = computed(() => newPrize);
		let filterString = ref("");
		//dispatchers
		onBeforeMount(async () => {
			console.log(campaignId.value);
			await getPrizes({ campaignID: campaignId.value });
			prizes = allPrizes();
			filteredPrizes.value = ref(prizes.value).value;
		});

		//getters

		//functions
		const createPrize = () => {
			showModal.value = true;
		};
		const onCancel = () => {
			showModal.value = false;
		};
		const filtered = (searchString: string) => {
			filterString.value = searchString;
			filteredPrizes.value = prizes.value.filter(e => {
				return e.name
					.toLowerCase()
					.includes(searchString.toLowerCase());
			});
		};
		const modifiedPrize = () => {
			showModal.value = false;
			filterString.value = "";
			filteredPrizes.value = ref(prizes.value).value;
		};

		return {
			showModal,
			onCancel,
			createPrize,
			prize,
			prizes,
			filteredPrizes,
			filtered,
			modifiedPrize,
			filterString,
			campaignId
		};
	},
	components: {
		PrizeItemComponent,
		Scroller,
		UpdateCreatePrizeForm,
		Search
	}
});
