
import { ref, computed } from "vue";
import { defineComponent } from "vue";
import UpdateCreateManagerForm from "../forms/UpdateCreateManagerForm.vue";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { deleteManager } from "@/composables/models/use-managers";

export default defineComponent({
	props: {
		manager: {
			type: Object,
			required: true
		}
	},
	emits: ["update"],
	setup(props, { emit }) {
		//variables
		let managerRef = computed(() => props.manager);
		const showModal = ref(false);
		let showDeleteModal = ref(false);
		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			showModal.value = false;
		};
		const openUpdateModal = () => {
			console.log(managerRef);
			showModal.value = true;
		};
		const changesSaved = () => {
			showModal.value = false;
			emit("update");
		};
		const onCancelDelete = () => {
			showDeleteModal.value = false;
		};
		const onDelete = () => {
			showDeleteModal.value = true;
		};
		const handleDelete = (id: string) => {
			showDeleteModal.value = false;
			deleteManager(id).then((response: Response) => {
				if (response.success === true) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				emit("update");
			});
		};

		return {
			onCancel,
			showModal,
			managerRef,
			openUpdateModal,
			changesSaved,
			onDelete,
			handleDelete,
			showDeleteModal,
			onCancelDelete
		};
	},
	components: { UpdateCreateManagerForm }
});
