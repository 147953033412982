export interface DropPool {
	id?: string;
	campaignID?: string;
	name: string;
	dropType: DropType;
	prepopulatedCount?: number;
	startDate?: string;
	endDate?: string;
	dropInterval?: DropInterval; //single or daily
	dropIntervalStart?: string; //time start
	dropIntervalEnd?: string; //time end
	dropUserRadius?: number; //radius to drop to user
	minDistanceFromUser?: number; //the minimum distance in meters to drop drom the user
	dropLocationRadius?: number; //radius to for active gps drop location
	dropSize?: number; //Number of items to drop to the user
	maxClaimSize?: number; //Number of items a user can claim based on the maxClaimInterval
	maxClaimInterval?: string; //The interval defining the max claim size
	dropLocations: DropLocation[];
	categorySplit: CategorySplit[];
	prizeSplit: PrizeSplit[];
	triggeredOn: TriggeredOn | null;
	triggerCount: number;
	useEnhancedAI: boolean;
	ARTemplateID?: string;
}

export interface CategorySplit {
	categoryID: string;
	name: string;
	number?: number;
	prizeSplit: PrizeSplit[];
}

export interface PrizeSplit {
	prizeID: string;
	name: string;
	number?: number;
	percent?: number;
}

export interface DropLocation {
	placeName?: string;
	latitude: string;
	longitude: string;
}

export enum DropType {
	PIN = "pin",
	GPS = "gps",
	PRE_POPULATED_VAT = "prepopulatedVat",
	PRE_POPULATED_PRIZE = "prepopulatedPrize",
	SHARE_REWARD = "shareReward"
}

export enum DropInterval {
	SINGLE = "single",
	DAILY = "daily"
}

export enum TriggeredOn {
	SHARE = "share",
	VIEW = "view",
	COLLECTED = "collected"
}
