
import { defineComponent } from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import {
	CategorySplit,
	DropInterval,
	DropPool,
	DropType,
	TriggeredOn
} from "@/types/dropPool";
import moment, { Moment } from "moment";
import PoolCategorySplit from "@/components/pool/PoolCategorySplit.vue";
import store from "@/store";
import { Campaign } from "@/types/campaign";
import { ARTemplate } from "@/types/arTemplate";

export default defineComponent({
	components: { Scroller, PoolCategorySplit },
	emits: ["deleted"],
	data() {
		return {
			moment: moment,
			showCategorySplit: false
		};
	},
	mounted() {
		store.dispatch("dropPoolStore/dispatchGetItems");
		store.dispatch("arTemplateStore/dispatchGetItems");
		store.dispatch("categoryStore/dispatchGetItems");
	},
	computed: {
		selectedDropPool(): DropPool {
			return store.getters["dropPoolStore/getSelectedItem"];
		},
		selectedCampaign(): Campaign {
			return store.getters["campaignStore/getSelectedItem"];
		},
		arTemplates(): ARTemplate[] {
			return store.getters["arTemplateStore/getItems"];
		},
		categories(): ARTemplate[] {
			return store.getters["categoryStore/getItems"];
		},
		categorySplitRef(): string[] {
			const arr: string[] = [];
			for (let i = 0; i < this.selectedDropPool.categorySplit.length; i++)
				arr.push(
					this.selectedDropPool.categorySplit[i].categoryID +
						"_" +
						this.selectedDropPool.categorySplit[i].name
				);
			return arr;
		}
	},
	methods: {
		checkDropType(field: string): { label: string; toolTip: string } {
			const info = { label: "", toolTip: "" };
			switch (field) {
				case "dropSize":
					switch (this.selectedDropPool.dropType) {
						case "shareReward":
							info.label = "Number of rewarded VATs";
							info.toolTip =
								"The number of VATs rewarded to the user per share";
							break;
						default:
							info.label = "User drop size";
							info.toolTip =
								"The number of items to drop around a user";
					}
					break;
				case "maxClaimSize":
					switch (this.selectedDropPool.dropType) {
						case "shareReward":
							info.label =
								"Max number of VATs claimed per interval";
							info.toolTip =
								"Th maximum number of VATs that a user can claim within the specified interval";
							break;
						default:
							info.label = "Maximum user claim size";
							info.toolTip =
								"The number of items a user can claim within the max claim size interval";
					}
					break;
				case "maxClaimInterval":
					switch (this.selectedDropPool.dropType) {
						case "shareReward":
							info.label = "";
							info.toolTip = "";
							break;
						default:
							info.label = "Maximum user claim interval";
							info.toolTip =
								"The interval in which the maximum claim size exists";
					}
					break;
			}

			return info;
		},
		showField(field: string): boolean {
			let show = true;
			if (this.selectedDropPool.dropType === "prepopulatedVat") {
				if (
					field == "dropInterval" ||
					field == "dropUserRadius" ||
					field == "minDistanceFromUser" ||
					field == "dropSize" ||
					field == "maxClaimInterval" ||
					field == "maxClaimSize"
				) {
					show = false;
				}
			} else if (this.selectedDropPool.dropType === "shareReward") {
				if (
					field == "dropInterval" ||
					field == "dropUserRadius" ||
					field == "minDistanceFromUser"
				) {
					show = false;
				}
			}

			return show;
		},
		disabledDate(current: moment.Moment): boolean {
			const campaignStart = moment(this.selectedCampaign?.startDate);
			const campaignEnd = moment(this.selectedCampaign?.endDate);
			if (
				current.isSameOrAfter(campaignStart) &&
				current.isSameOrBefore(campaignEnd)
			) {
				return false;
			}
			return true;
		},
		onDateChange(date: Moment, dateString: string): void {
			this.selectedDropPool.startDate = dateString[0];
			this.selectedDropPool.endDate = dateString[1];
		},
		handleDropTypeChange(value: DropType): void {
			this.selectedDropPool.dropType = value;
		},
		handleTriggeredOnChange(value: TriggeredOn): void {
			this.selectedDropPool.triggeredOn = value;
		},
		handleIntervalChange(value: DropInterval): void {
			this.selectedDropPool.dropInterval = value;
		},
		timeIntervalChange($event: any, field: string): void {
			const value = $event.format("HH:mm");
			if (field == "start") {
				this.selectedDropPool.dropIntervalStart = value;
			} else {
				this.selectedDropPool.dropIntervalEnd = value;
			}
		},
		handleMaxClaimIntervalChange(interval: string): void {
			this.selectedDropPool.maxClaimInterval = interval;
		},
		handleARTemplateChange($event: any): void {
			this.selectedDropPool.ARTemplateID = $event;
		},
		handleCategoryChange(selectedItems: any): void {
			this.showCategorySplit = true;
			// dropPoolRef.value.categorySplit = [];
			//Add if not there
			let selectedCategories: string[] = [];
			for (let i = 0; i < selectedItems.length; i++) {
				const categoryID = selectedItems[i].split("_")[0];
				selectedCategories.push(categoryID);

				const index = this.selectedDropPool.categorySplit.findIndex(
					(e: CategorySplit) => e.categoryID == categoryID
				);
				if (index < 0) {
					const categorySplit: CategorySplit = {
						categoryID: selectedItems[i].split("_")[0],
						name: selectedItems[i].split("_")[1],
						prizeSplit: []
					};
					this.selectedDropPool.categorySplit.push(categorySplit);
				}
			}
			//Remove if not there
			const filteredCategorySplits = this.selectedDropPool.categorySplit.filter(
				(e: CategorySplit) => {
					return selectedCategories.includes(e.categoryID);
				}
			);
			this.selectedDropPool.categorySplit = filteredCategorySplits;
		},
		saveCategorySplit(categorySplitRef: CategorySplit[]): void {
			for (let i = 0; i < categorySplitRef.length; i++) {
				this.selectedDropPool.categorySplit[i].number =
					categorySplitRef[i].number;
			}
		}
	}
});
