
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import {
	blankManager,
	createManager,
	updateManager,
	validationRules
} from "@/composables/models/use-managers";
import { defineComponent, ref, watch } from "vue";
import { Manager } from "@/types/manager";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";

export default defineComponent({
	components: {},
	props: {
		manager: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		let managerRef = ref(JSON.parse(JSON.stringify(props.manager)));
		const rules = validationRules();
		const methodRef = ref(props.method);
		//dispatchers

		//getters

		//functions
		watch(managerRef.value, () => {
			formRef.value.validate();
		});

		const onCancel = () => {
			if (props.method == "Create") managerRef.value = blankManager();
			formRef.value.resetFields();
			emit("saved");
		};
		const onSubmit = () => {
			formRef.value
				.validate()
				.then(() => {
					if (props.method == "Create") {
						createManager(managerRef.value as Manager).then(
							(response: Response) => {
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								managerRef.value = blankManager();
								emit("saved");
							}
						);
					} else if (props.method == "Update") {
						const updatedManager = {
							id: managerRef.value.id,
							name: managerRef.value.name,
							email: managerRef.value.email
						};
						updateManager(updatedManager as Manager).then(
							(response: Response) => {
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								emit("saved");
							}
						);
					}
				})
				.catch((error: ValidateErrorEntity<Manager>) => {
					console.log("error", error);
				});
		};
		return {
			onCancel,
			onSubmit,
			methodRef,
			managerRef,
			formRef,
			rules
		};
	}
});
