import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8f0b816"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-main" }
const _hoisted_2 = { class: "panel-view stretch-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNavigation = _resolveComponent("SideNavigation")!
  const _component_WalletManagerComponent = _resolveComponent("WalletManagerComponent")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideNavigation),
    _createVNode(_component_a_row, { style: {"height":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 24 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_WalletManagerComponent)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}