
import { defineComponent } from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import UpdateCreateArTemplate from "@/components/forms/UpdateCreateARTemplateForm.vue";
import store from "@/store";
import ARTemplateItem from "./ARTemplateItem.vue";

export default defineComponent({
	data() {
		return {
			showModal: false
		};
	},
	methods: {
		createARTemplate() {
			this.showModal = true;
			console.log("create");
		},
		onCancel() {
			console.log("cancel");
		},
		closeModal() {
			this.showModal = false;
		}
	},
	computed: {
		arTemplates() {
			return store.getters["arTemplateStore/getItems"];
		}
	},
	components: {
		Scroller,
		ARTemplateItem,
		UpdateCreateArTemplate
		// Search
	}
});
