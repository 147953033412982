import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMapboxTs from "vue-mapbox-ts";
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import { VueCsvImportPlugin } from "vue-csv-import";
import ganttastic from "@infectoone/vue-ganttastic";

import "ant-design-vue/dist/antd.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAngleLeft,
	faAngleRight,
	faUser,
	faUsers,
	faPowerOff,
	faCog,
	faSlidersH,
	faPlus,
	faPen,
	faUpload,
	faQrcode,
	faMapMarkerAlt,
	faInfo,
	faWallet,
	faPalette,
	faImage,
	faAward,
	faArrowUp,
	faArrowDown,
	faTrash,
	faBoxes,
	faEllipsisV,
	faVideo,
	faList,
	faArrowsAltH,
	faArrowsAltV,
	faFill,
	faMinus,
	faGift,
	faCubes,
	faTimes,
	faBars,
	faGem,
	faCircleNotch,
	faVrCardboard
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
const app = createApp(App)
	.use(store)
	.use(router);

import {
	Layout,
	Button,
	Menu,
	Input,
	InputNumber,
	Row,
	Col,
	Tag,
	Select,
	DatePicker,
	Upload,
	Tooltip,
	Modal,
	TimePicker,
	Statistic,
	Divider,
	Radio,
	Collapse,
	List,
	Alert,
	AutoComplete,
	Popover,
	Image,
	Form,
	Card,
	Checkbox
} from "ant-design-vue";

app.component("font-awesome-icon", FontAwesomeIcon);

library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faUser);
library.add(faUsers);
library.add(faPowerOff);
library.add(faCog);
library.add(faSlidersH);
library.add(faPlus);
library.add(faPen);
library.add(faUpload);
library.add(faQrcode);
library.add(faMapMarkerAlt);
library.add(faInfo);
library.add(faWallet);
library.add(faPalette);
library.add(faImage);
library.add(faAward);
library.add(faArrowUp);
library.add(faArrowDown);
library.add(faTrash);
library.add(faBoxes);
library.add(faEllipsisV);
library.add(faVideo);
library.add(faList);
library.add(faArrowsAltH);
library.add(faArrowsAltV);
library.add(faFill);
library.add(faMinus);
library.add(faGift);
library.add(faCubes);
library.add(faTimes);
library.add(faGem);
library.add(faBars);
library.add(faCircleNotch);
library.add(faVrCardboard);

app.use(Layout);
app.use(Button);
app.use(Menu);
app.use(Input);
app.use(Row);
app.use(Col);
app.use(Tag);
app.use(Select);
app.use(DatePicker);
app.use(InputNumber);
app.use(Upload);
app.use(Tooltip);
app.use(Modal);
app.use(TimePicker);
app.use(Statistic);
app.use(Divider);
app.use(Radio);
app.use(Collapse);
app.use(List);
app.use(Alert);
app.use(AutoComplete);
app.use(Popover);
app.use(Image);
app.use(Form);
app.use(Card);
app.use(Checkbox);

app.use(Vue3ColorPicker);
app.use(Vue3DraggableResizable);
app.use(VueCsvImportPlugin);
app.use(ganttastic);

app.use(VueMapboxTs);

app.mount("#app");
