import { customComponentStore } from "@/store/customComponentStore";
import { CustomComponent } from "@/types/customComponent";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { blankContainer } from "./use-containers";
import { vats } from "../../templates/vatTemplates";
import { RequestByCampaignID } from "@/types/requests";

export function allCustomComponents(): ComputedRef<CustomComponent[]> {
	const items = customComponentStore.getItems;
	return items;
}
export function selectCustomComponent(itemID: string): void {
	customComponentStore.commitSelectedItemID(itemID);
}
export function selectedCustomComponent(): ComputedRef<
	CustomComponent | undefined
> {
	return customComponentStore.getSelectedItem;
}
export async function getCustomComponents(
	params: RequestByCampaignID
): Promise<boolean> {
	return customComponentStore.dispatchGetItems(params);
}
export async function createCustomComponent(
	item: CampaignObjectRequest
): Promise<Response> {
	return await customComponentStore.dispatchCreateItem(item);
}

export async function updateCustomComponent(
	item: CustomComponent
): Promise<Response> {
	return await customComponentStore.dispatchUpdateItem(item);
}

export async function deleteCustomComponent(
	delObj: RequestByID
): Promise<Response> {
	return await customComponentStore.dispatchDeleteItem(delObj);
}

export function blankCustomComponent(): CustomComponent {
	const customComponent: CustomComponent = {
		name: "",
		isSystem: true,
		type: "",
		container: blankContainer()
	};
	return customComponent;
}

export function validationRules() {
	const rules = {
		type: [
			{ required: true, message: "Type is required", trigger: "onBlur" }
		],
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 1,
				max: 50,
				message: "Length should be 1 to 50",
				trigger: "blur"
			}
		]
	};
	return rules;
}
