
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import ArtworkComponent from "@/components/artwork/ArtworkComponent.vue";
import StylesEditor from "@/components/artwork/StylesEditor.vue";
import AttributesEditor from "@/components/artwork/AttributesEditor.vue";
import ActionsEditor from "@/components/artwork/ActionsEditor.vue";
import Scroller from "@/components/utils/Scroller.vue";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import ShortCuts from "@/components/artwork/ShortCuts.vue";
import PrizeManagerComponent from "@/components/prize/PrizeManagerComponent.vue";
import { useRoute } from "vue-router";
import { selectedPrize, updatePrize } from "@/composables/models/use-prizes";
import {
	blankContainer,
	selectedLayout,
	selectLayout
} from "@/composables/models/use-containers";
import store from "@/store";

export default defineComponent({
	components: {
		StylesEditor,
		AttributesEditor,
		ActionsEditor,
		Scroller,
		SideNavigation,
		ShortCuts,
		ArtworkComponent,
		PrizeManagerComponent
	},
	setup() {
		//variables
		const route = ref(useRoute());
		let prize = selectedPrize();
		const campaignID = ref();
		const activeKey = ref();
		const saving = ref(false);
		let layout = selectedLayout();
		const component = ref("");
		const componentValue = ref("");
		const campaignRef = ref();
		let selectedItem = "";
		const editable = ref(true);

		let previousSelected = "";

		//dispatchers

		//getters

		//functions
		onMounted(async () => {
			selectLayout(blankContainer());
			campaignID.value = route.value.params.campaignID;
			campaignRef.value = store.getters["campaignStore/getSelectedItem"];
			componentValue.value = "allocatedComponent";
		});

		watchEffect(() => {
			if (prize.value?.allocatedComponent) {
				selectArtworkComponent("allocatedComponent");
			}
		});

		function selectArtworkComponent(comp: string) {
			if (comp == "allocatedComponent") {
				selectLayout(prize.value!.allocatedComponent!);
				component.value = "Prize in wallet";
				previousSelected = "allocatedComponent";
			} else if (comp == "redeemedComponent") {
				selectLayout(prize.value!.redeemedComponent!);
				component.value = "Redeemed item in wallet";
				previousSelected = "redeemedComponent";
			} else if (comp == "expiredComponent") {
				selectLayout(prize.value!.expiredComponent!);
				component.value = "Expired item in wallet";
				previousSelected = "expiredComponent";
			} else if (comp == "redeemedComponent") {
				selectLayout(prize.value!.redeemedComponent!);
				component.value = "Expired item in wallet";
				previousSelected = "redeemedComponent";
			} else if (comp == "gameplayFaceCard") {
				selectLayout(prize.value!.gameplayFaceCard!);
				component.value = "Gameplay FaceCard";
				previousSelected = "gameplayFaceCard";
			} else if (comp == "redemptionFaceCard") {
				selectLayout(prize.value!.redemptionFaceCard!);
				component.value = "Redemption FaceCard";
				previousSelected = "redemptionFaceCard";
			}
			componentValue.value = comp;
			selectedItem = comp;
		}

		async function savePrize() {
			prize.value!.campaignID = route.value.params.campaignID.toString();
			saving.value = true;
			await updatePrize(prize.value!);
			saving.value = false;
		}

		return {
			prize,
			campaignID,
			savePrize,
			activeKey,
			saving,
			selectArtworkComponent,
			layout,
			component,
			componentValue,
			campaignRef,
			editable
		};
	}
});
