
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";
// import MainNavBar from "@/components/MainNavBar.vue";

export default defineComponent({
	components: {
		// MainNavBar,
		AssetManagerComponent
	},
	setup() {
		//variables
		const collapsed = ref(false);
		const router = useRouter();
		const campaignID = "asbdiu3y923gug9"; //For time being Just for asset
		const showModal = ref(false); //For time being Just for asset
		//dispatchers

		//getters

		//functions
		//console.log(collapsed.value);
		const onCancel = () => {
			//For time being Just for asset
			showModal.value = false;
		};
		const gotoManager = () => {
			router.push("/console/manager");
		};
		const gotoCampaigns = () => {
			router.push("/console/campaigns");
		};
		const logout = () => {
			localStorage.removeItem("AccessToken");
			localStorage.removeItem("IdToken");
			localStorage.removeItem("RefreshToken");
			router.push("/");
		};

		return {
			collapsed,
			gotoManager,
			gotoCampaigns,
			logout,
			campaignID, //For time being just for asset
			showModal, //For time being just for asset
			onCancel //For time being just for asset
		};
	}
});
