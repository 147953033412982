import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.edit)
    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
        key: 0,
        id: "textearea-input",
        onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateElement())),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.elementRef.text) = $event)),
        style: {"color":"#000"}
      }, null, 544)), [
        [_vModelText, _ctx.elementRef.text]
      ])
    : (_openBlock(), _createElementBlock("p", {
        key: 1,
        style: _normalizeStyle(_ctx.elementRef.styles),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editElement && _ctx.editElement(...args)))
      }, _toDisplayString(_ctx.elementRef.text), 5))
}