
import {
	defineComponent
	// ref,
	// computed,
	// onBeforeMount,
	// ComputedRef
} from "vue";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import ARTemplateList from "@/components/ar/ARTemplateList.vue";
import Scroller from "@/components/utils/Scroller.vue";
import Search from "@/components/utils/Search.vue";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-ejs";
import "ace-builds/src-noconflict/theme-chrome";

import store from "@/store";
import { ARTemplate } from "@/types/arTemplate";

export default defineComponent({
	data() {
		return {
			showModal: false,
			saving: false,
			readonly: true
		};
	},
	mounted() {
		console.log("mounted");
		this.loadTemplates();
	},
	methods: {
		async loadTemplates() {
			await store.dispatch("arTemplateStore/dispatchGetItems");
		},
		async save() {
			this.saving = true;
			await store.dispatch(
				"arTemplateStore/dispatchUpdateItem",
				this.selectedTemplate
			);
			this.saving = false;
		},
		edit() {
			if (this.readonly && !this.showModal) {
				this.showModal = true;
			} else {
				this.readonly = true;
			}
		}
	},
	computed: {
		selectedCampaign() {
			return store.getters["campaignStore/getSelectedItem"];
		},
		selectedTemplate(): ARTemplate {
			return store.getters["arTemplateStore/getSelectedItem"];
		}
	},

	components: {
		SideNavigation,
		ARTemplateList,
		VAceEditor
		// UpdateCreateCampaignForm,
		// Search
	}
});
