
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import ArtworkComponent from "@/components/artwork/ArtworkComponent.vue";
import StylesEditor from "@/components/artwork/StylesEditor.vue";
import AttributesEditor from "@/components/artwork/AttributesEditor.vue";
import ActionsEditor from "@/components/artwork/ActionsEditor.vue";
import Scroller from "@/components/utils/Scroller.vue";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import ShortCuts from "@/components/artwork/ShortCuts.vue";
import VItemCategoryManagerComponent from "@/components/vItemCategory/VItemCategoryManagerComponent.vue";

import { useRoute } from "vue-router";

import { selectedLayout } from "@/composables/models/use-containers";

import {
	selectedCategory,
	updateCatgory
} from "@/composables/models/use-vItemCategories";
import store from "@/store";

export default defineComponent({
	components: {
		StylesEditor,
		AttributesEditor,
		ActionsEditor,
		Scroller,
		SideNavigation,
		ShortCuts,
		ArtworkComponent,
		VItemCategoryManagerComponent
	},
	setup() {
		//variables
		const route = ref(useRoute());
		const vItem = selectedCategory();
		const campaignID = ref();
		const activeKey = ref();
		const saving = ref(false);
		let layout = selectedLayout();
		const component = ref("");
		const componentValue = ref("");
		const campaignRef = ref();
		const editable = ref(true);

		//dispatchers

		//getters

		//functions
		onMounted(async () => {
			campaignID.value = route.value.params.campaignID;
			campaignRef.value = store.getters["campaignStore/getSelectedItem"];
		});

		watchEffect(() => {
			if (vItem.value) {
				component.value = "Claimed item in wallet";
				componentValue.value = "unAllocatedComponent";
				// selectLayout(vItem.value!.unAllocatedComponent!);
				// layout = selectedLayout();
				// console.log(layout);
			}
		});

		async function saveVItem() {
			vItem.value!.campaignID = route.value.params.campaignID.toString();
			saving.value = true;
			console.log(JSON.stringify(vItem.value?.unAllocatedComponent));
			await updateCatgory(vItem.value!);
			saving.value = false;
		}

		return {
			vItem,
			campaignID,
			saveVItem,
			activeKey,
			saving,
			// selectArtworkComponent,
			layout,
			component,
			componentValue,
			campaignRef,
			editable
		};
	}
});
