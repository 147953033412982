import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfb8eaf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "artwork-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Container, {
      editable: _ctx.editable,
      container: _ctx.containerRef
    }, null, 8, ["editable", "container"])
  ]))
}