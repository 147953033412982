import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78ec44aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
			selected: _ctx.elementRef.active && _ctx.editable,
			checkboxHolder: true
		})
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.elementRef.attributes.id,
      name: _ctx.elementRef.attributes.id
    }, null, 8, _hoisted_1),
    _createElementVNode("label", {
      class: "checkbox-label",
      style: _normalizeStyle(_ctx.elementRef.styles),
      for: _ctx.elementRef.attributes.id
    }, _toDisplayString(_ctx.elementRef.attributes.label), 13, _hoisted_2)
  ], 2))
}