
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import {
	blankPrize,
	createPrize,
	selectPrize,
	updatePrize,
	validationRules
} from "@/composables/models/use-prizes";
import {
	blankVoucherCodes,
	createVoucherCodes,
	deleteVoucherCodes,
	getVoucherCodes,
	selectedVoucherCode,
	updateVoucherCodes
} from "@/composables/models/use-voucherCodes";
import {
	computed,
	ComputedRef,
	defineComponent,
	onBeforeMount,
	onMounted,
	ref,
	watch
} from "vue";
import { Prize } from "@/types/prize";
import { Response } from "@/utils/response";
import { message, Modal } from "ant-design-vue";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { allWallets, getWallets } from "@/composables/models/use-wallets";
import { VoucherCodes, VoucherItem } from "@/types/voucherCodes";

export default defineComponent({
	components: {},
	props: {
		prize: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		}
	},
	methods: {
		handleAddTemplate() {
			this.prizeRef.nftTemplates.push({
				name: "",
				templateID: "",
				count: 1
			});
		},
		handleRemoveTemplate(index: number) {
			this.prizeRef.nftTemplates.splice(index, 1);
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		const csvRef = ref<VoucherItem[]>([]);
		const showCSVModal = ref(false);
		let prizeRef = ref(JSON.parse(JSON.stringify(props.prize)));
		const rules = validationRules();
		getWallets({ campaignID: props.campaignID });
		getVoucherCodes({ campaignID: props.campaignID });
		const wallets = allWallets();
		const voucherCodes = selectedVoucherCode();
		const loadingVoucherCodes = ref(false);
		const loadingForm = ref(false);

		//dispatchers

		//functions
		watch(prizeRef.value, () => {
			formRef.value.validate();
		});

		const onCancel = () => {
			if (props.method == "Create") prizeRef.value = blankPrize();
			formRef.value.resetFields();
			emit("saved");
		};
		const onSubmit = () => {
			formRef.value
				.validate()
				.then(() => {
					loadingForm.value = true;
					if (props.method == "Create") {
						const campaignObj: CampaignObjectRequest = {
							campaignID: props.campaignID,
							data: prizeRef.value as Prize
						};
						console.log(campaignObj);
						createPrize(campaignObj).then((response: Response) => {
							console.log(response);
							if (response.success === true) {
								message.success(response.message);
							} else {
								message.error(response.message);
							}
							loadingForm.value = false;
							prizeRef.value = blankPrize();
							emit("saved");
						});
					} else if (props.method == "Update") {
						const updatedPrize = {
							id: prizeRef.value.id,
							campaignID: prizeRef.value.campaignID,
							redemptionWalletID:
								prizeRef.value.redemptionWalletID,
							name: prizeRef.value.name,
							description: prizeRef.value.description,
							image: prizeRef.value.image,
							value: prizeRef.value.value,
							expiryDate: prizeRef.value.expiryDate,
							maxNumber: prizeRef.value.maxNumber
						};
						updatePrize(prizeRef.value as Prize).then(
							(response: Response) => {
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								loadingForm.value = false;
								emit("saved");
							}
						);
					}
				})
				.catch((error: ValidateErrorEntity<Prize>) => {
					console.log("error", error);
				});
		};

		async function createVouchersFromCSV() {
			const codes = csvRef.value.map(v => v.voucher);
			console.log(voucherCodes);
			codes.shift();
			let campaignObject: CampaignObjectRequest;
			if (!voucherCodes.value) {
				loadingVoucherCodes.value = true;
				const newVoucherCodes: VoucherCodes = blankVoucherCodes(
					prizeRef.value.id
				);
				newVoucherCodes.codes = codes as string[];
				campaignObject = {
					campaignID: props.campaignID,
					data: newVoucherCodes
				};
				await createVoucherCodes(campaignObject).then(
					(response: Response) => {
						if (response.success === true) {
							const input = document.querySelector(
								'input[type="file"]'
							) as HTMLInputElement;

							if (input) {
								input.value = "";
							}
							console.log(response);
							message.success(response.message);
						} else {
							message.error(response.message);
						}
						showCSVModal.value = false;
						loadingVoucherCodes.value = false;
					}
				);
			} else {
				let existingVoucherCodes = voucherCodes.value;
				if (existingVoucherCodes) {
					loadingVoucherCodes.value = true;
					existingVoucherCodes.codes = codes as string[];
					await updateVoucherCodes(existingVoucherCodes).then(
						(response: Response) => {
							if (response.success === true) {
								console.log(response);
								message.success(response.message);
							} else {
								message.error(response.message);
							}
							showCSVModal.value = false;
							loadingVoucherCodes.value = false;
						}
					);
				}
			}
		}

		function deleteVoucherCodesModal() {
			Modal.warning({
				title: "Warning!",
				content: "All voucher codes will be deleted",
				async onOk() {
					const deleteReq: RequestByID = {
						campaignID: props.campaignID,
						id: voucherCodes.value?.id as string
					};
					await deleteVoucherCodes(deleteReq);
				}
			});
		}

		return {
			onCancel,
			onSubmit,
			prizeRef,
			formRef,
			rules,
			wallets,
			showCSVModal,
			csvRef,
			voucherCodes,
			loadingVoucherCodes,
			loadingForm,
			createVouchersFromCSV,
			deleteVoucherCodesModal
		};
	}
});
