
import {
	defineComponent,
	ref,
	computed,
	ComputedRef,
	onBeforeMount
} from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import CategoryItemComponent from "@/components/vItemCategory/CategoryItemComponent.vue";
import UpdateCreateVItemCategoryForm from "@/components/forms/UpdateCreateVItemCategoryForm.vue";
import {
	allCategories,
	blankCategory,
	getCategory
} from "@/composables/models/use-vItemCategories";
import Search from "@/components/utils/Search.vue";
import { Category } from "@/types/vItemCategory";
import { useRoute } from "vue-router";

export default defineComponent({
	setup() {
		//variables
		const showModal = ref(false);
		const route = useRoute();
		let campaignID = ref(route.params.campaignID.toString());
		let categories: ComputedRef<Category[]> = computed(() => []);
		let filteredCategories = ref(categories.value);
		let newCategory = blankCategory();
		const category = computed(() => newCategory);
		let filterString = ref("");
		//dispatchers
		onBeforeMount(async () => {
			await getCategory({ campaignID: campaignID.value });
			categories = allCategories();
			filteredCategories.value = ref(categories.value).value;
			campaignID = ref(route.params.campaignID.toString());
		});
		//getters

		//functions
		const createVItemCategory = () => {
			showModal.value = true;
		};
		const onCancel = () => {
			showModal.value = false;
		};
		const filtered = (searchString: string) => {
			filterString.value = searchString;
			filteredCategories.value = categories.value.filter(e => {
				return e.name
					.toLowerCase()
					.includes(searchString.toLowerCase());
			});
		};
		const modifiedCategory = () => {
			showModal.value = false;
			filterString.value = "";
			filteredCategories.value = ref(categories.value).value;
		};

		return {
			showModal,
			onCancel,
			createVItemCategory,
			category,
			categories,
			filteredCategories,
			filtered,
			modifiedCategory,
			filterString,
			campaignID
		};
	},
	components: {
		CategoryItemComponent,
		Scroller,
		UpdateCreateVItemCategoryForm,
		Search
	}
});
