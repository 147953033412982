
import { computed, defineComponent, ref } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";

export default defineComponent({
	components: {
		SearchOutlined,
	},
	emits: ["filtered"],
	props: {
		items: {
			type: Object,
			required: true,
		},
		searchString: {
			type: String,
			required: true,
		},
	},

	setup(props, { emit }) {
		//variables
		let itemsProps = computed(() => props.items);
		let searchStr = computed(() => props.searchString);
		//dispatchers

		//getters

		//functions
		const filterSearchItems = (filterQuery: string) => {
			emit("filtered", filterQuery);
		};

		return { filterSearchItems, itemsProps, searchStr };
	},
});
