import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28a69ab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "content-main",
  style: {"padding-top":"20px"}
}
const _hoisted_2 = {
  key: 0,
  class: "bread-crumb"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "panel stretch-height" }
const _hoisted_5 = {
  style: {"padding":"20px 20px 50px 40px"},
  class: "panel stretch-height"
}
const _hoisted_6 = { class: "panel stretch-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNavigation = _resolveComponent("SideNavigation")!
  const _component_PageEditor = _resolveComponent("PageEditor")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_StylesEditor = _resolveComponent("StylesEditor")!
  const _component_AttributesEditor = _resolveComponent("AttributesEditor")!
  const _component_ActionsEditor = _resolveComponent("ActionsEditor")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_Scroller = _resolveComponent("Scroller")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_ShortCuts = _resolveComponent("ShortCuts")!
  const _component_CustomPage = _resolveComponent("CustomPage")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideNavigation),
    (_ctx.campaignRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, "Campaigns / " + _toDisplayString(_ctx.campaignRef.name) + " / Artwork", 1),
          (_ctx.customPage)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.customPage.url ? ` / ${_ctx.customPage.url}` : ``), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, {
      gutter: 10,
      style: {"height":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Scroller, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_collapse, {
                    activeKey: _ctx.activeKey,
                    "onUpdate:activeKey": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeKey) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_collapse_panel, {
                        key: "1",
                        header: "Pages"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PageEditor, { campaignID: _ctx.campaignID }, null, 8, ["campaignID"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_collapse_panel, {
                        key: "4",
                        header: "Styles"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_StylesEditor, {
                            onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.savePage())),
                            campaignID: _ctx.campaignID
                          }, null, 8, ["campaignID"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_collapse_panel, {
                        key: "5",
                        header: "Attributes"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AttributesEditor, {
                            onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.savePage()))
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_collapse_panel, {
                        key: "6",
                        header: "Actions"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ActionsEditor, {
                            onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.savePage()))
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["activeKey"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.campaignID)
                ? (_openBlock(), _createBlock(_component_ShortCuts, {
                    key: 0,
                    onUpdate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.savePage())),
                    campaignID: _ctx.campaignID
                  }, null, 8, ["campaignID"]))
                : _createCommentVNode("", true),
              (_ctx.layout)
                ? (_openBlock(), _createBlock(_component_CustomPage, {
                    key: 1,
                    style: {"transform":"scale(1)"},
                    onUpdate: _cache[5] || (_cache[5] = ($event: any) => (_ctx.savePage())),
                    editable: true,
                    layout: _ctx.layout
                  }, null, 8, ["layout"]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_button, {
                loading: _ctx.saving,
                style: {"margin-top":"10px"},
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.savePage()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.layout)
                ? (_openBlock(), _createBlock(_component_CustomPage, {
                    key: 0,
                    style: {"transform":"scale(1)"},
                    layout: _ctx.layout,
                    editable: false
                  }, null, 8, ["layout"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}