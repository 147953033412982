
import {
	defineComponent,
	ref,
	computed,
	ComputedRef,
	onBeforeMount,
	watchEffect
} from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import CustomItemComponent from "@/components/customComponent/CustomItemComponent.vue";
import UpdateCreateCustomComponentForm from "@/components/forms/UpdateCreateCustomComponentForm.vue";
import {
	allCustomComponents,
	blankCustomComponent,
	getCustomComponents,
	selectCustomComponent
} from "@/composables/models/use-customComponents";
import Search from "@/components/utils/Search.vue";
import { CustomComponent } from "@/types/customComponent";
import { useRoute } from "vue-router";

export default defineComponent({
	setup() {
		//variables
		const showModal = ref(false);
		const route = useRoute();
		let campaignId = ref(route.params.campaignID.toString());
		let customComponents: ComputedRef<CustomComponent[]> = computed(
			() => []
		);
		let filteredCustomComponents = ref(customComponents.value);
		let newCustomComponent = blankCustomComponent();
		const customComponent = computed(() => newCustomComponent);
		let filterString = ref("");
		//dispatchers
		onBeforeMount(async () => {
			console.log(campaignId.value);
			await getCustomComponents({ campaignID: campaignId.value });
			customComponents = allCustomComponents();
			filteredCustomComponents.value = ref(customComponents.value).value;
		});

		//getters

		//functions
		const createCustomComponent = () => {
			showModal.value = true;
		};
		const onCancel = () => {
			showModal.value = false;
		};
		const filtered = (searchString: string) => {
			filterString.value = searchString;
			filteredCustomComponents.value = customComponents.value.filter(
				e => {
					return e.name
						.toLowerCase()
						.includes(searchString.toLowerCase());
				}
			);
		};
		const modifiedCustomComponent = () => {
			showModal.value = false;
			filterString.value = "";
			filteredCustomComponents.value = ref(customComponents.value).value;
		};

		return {
			showModal,
			onCancel,
			createCustomComponent,
			customComponent,
			customComponents,
			filteredCustomComponents,
			filtered,
			modifiedCustomComponent,
			filterString,
			campaignId
		};
	},
	components: {
		CustomItemComponent,
		Scroller,
		UpdateCreateCustomComponentForm,
		Search
	}
});
