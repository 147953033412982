import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { campaignStore } from "./modules/campaignStore";
import { arTemplateStore } from "./modules/arTemplateStore";
import { dropPoolStore } from "./modules/dropPoolStore";
import { categoryStore } from "./modules/categoryStore";

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	reducer: (state: any) => ({ campaignStore: state["campaignStore"] })
});

export default createStore({
	modules: {
		campaignStore,
		arTemplateStore,
		dropPoolStore,
		categoryStore
	},
	plugins: [vuexLocal.plugin]
});
// import { campaignStore } from "./campaignStore";
// if (module.hot) {
// 	module.hot.accept(["./campaignStore"], () => {
// 		store.hotUpdate({
// 			modules: {
// 				campaignStore
// 			}
// 		});
// 	});
// }
