
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import UpdateCreateCustomPageForm from "@/components/forms/UpdateCreateCustomPageForm.vue";
import {
	createCustomPage,
	allCustomPages,
	blankCustomPage,
	deleteCustomPage,
	getCustomPages,
	selectedCustomPage,
	selectedCustomPageByURL
} from "@/composables/models/use-customPages";
import { CustomPage } from "@/types/customPage";
import { useRoute, useRouter } from "vue-router";
import { CampaignObjectRequest } from "@/types/requests";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import {
	saveSelectedContainer,
	selectLayout
} from "@/composables/models/use-containers";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
	name: "PageEditor",
	props: {
		campaignID: {
			type: String,
			required: true
		}
	},
	components: {
		UpdateCreateCustomPageForm
	},
	setup(props) {
		//variables
		getCustomPages({ campaignID: props.campaignID });
		const router = useRouter();
		const methodRef = ref("Create");
		const showModal = ref(false);
		const showDeleteModal = ref(false);
		const newPage = blankCustomPage();
		let page = ref(newPage);
		const pages = allCustomPages();
		const selectedPage = selectedCustomPage();
		const selectedPageRef = ref();
		let pageSelected = false;

		//dispatchers

		//getters
		watch(selectedPage, () => {
			selectedPageRef.value = selectedPage.value;
		});
		watchEffect(() => {
			getCustomPages({ campaignID: props.campaignID });
			if (!pageSelected && pages.value.length > 0) {
				saveSelectedContainer(null);
				gotoPage(pages.value[0]);
				pageSelected = true;
			}
		});

		function gotoPage(page: CustomPage) {
			selectedPageRef.value = page;
			console.log("gotopage");
			console.log(selectedPageRef.value);
			router.push({
				name: `Artwork`,
				params: { custompage: page.url }
			});
			selectLayout(page.layout);
		}

		//functions
		const newCustomPage = () => {
			methodRef.value = "Create";
			page.value = blankCustomPage();
			showModal.value = true;
		};
		const onCancel = () => {
			showModal.value = false;
			showDeleteModal.value = false;
		};
		const modifiedCustomPage = (page: CustomPage | null) => {
			showModal.value = false;
			selectedPageRef.value = page;
			console.log(page);
			if (page) {
				gotoPage(page);
			}
		};
		// const onSelectCustomPage = (selectPage: CustomPage) => {
		// 	selectCustomPage(selectPage.id!);
		// };
		const onEditCustomPage = (editPage: CustomPage) => {
			methodRef.value = "Update";
			page.value = editPage;
			showModal.value = true;
		};
		const onSelectDeleteCustomPage = (editPage: CustomPage) => {
			selectedPageRef.value = editPage;
			showDeleteModal.value = true;
		};
		const onDeleteCustomPage = () => {
			showDeleteModal.value = false;
			deleteCustomPage({
				id: selectedPageRef.value!.id!,
				campaignID: selectedPageRef.value!.campaignID!
			});
		};
		const onDuplicateCustomPage = (editPage: CustomPage) => {
			let pageCopy = JSON.parse(JSON.stringify(editPage));
			pageCopy.name = `${pageCopy.name} copy`;
			pageCopy.url = `${pageCopy.name} copy`;
			delete pageCopy.id;
			delete pageCopy.campaignID;
			delete pageCopy.tableName;
			delete pageCopy.createdAt;
			delete pageCopy.updatedAt;
			const campaignObj: CampaignObjectRequest = {
				campaignID: props.campaignID,
				data: pageCopy
			};

			createCustomPage(campaignObj).then((response: Response) => {
				if (response.success === true) {
					message.success(response.message);
				} else {
					message.error(response.ErrorMessage);
				}
			});
		};

		function isCustom(page: CustomPage): boolean {
			if (
				page.url != "registration-page" &&
				page.url != "enter-pin" &&
				page.url != "vault" &&
				page.url != "map" &&
				page.url != "ar-viewer" &&
				page.url != "campaign-over"
			) {
				return true;
			} else {
				return true;
			}
		}

		return {
			pages,
			showModal,
			page,
			methodRef,
			modifiedCustomPage,
			newCustomPage,
			onCancel,
			onEditCustomPage,
			onSelectDeleteCustomPage,
			onDuplicateCustomPage,
			onDeleteCustomPage,
			showDeleteModal,
			gotoPage,
			isCustom
		};
	}
});
