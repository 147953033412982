
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import {
	blankCustomComponent,
	createCustomComponent,
	updateCustomComponent,
	validationRules
} from "@/composables/models/use-customComponents";
import { defineComponent, ref, watch } from "vue";
import { CustomComponent } from "@/types/customComponent";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { CampaignObjectRequest } from "@/types/requests";
import { customComponentTypes } from "@/templates/customComponents";
// import { v4 as uuidv4 } from "uuid";

export default defineComponent({
	components: {},
	props: {
		customComponent: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		let customComponentRef = ref(
			JSON.parse(JSON.stringify(props.customComponent))
		);
		const rules = validationRules();

		//functions
		watch(customComponentRef.value, () => {
			formRef.value.validate();
		});

		const onCancel = () => {
			if (props.method == "Create")
				customComponentRef.value = blankCustomComponent();
			formRef.value.resetFields();
			emit("saved");
		};
		const changeTemplate = () => {
			const type = customComponentRef.value.type;
			if (type == "popup") {
				customComponentRef.value.container = customComponentTypes[type];
			}
		};
		const setTemplate = () => {
			customComponentRef.value.container =
				customComponentTypes[customComponentRef.value.name];
		};

		const onSubmit = () => {
			formRef.value
				.validate()
				.then(() => {
					if (
						props.method == "Create" ||
						props.method == "Duplicate"
					) {
						// customComponentRef.value.container._id = uuidv4();
						const campaignObj: CampaignObjectRequest = {
							campaignID: props.campaignID,
							data: customComponentRef.value as CustomComponent
						};
						if (props.method == "Duplicate") {
							delete customComponentRef.value.id;
							delete customComponentRef.value.tableName;
							delete customComponentRef.value.createdAt;
							delete customComponentRef.value.updatedAt;
							delete customComponentRef.value.campaignID;
						}
						console.log(campaignObj);
						createCustomComponent(campaignObj).then(
							(response: Response) => {
								console.log(response);
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								customComponentRef.value = blankCustomComponent();
								emit("saved");
							}
						);
					} else if (props.method == "Update") {
						// customComponentRef.value.container._id = uuidv4();
						const updatedCustomComponent = {
							id: customComponentRef.value.id,
							campaignID: customComponentRef.value.campaignID,
							name: customComponentRef.value.name,
							type: customComponentRef.value.type,
							container: customComponentRef.value.container
						};
						updateCustomComponent(
							customComponentRef.value as CustomComponent
						).then((response: Response) => {
							if (response.success === true) {
								message.success(response.message);
							} else {
								message.error(response.message);
							}
							emit("saved");
						});
					}
				})
				.catch((error: ValidateErrorEntity<CustomComponent>) => {
					console.log("error", error);
				});
		};

		return {
			onCancel,
			onSubmit,
			changeTemplate,
			setTemplate,
			customComponentRef,
			formRef,
			rules
		};
	}
});
