import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    style: _normalizeStyle(_ctx.elementRef.styles),
    src: _ctx.elementRef.attributes.src,
    width: _ctx.elementRef.attributes.width,
    height: _ctx.elementRef.attributes.height
  }, null, 12, _hoisted_1))
}