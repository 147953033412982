import { customPageStore } from "@/store/customPageStore";

import { CustomPage } from "@/types/customPage";
import { ComputedRef } from "vue";
import { blankContainer } from "./use-containers";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { customElementStore } from "@/store/containerStore";
import { RequestByCampaignID } from "@/types/requests";

export function allCustomPages(): ComputedRef<CustomPage[]> {
	const items = customPageStore.getItems;
	return items;
}
export function selectCustomPage(itemID: string) {
	customPageStore.commitSelectedItemID(itemID);
}
export function selectCustomPageByURL(url: string) {
	customPageStore.commitSelectedItemURL(url);
	const page = selectedCustomPageByURL();
	if (page.value) {
		customElementStore.commitSelectLayout(page.value!.layout!);
	}
}
export function selectedCustomPage(): ComputedRef<CustomPage | undefined> {
	const item = customPageStore.getSelectedItem;
	return item;
}
export function selectedCustomPageByURL(): ComputedRef<CustomPage | undefined> {
	const item = customPageStore.getSelectedItemByURL;
	return item;
}
export async function getCustomPages(
	params: RequestByCampaignID
): Promise<boolean> {
	return customPageStore.dispatchGetItems(params);
}
export async function createCustomPage(
	item: CampaignObjectRequest
): Promise<Response> {
	return await customPageStore.dispatchCreateItem(item);
}

export async function updateCustomPage(item: CustomPage): Promise<Response> {
	const updated = await customPageStore.dispatchUpdateItem(item);
	// const customPage: CustomPage = updated.data;
	// selectActiveContainer(customPage.layout);
	return updated;
}

export async function deleteCustomPage(delObj: RequestByID): Promise<Response> {
	// customPageStore.commitDeleteItem(itemID);
	// return true;
	return await customPageStore.dispatchDeleteItem(delObj);
}

export function blankCustomPage(): CustomPage {
	const customPage: CustomPage = {
		// id: uuidv4(),
		name: "",
		url: "",
		layout: blankContainer()
	};
	return customPage;
}
