import { vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69a63932"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "btn-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ selected: _ctx.elementRef.active && _ctx.editable })
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editElement && _ctx.editElement(...args))),
          style: _normalizeStyle(_ctx.elementRef.styles)
        }, [
          (_ctx.edit)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateElement())),
                style: _normalizeStyle({ width: _ctx.inputWidth }),
                class: "btn-text-input",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.elementRef.text) = $event))
              }, null, 36)), [
                [_vModelText, _ctx.elementRef.text]
              ])
            : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.elementRef.text), 1))
        ], 4))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.doAction(_ctx.elementRef.action))),
          style: _normalizeStyle(_ctx.elementRef.styles)
        }, _toDisplayString(_ctx.elementRef.text), 5))
  ], 2))
}