import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b37f4f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { class: "submit-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_form, {
      ref: "formSignUpRef",
      model: _ctx.managerSignUpRef,
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          ref: "name",
          name: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              class: "custom-input-style",
              placeholder: "Name",
              value: _ctx.managerSignUpRef.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.managerSignUpRef.name) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }, 512),
        _createVNode(_component_a_form_item, { name: "email" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              class: "custom-input-style",
              placeholder: "Email",
              value: _ctx.managerSignUpRef.email,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.managerSignUpRef.email) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createManagers())),
                class: "login-btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Sign up")
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_a_modal, {
      footer: null,
      visible: _ctx.showModal,
      onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onOK()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_alert, {
          message: "Account created successfully!",
          description: "We have sent you password on your email. You can login using that password.",
          type: "success",
          "show-icon": ""
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            style: {"margin-right":"10px"},
            type: "primary",
            onClick: _ctx.onOK
          }, {
            default: _withCtx(() => [
              _createTextVNode("OK")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}