
import { defineComponent, ref } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true,
		},
	},

	setup(props, { emit }) {
		//variables
		const elementRef = ref(props.element);
		const edit = ref(false);

		return { elementRef, edit };
	},
});
