
import { defineComponent, onMounted, ref } from "vue";
import ArtworkComponent from "@/components/artwork/ArtworkComponent.vue";
import StylesEditor from "@/components/artwork/StylesEditor.vue";
import AttributesEditor from "@/components/artwork/AttributesEditor.vue";
import ActionsEditor from "@/components/artwork/ActionsEditor.vue";
import Scroller from "@/components/utils/Scroller.vue";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import ShortCuts from "@/components/artwork/ShortCuts.vue";
import CustomManagerComponent from "@/components/customComponent/CustomManagerComponent.vue";

import { useRoute } from "vue-router";

import {
	selectedCustomComponent,
	updateCustomComponent
} from "@/composables/models/use-customComponents";
import { selectedLayout } from "@/composables/models/use-containers";
import store from "@/store";

export default defineComponent({
	components: {
		StylesEditor,
		AttributesEditor,
		ActionsEditor,
		Scroller,
		SideNavigation,
		ShortCuts,
		ArtworkComponent,
		CustomManagerComponent
	},
	setup() {
		//variables
		const route = ref(useRoute());
		let customComponent = selectedCustomComponent();
		const campaignID = ref();
		const activeKey = ref();
		const saving = ref(false);
		let layout = selectedLayout();
		const component = ref("");
		const componentValue = ref("");
		const campaignRef = ref();
		let selectedItem = "";
		const editable = ref(true);

		let previousSelected = "";

		//dispatchers

		//getters

		//functions
		onMounted(async () => {
			// selectLayout(blankContainer());
			campaignID.value = route.value.params.campaignID;
			campaignRef.value = store.getters["campaignStore/getSelectedItem"];
		});

		// watchEffect(() => {});

		async function saveCustomComponent() {
			customComponent.value!.campaignID = route.value.params.campaignID.toString();
			saving.value = true;
			console.log(JSON.stringify(customComponent.value?.container));
			await updateCustomComponent(customComponent.value!);
			saving.value = false;
		}

		return {
			customComponent,
			campaignID,
			saveCustomComponent,
			activeKey,
			saving,
			layout,
			component,
			componentValue,
			campaignRef,
			editable
		};
	}
});
