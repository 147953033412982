
import { createDropPool } from "@/composables/models/use-dropPools";
import store from "@/store";
import { DropPool } from "@/types/dropPool";
import { CampaignObjectRequest } from "@/types/requests";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import moment, { Moment } from "moment";
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
	components: {},
	props: {
		dropPool: {
			type: Object,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		let dropPoolRef = ref(JSON.parse(JSON.stringify(props.dropPool)));
		// let campaign = selectCampaign(props.campaignID);
		let campaign = store.getters["campaignStore/getSelectedItem"];
		//dispatchers

		//getters

		//functions
		const dateRange = computed(() => {
			return [
				moment(dropPoolRef.value.startDate),
				moment(dropPoolRef.value.endDate)
			];
		});

		const onCancel = () => {
			emit("saved");
		};
		const onSubmit = async () => {
			const dropPoolObj: CampaignObjectRequest = {
				campaignID: props.campaignID,
				data: dropPoolRef.value as DropPool
			};
			console.log(dropPoolObj);
			const resp = await store.dispatch(
				"dropPoolStore/dispatchCreateItem",
				dropPoolObj
			);
			if (resp.success === true) {
				message.success(resp.message);
			} else {
				message.error(resp.message);
			}
			emit("saved");
		};

		const onChange = (date: Moment, dateString: string) => {
			console.log(dateString);
			dropPoolRef.value.startDate = moment(dateString[0]).format(
				`YYYY-MM-DDTHH:MM:ss.SSSS[Z]`
			);
			dropPoolRef.value.endDate = moment(dateString[1]).format(
				`YYYY-MM-DDTHH:MM:ss.SSSS[Z]`
			);
		};

		const disabledDate = (current: moment.Moment) => {
			// Can not select days before today and today
			const campaignStart = moment(campaign!.startDate);
			const campaignEnd = moment(campaign!.endDate);
			if (
				current.isSameOrAfter(campaignStart) &&
				current.isSameOrBefore(campaignEnd)
			) {
				return false;
			}
			return true;
			//  && current < dayjs().endOf("day");
		};

		return {
			onCancel,
			onSubmit,
			dropPoolRef,
			formRef,
			dateRange,
			onChange,
			disabledDate
		};
	}
});
