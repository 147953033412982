export const vats = {
	unallocated: {
		elements: [
			{
				elements: [],
				name: "Header",
				active: false,
				action: { type: "", value: "" },
				styles: { color: "#ffffffff", fontSize: "23px" },
				attributes: {},
				containers: [],
				_id: "3d62f41c-d751-4a3b-beee-eaa11f0f8753",
				text: "Tap to play",
				type: "Header"
			}
		],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#000000ff",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			borderRadius: "20px",
			flexWrap: "no-wrap",
			width: "150px",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "150px"
		},
		attributes: {},
		containers: [],
		_id: "80ffdaf5-d9ed-4451-9efa-29021d51d14e",
		type: "Container"
	},
	//Prize
	prize: {
		elements: [
			{
				elements: [],
				name: "Header",
				active: false,
				action: { type: "", value: "" },
				styles: { color: "#ffffffff", fontSize: "23px" },
				attributes: {},
				containers: [],
				_id: "3d62f41c-d751-4a3b-beee-eaa11f0f8753",
				text: "Prize ",
				type: "Header"
			},
			{
				elements: [
					{
						elements: [],
						name: "Paragraph",
						active: false,
						action: { type: "", value: "" },
						styles: { color: "#000", fontSize: "12px" },
						attributes: { text: "Paragraph" },
						containers: [],
						_id: "8fafda72-7346-4c07-9719-0d235390b634",
						text: "Paragraph",
						type: "Paragraph"
					}
				],
				name: "Paragraph",
				active: false,
				action: { type: "", value: "" },
				styles: { color: "#ffffffff", fontSize: "12px" },
				attributes: { text: "Paragraph" },
				containers: [],
				_id: "dfa1f83f-33fb-45e8-bdae-16240306df78",
				text: "Tap to redeem",
				type: "Paragraph"
			}
		],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#000000ff",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			borderRadius: "20px",
			flexWrap: "no-wrap",
			width: "150px",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "150px"
		},
		attributes: {},
		containers: [],
		_id: "80ffdaf5-d9ed-4451-9efa-29021d51d14e",
		type: "Container"
	},
	expired: {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000000",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "flex-end",
			overflow: "hidden",
			borderRadius: "20px",
			flexWrap: "no-wrap",
			width: "150px",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "150px"
		},
		attributes: {},
		containers: [
			{
				elements: [
					{
						elements: [],
						name: "Paragraph",
						active: false,
						action: { type: "", value: "" },
						styles: {
							color: "#ffffffff",
							fontSize: "12px",
							lineHeight: "25px"
						},
						attributes: { text: "Paragraph" },
						containers: [],
						_id: "a6372bc7-859e-4e64-a47a-da9bb785078b",
						text: "Expired",
						type: "Paragraph"
					}
				],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					backgroundColor: "#d81b43ff",
					alignItems: "center",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					justifyContent: "space-between",
					flexWrap: "no-wrap",
					width: "100%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "30px"
				},
				attributes: {},
				containers: [],
				_id: "d4e0304c-f32b-492f-a9f0-322232f8873d",
				type: "Container"
			}
		],
		_id: "99520176-bcb9-4408-bab1-c832b8f04a06",
		type: "Container"
	},
	redeemed: {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000000",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "flex-end",
			overflow: "hidden",
			borderRadius: "20px",
			flexWrap: "no-wrap",
			width: "150px",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "150px"
		},
		attributes: {},
		containers: [
			{
				elements: [
					{
						elements: [],
						name: "Paragraph",
						active: false,
						action: { type: "", value: "" },
						styles: {
							color: "#ffffffff",
							fontSize: "12px",
							lineHeight: "25px"
						},
						attributes: { text: "Paragraph" },
						containers: [],
						_id: "a6372bc7-859e-4e64-a47a-da9bb785078b",
						text: "Redeemed",
						type: "Paragraph"
					}
				],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					backgroundColor: "#d81b43ff",
					alignItems: "center",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					justifyContent: "space-between",
					flexWrap: "no-wrap",
					width: "100%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "30px"
				},
				attributes: {},
				containers: [],
				_id: "d4e0304c-f32b-492f-a9f0-322232f8873d",
				type: "Container"
			}
		],
		_id: "99520176-bcb9-4408-bab1-c832b8f04a06",
		type: "Container"
	},
	gamplay: {
		elements: [
			{
				elements: [],
				name: "Paragraph",
				active: true,
				action: { type: "", value: "" },
				styles: {
					width: "",
					fontSize: "12px",
					color: "#000",
					textAlign: "center"
				},
				attributes: { text: "Paragraph" },
				containers: [],
				_id: "63824217-7145-4d92-b19b-ac6bbfe25203",
				text:
					"Delete this text and add a video component from the add menu",
				type: "Paragraph"
			}
		],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			alignItems: "center",
			flexWrap: "no-wrap",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			fontStyle: "normal",
			backgroundRepeat: "no-repeat",
			justifyContent: "center",
			height: "100%"
		},
		attributes: {},
		containers: [],
		_id: "b33b9416-c4b8-4123-a816-2ee777c460df",
		type: "Container"
	},
	redemption: {
		elements: [
			{
				elements: [],
				name: "Paragraph",
				active: true,
				action: { type: "", value: "" },
				styles: { color: "#000", fontSize: "12px" },
				attributes: { text: "Paragraph" },
				containers: [],
				_id: "51b25b19-fecc-4635-84f3-d9f177af8912",
				text: "Delete this text and add the design for redemption",
				type: "Paragraph"
			}
		],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			alignItems: "center",
			flexWrap: "no-wrap",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			fontStyle: "normal",
			backgroundRepeat: "no-repeat",
			justifyContent: "center",
			height: "100%"
		},
		attributes: {},
		containers: [],
		_id: "a21ffbb7-5417-47c5-b397-c782acac2186",
		type: "Container"
	}
};
