import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { Container } from "../types/customPage";
import ApiProtected from "../utils/api-protected";
import { ComputedRef, computed } from "vue";

VuexModule.__useHotUpdate = true;

@Module
class ContainerStore extends VuexModule {
	api = ApiProtected.getInstance();
	//state
	items: Container[] = [];
	selectedItemID = "";
	selectedLayout = {};

	//getter
	get getItems(): ComputedRef<Container[]> {
		return computed(() => this.items);
	}
	get getSelectedItem(): ComputedRef<Container | undefined> {
		return computed(() =>
			this.items.find(e => e._id == this.selectedItemID)
		);
	}
	get getSelectedLayout(): ComputedRef<Container | undefined> {
		return computed(() => this.selectedLayout as Container);
	}

	// mutations
	@Mutation
	commitSaveItems(items: Container[]) {
		this.items = items;
		console.log(this.items);
	}
	@Mutation
	commitSaveItem(item: Container) {
		const index = this.items.findIndex(e => e._id == item._id);
		if (index == -1) {
			this.items.push(item);
		} else {
			this.items[index] = item;
		}
	}
	@Mutation
	commitDeleteItem(itemID: string) {
		const index = this.items.findIndex(e => e._id == itemID);
		if (index != -1) {
			this.items.splice(index, 1);
		}
	}
	@Mutation
	commitSelectItemID(itemID: string) {
		this.selectedItemID = itemID;
	}
	@Mutation
	commitSelectLayout(layout: Container) {
		this.selectedLayout = layout;
	}

	//actions
	@Action
	async dispatchGetItems(): Promise<boolean> {
		const items: Container[] = await this.api.getCustomElements();
		this.commitSaveItems(items);
		return true;
	}
	@Action
	async dispatchGetItemByID(itemID: string): Promise<boolean> {
		const item: Container = await this.api.getCustomElementByID(itemID);
		this.commitSaveItem(item);
		return true;
	}
	@Action
	async dispatchCreateItem(item: Container): Promise<Container> {
		this.commitSaveItem(item);
		return item;
	}
	@Action
	async dispatchUpdateItem(item: Container): Promise<Container> {
		this.commitSaveItem(item);
		return item;
	}
	@Action
	async dispatchDeleteItem(itemID: string): Promise<boolean> {
		this.commitDeleteItem(itemID);
		return true;
	}
}

// register module (could be in any file)
import store from ".";
export const customElementStore = new ContainerStore({
	store,
	name: "container"
});
