import { Campaign } from "@/types/campaign";
import moment from "moment";
import { validate } from "../../utils/validation";

export function blankCampaign(): Campaign {
	const startDate = moment().format("YYYY-MM-DD");
	const endDate = moment()
		.add(1, "week")
		.format("YYYY-MM-DD");
	const campaign: Campaign = {
		name: "",
		subDomain: "",
		description: "",
		startDate: startDate,
		endDate: endDate,
		enabled: false,
		managerID: "123",
		pageAfterAuth: "",
		status: "In development",
		logo: "../vlogo-bg.png",
		googleFont: "",
		fontClass: ""
	};
	return campaign;
}

export function validateCampaign(
	item: Campaign
): { valid: boolean; message: string | null } {
	const validation = [
		{
			field: "name",
			rules: [
				{ type: "required", message: "Name is required" },
				{ type: "length", min: 3, max: 50 },
				{ type: "dataType", value: "string" }
			]
		}
	];
	return validate(item, validation);
}

export function validationRules() {
	const rules = {
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 3,
				max: 50,
				message: "Length should be 3 to 50 characters",
				trigger: "blur"
			}
		],
		description: [
			{
				required: true,
				message: "Description is required",
				trigger: "onBlur"
			},
			{
				min: 3,
				max: 50,
				message: "Length should be 3 to 70 characters",
				trigger: "blur"
			}
		]
	};
	return rules;
}
