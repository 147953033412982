import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { VoucherCodes } from "../types/voucherCodes";
import ApiProtected from "../utils/api-protected";
import { ComputedRef, computed, ref } from "vue";

VuexModule.__useHotUpdate = true;

@Module
class VoucherCodesStore extends VuexModule {
	api = ApiProtected.getInstance();
	//state
	items: VoucherCodes[] = [];
	selectedItemID = "";

	//getter
	get getItems(): ComputedRef<VoucherCodes[]> {
		return computed(() => this.items);
	}
	get getSelectedItem(): ComputedRef<VoucherCodes | undefined> {
		return computed(() =>
			this.items.find(e => e.id == this.selectedItemID)
		);
	}

	get getItemByPrizeID(): ComputedRef<VoucherCodes | undefined> {
		const prize = selectedPrize();
		return computed(() => {
			if (prize.value) {
				return this.items.find(e => e.prizeID == prize.value!.id);
			} else {
				return undefined;
			}
		});
	}

	// mutations
	@Mutation
	commitSaveItems(items: VoucherCodes[]) {
		this.items = items;
		console.log(this.items);
	}
	@Mutation
	commitSaveItem(item: VoucherCodes) {
		const index = this.items.findIndex(e => e.id == item.id);
		if (index == -1) {
			this.items.push(item);
		} else {
			this.items[index] = item;
		}
	}
	@Mutation
	commitDeleteItem(itemID: string) {
		const index = this.items.findIndex(e => e.id == itemID);
		if (index != -1) {
			this.items.splice(index, 1);
		}
	}
	@Mutation
	commitSelectedItemID(itemID: string) {
		this.selectedItemID = itemID;
	}

	//actions
	@Action
	async dispatchGetItems(params: RequestByCampaignID): Promise<boolean> {
		const response: Response = await this.api.getVoucherCodes(params);
		if (response.success === true) {
			const items: VoucherCodes[] = response.data;
			this.commitSaveItems(items);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchGetItemByID(params: RequestByID): Promise<boolean> {
		const response: Response = await this.api.getVoucherCodesByID(params);
		if (response.success === true) {
			const item: VoucherCodes = response.data;
			this.commitSaveItem(item);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchCreateItem(item: CampaignObjectRequest): Promise<Response> {
		const itemCreated: Response = await this.api.createVoucherCodes(item);
		console.log(itemCreated);
		if (itemCreated.success === true) this.commitSaveItem(itemCreated.data);
		return itemCreated;
	}
	@Action
	async dispatchUpdateItem(item: VoucherCodes): Promise<Response> {
		const itemUpdated = await this.api.updateVoucherCodes(item);
		if (itemUpdated.success === true) this.commitSaveItem(itemUpdated.data);
		return itemUpdated;
	}
	@Action
	async dispatchDeleteItem(delObject: RequestByID): Promise<Response> {
		const itemDeleted = await this.api.deleteVoucherCodes(delObject);
		if (itemDeleted.success === true) this.commitDeleteItem(delObject.id);
		return itemDeleted;
	}
}

// register module (could be in any file)
import store from ".";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { selectedPrize } from "@/composables/models/use-prizes";
import { RequestByCampaignID } from "@/types/requests";
export const voucherCodesStore = new VoucherCodesStore({
	store,
	name: "voucherCodes"
});
