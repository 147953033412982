
import {
	defineComponent
	// ref,
	// computed,
	// onBeforeMount,
	// ComputedRef
} from "vue";
import UpdateCreateCampaignForm from "@/components/forms/UpdateCreateCampaignForm.vue";
import CampaignItemComponent from "@/components/campaign/CampaignItemComponent.vue";
import Scroller from "@/components/utils/Scroller.vue";
import Search from "@/components/utils/Search.vue";
// import {
// 	allCampaigns,
// 	blankCampaign,
// 	getCampaigns
// } from "../composables/models/use-campaigns";
import { Campaign } from "@/types/campaign";
import { blankCampaign } from "@/composables/models/use-campaigns";
import store from "@/store";

export default defineComponent({
	data() {
		return {
			showModal: false,
			filteredCampaigns: [] as Campaign[],
			filterString: "",
			campaign: {} as Campaign
		};
	},
	beforeMount() {
		this.loadCampaigns();
	},
	methods: {
		async loadCampaigns() {
			await store.dispatch("campaignStore/dispatchGetItems");
			this.filteredCampaigns = this.campaigns;
		},
		createCampaign() {
			this.campaign = blankCampaign();
			this.showModal = true;
		},
		onCancel() {
			this.showModal = false;
		},
		modifiedCampaign() {
			this.showModal = false;
			this.filterString = "";
			this.filteredCampaigns = this.campaigns;
		},
		filtered(searchString: string) {
			this.filterString = searchString;
			this.filteredCampaigns = this.campaigns.filter((e: Campaign) => {
				return e.name
					.toLowerCase()
					.includes(searchString.toLowerCase());
			});
		}
	},
	computed: {
		campaigns() {
			return store.getters["campaignStore/getItems"];
		}
	},
	// setup() {
	// 	//variables
	// 	const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;
	// 	const showModal = ref(false);
	// 	let campaigns: ComputedRef<Campaign[]> = computed(() => []);
	// 	let filteredCampaigns = ref(campaigns.value);
	// 	let newCampaign = blankCampaign();
	// 	const campaign = computed(() => newCampaign);
	// 	let filterString = ref("");
	// 	//dispatchers
	// 	onBeforeMount(async () => {
	// 		await getCampaigns();
	// 		campaigns = allCampaigns();
	// 		filteredCampaigns.value = ref(campaigns.value).value;
	// 	});

	// 	//getters

	// 	//functions
	// 	const createCampaign = () => {
	// 		showModal.value = true;
	// 	};
	// 	const onCancel = () => {
	// 		showModal.value = false;
	// 	};
	// 	const filtered = (searchString: string) => {
	// 		filterString.value = searchString;
	// 		filteredCampaigns.value = campaigns.value.filter(e => {
	// 			return e.name
	// 				.toLowerCase()
	// 				.includes(searchString.toLowerCase());
	// 		});
	// 	};
	// 	const modifiedCampaign = () => {
	// 		showModal.value = false;
	// 		filterString.value = "";
	// 		filteredCampaigns.value = ref(campaigns.value).value;
	// 	};

	// 	return {
	// 		deadline,
	// 		showModal,
	// 		createCampaign,
	// 		campaigns,
	// 		filteredCampaigns,
	// 		filtered,
	// 		campaign,
	// 		onCancel,
	// 		modifiedCampaign,
	// 		filterString
	// 	};
	// },
	components: {
		CampaignItemComponent,
		UpdateCreateCampaignForm,
		Scroller,
		Search
	}
});
