
import { ref, computed } from "vue";
import { defineComponent } from "vue";
import UpdateCreateWalletForm from "@/components/forms/UpdateCreateWalletForm.vue";
import { deleteWallet } from "@/composables/models/use-wallets";
import { RequestByID } from "@/types/requests";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";

export default defineComponent({
	props: {
		wallet: {
			type: Object,
			required: true
		}
	},
	emits: ["delete", "update"],
	setup(props, { emit }) {
		//variables
		const showModal = ref(false);
		const walletRef = computed(() => props.wallet);
		let showDeleteModal = ref(false);
		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			showModal.value = false;
		};
		const onCancelDelete = () => {
			showDeleteModal.value = false;
		};
		const onDelete = () => {
			showDeleteModal.value = true;
		};
		const handleDelete = (id: string, campaignID: string) => {
			showDeleteModal.value = false;
			const delWallet: RequestByID = {
				id: id,
				campaignID: campaignID
			};
			deleteWallet(delWallet).then((response: Response) => {
				if (response.success === true) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				emit("delete");
			});
		};
		const changesSaved = () => {
			showModal.value = false;
			emit("update");
		};

		return {
			onCancel,
			showModal,
			walletRef,
			showDeleteModal,
			handleDelete,
			onDelete,
			onCancelDelete,
			changesSaved
		};
	},
	components: {
		UpdateCreateWalletForm
	}
});
