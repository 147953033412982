import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97115abc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "small-nav position-small-nav"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_fk_color_picker = _resolveComponent("fk-color-picker")!
  const _component_AssetManagerComponent = _resolveComponent("AssetManagerComponent")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.containerRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_a_popover, {
              visible: _ctx.elementsVisible,
              "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.elementsVisible) = $event)),
              title: "Add Element",
              placement: "right",
              trigger: "click"
            }, {
              content: _withCtx(() => [
                _createElementVNode("p", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAddContainer && _ctx.onAddContainer(...args)))
                }, "Container"),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
                  return (_openBlock(), _createElementBlock("p", {
                    onClick: ($event: any) => (_ctx.onAddElement(element)),
                    key: index
                  }, _toDisplayString(element.name), 9, _hoisted_2))
                }), 128))
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  size: "small",
                  class: "small-nav-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "plus" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["visible"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_a_tooltip, {
              title: "Delete",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: _ctx.onDelete,
                  size: "small",
                  class: "small-nav-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "trash" })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_a_tooltip, {
              title: 
					_ctx.containerRef?.styles.flexDirection == 'row'
						? 'Column'
						: 'Row'
				,
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: _ctx.toggleFlexDirection,
                  size: "small",
                  class: "small-nav-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: 
							_ctx.containerRef?.styles.flexDirection == 'row'
								? 'arrows-alt-v'
								: 'arrows-alt-h'
						
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_a_tooltip, {
              title: "Fill",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_popover, { trigger: "click" }, {
                  content: _withCtx(() => [
                    _createVNode(_component_fk_color_picker, {
                      color: _ctx.containerRef.styles.backgroundColor,
                      "onUpdate:color": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.containerRef.styles.backgroundColor) = $event)),
                      format: "hex8",
                      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updatePage()))
                    }, null, 8, ["color"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      size: "small",
                      class: "small-nav-btn"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "fill" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_a_tooltip, {
              title: "Stroke",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_popover, {
                  visible: _ctx.strokeVisible,
                  "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.strokeVisible) = $event)),
                  title: "Add Element",
                  placement: "right",
                  trigger: "click"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("p", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setBorderStyle('none')))
                    }, "none"),
                    _createElementVNode("p", {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setBorderStyle('solid')))
                    }, "solid"),
                    _createElementVNode("p", {
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setBorderStyle('dashed')))
                    }, "dashed"),
                    _createElementVNode("p", {
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setBorderStyle('dotted')))
                    }, "dotted")
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      size: "small",
                      class: "small-nav-btn"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "minus" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["visible"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_a_tooltip, {
              title: "Background image",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showModal = true)),
                  size: "small",
                  class: "small-nav-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "image" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_modal, {
      footer: null,
      visible: _ctx.showModal,
      title: 'Assets',
      onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showModal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AssetManagerComponent, {
          onSetAsset: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setAsset($event)))
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}