import { walletStore } from "@/store/walletStore";
import { Wallet } from "@/types/wallet";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { v4 as uuidv4 } from "uuid";
import { RequestByCampaignID } from "@/types/requests";

export function allWallets(): ComputedRef<Wallet[]> {
	const items = walletStore.getItems;
	return items;
}
export function selectedWallet(
	itemID: string
): ComputedRef<Wallet | undefined> {
	walletStore.commitSelectedItemID(itemID);
	const item = walletStore.getSelectedItem;
	return item;
}
export async function getWallets(params: RequestByCampaignID): Promise<boolean> {
	return walletStore.dispatchGetItems(params);
}
export async function createWallet(
	item: CampaignObjectRequest
): Promise<Response> {
	return await walletStore.dispatchCreateItem(item);
}

export async function updateWallet(item: Wallet): Promise<Response> {
	return await walletStore.dispatchUpdateItem(item);
}

export async function deleteWallet(delObj: RequestByID): Promise<Response> {
	return await walletStore.dispatchDeleteItem(delObj);
}

export function blankWallet(): Wallet {
	const wallet: Wallet = {
		name: "",
		userID: uuidv4(),
		type: "merchant",
		image: ""
	};
	return wallet;
}

export function validationRules() {
	const rules = {
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 1,
				max: 50,
				message: "Length should be 1 to 50",
				trigger: "blur"
			}
		]
	};
	return rules;
}
