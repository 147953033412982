
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import CustomPage from "@/components/artwork/CustomPage.vue";
import StylesEditor from "@/components/artwork/StylesEditor.vue";
import AttributesEditor from "@/components/artwork/AttributesEditor.vue";
import PageEditor from "@/components/artwork/PageEditor.vue";
import ActionsEditor from "@/components/artwork/ActionsEditor.vue";
import Scroller from "@/components/utils/Scroller.vue";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import ShortCuts from "@/components/artwork/ShortCuts.vue";

import {
	selectCustomPageByURL,
	selectedCustomPageByURL,
	updateCustomPage
} from "../composables/models/use-customPages";
import { useRoute, useRouter } from "vue-router";
import {
	blankContainer,
	selectedContainer,
	selectedLayout,
	selectLayout,
	setAllContainersInactive
} from "@/composables/models/use-containers";
import store from "@/store";

export default defineComponent({
	components: {
		CustomPage,
		PageEditor,
		StylesEditor,
		AttributesEditor,
		ActionsEditor,
		Scroller,
		SideNavigation,
		ShortCuts
	},
	setup() {
		//variables
		const route = ref(useRoute());
		const router = useRouter();
		selectCustomPageByURL(route.value.params.custompage as string);
		let customPage = selectedCustomPageByURL();
		let selectedCustomPageRef = ref();
		let layout = selectedLayout();
		const campaignRef = ref();

		const campaignID = ref();
		const activeKey = ref();
		const saving = ref(false);

		//dispatchers

		//getters

		//functions
		onMounted(async () => {
			selectLayout(blankContainer());
			campaignRef.value = store.getters["campaignStore/getSelectedItem"];
			campaignID.value = route.value.params.campaignID;
			selectCustomPageByURL(route.value.params.custompage as string);
			console.log("customPage.value");
			console.log(customPage.value);
			if (customPage.value) {
				selectLayout(customPage.value.layout);
				const layout = selectedLayout();
				selectedCustomPageRef.value = customPage.value;
				setAllContainersInactive(layout!.value!, []);
			}
			setTimeout(() => {
				activeKey.value = ["1"];
			}, 500);
		});
		watch(route.value, to => {
			selectCustomPageByURL(to.params.custompage as string);
			customPage = selectedCustomPageByURL();
			// console.log(customPage.value);
			if (customPage.value) {
				selectedCustomPageRef.value = customPage.value;
				selectLayout(customPage.value.layout);
				const layout = selectedLayout();
				setAllContainersInactive(layout!.value!, []);
			}
		});

		async function savePage() {
			selectedCustomPageRef.value!.campaignID = route.value.params.campaignID.toString();
			saving.value = true;
			console.log(selectedCustomPageRef.value);
			await updateCustomPage(selectedCustomPageRef.value!);
			saving.value = false;
		}

		return {
			customPage,
			campaignID,
			selectedCustomPageRef,
			savePage,
			activeKey,
			saving,
			layout,
			campaignRef
		};
	}
});
