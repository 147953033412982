export enum Framework {
	BLIPPAR = "blippar",
	AFRAME = "aframe"
}

export enum ARType {
	SURFACE_TRACKING = "surface_tracking",
	MARKER_TRACKING = "marker_tracking",
	GPS_TRACKING = "gps_tracking"
}

export interface ARTemplate {
	id?: string;
	campaignID?: string;
	name: string;
	description: string;
	systemTemplate: boolean;
	ARType: ARType;
	framework: Framework;
	SDKToken: string;
	code: string;
}

export interface BaseARTemplate {
	type: string;
	code: string;
}
