
import {
	createCustomPage,
	updateCustomPage,
	blankCustomPage
} from "@/composables/models/use-customPages";
import { CustomPage } from "@/types/customPage";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest } from "@/types/requests";

export default defineComponent({
	components: {},
	props: {
		customPage: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const methodRef = ref(props.method);
		let customPageRef = ref(JSON.parse(JSON.stringify(props.customPage)));

		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			if (props.method == "Create")
				customPageRef.value = blankCustomPage();
			emit("saved", null);
		};
		const onSubmit = async () => {
			if (props.method == "Create") {
				const campaignObj: CampaignObjectRequest = {
					campaignID: props.campaignID,
					data: customPageRef.value
				};
				console.log(campaignObj);
				createCustomPage(campaignObj).then((response: Response) => {
					if (response.success === true) {
						message.success(response.message);
					} else {
						message.error(response.ErrorMessage);
					}
					emit("saved", response.data);
				});
				customPageRef.value = blankCustomPage();
			} else if (props.method == "Update") {
				const resp = await updateCustomPage(
					customPageRef.value as CustomPage
				);
				emit("saved", resp.data);
			}
		};

		function isCustom(url: string, name: string): boolean {
			if (
				url.toLowerCase() != "login" &&
				url.toLowerCase() != "wallet" &&
				url.toLowerCase() != "map" &&
				url.toLowerCase() != "ar-view" &&
				name.toLowerCase() != "login" &&
				name.toLowerCase() != "wallet" &&
				name.toLowerCase() != "map" &&
				name.toLowerCase() != "ar-view"
			) {
				return true;
			} else {
				return false;
			}
		}

		return {
			onCancel,
			onSubmit,
			customPageRef,
			methodRef,
			isCustom
		};
	}
});
