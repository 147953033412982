import { vItemCategoryStore } from "@/store/vItemCategoryStore";
import { Category } from "@/types/vItemCategory";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";

import { vats } from "../../templates/vatTemplates";
import { v4 as uuidv4 } from "uuid";
import { RequestByCampaignID } from "@/types/requests";

export function allCategories(): ComputedRef<Category[]> {
	const items = vItemCategoryStore.getItems;
	return items;
}
export function selectCategory(itemID: string): void {
	vItemCategoryStore.commitSelectedItemID(itemID);
}
export function selectedCategory(): ComputedRef<Category | undefined> {
	return vItemCategoryStore.getSelectedItem;
}
export async function getCategory(
	params: RequestByCampaignID
): Promise<boolean> {
	return vItemCategoryStore.dispatchGetItems(params);
}
export async function createCategory(
	item: CampaignObjectRequest
): Promise<Response> {
	return await vItemCategoryStore.dispatchCreateItem(item);
}

export async function updateCatgory(item: Category): Promise<Response> {
	return await vItemCategoryStore.dispatchUpdateItem(item);
}

export async function deleteCategory(delObj: RequestByID): Promise<Response> {
	return await vItemCategoryStore.dispatchDeleteItem(delObj);
}

export function blankCategory(): Category {
	const category: Category = {
		name: "",
		description: "",
		asset: "",
		assetSize: 0.4,
		image: "",
		imageSize: 50,
		unAllocatedComponent: vats.unallocated
	};
	category.unAllocatedComponent!._id = uuidv4();
	return category;
}

export function validationRules() {
	const rules = {
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 1,
				max: 50,
				message: "Length should be 1 to 50",
				trigger: "blur"
			}
		]
	};
	return rules;
}
