import { Campaign } from "@/types/campaign";
import { Response } from "@/utils/response";
import ApiProtected from "@/utils/api-protected";
import {
	ARTemplate,
	ARType,
	BaseARTemplate,
	Framework
} from "@/types/arTemplate";
import store from "..";
import {
	CampaignObjectRequest,
	RequestByCampaignID,
	RequestByID
} from "@/types/requests";
const api = ApiProtected.getInstance();

export const arTemplateStore = {
	namespaced: true,
	state: {
		items: [] as ARTemplate[],
		baseTemplates: [] as BaseARTemplate[],
		selectedItemID: ""
	},
	mutations: {
		saveItems(state: any, items: ARTemplate[]) {
			state.items = items;
		},
		commitSaveItem(state: any, item: ARTemplate) {
			const index = state.items.findIndex(
				(e: Campaign) => e.id == item.id
			);
			if (index == -1) {
				state.items.push(item);
			} else {
				state.items[index] = item;
			}
			console.log(state.items);
		},

		commitDeleteItem(state: any, itemID: string) {
			const index = state.items.findIndex(
				(e: ARTemplate) => e.id == itemID
			);
			if (index != -1) {
				state.items.splice(index, 1);
			}
		},

		commitSelectItemID(state: any, itemID: string) {
			state.selectedItemID = itemID;
		},
		saveBaseTemplates(state: any, templates: BaseARTemplate[]) {
			state.baseTemplates = templates;
		}
	},
	getters: {
		getItems(state: any): ARTemplate[] {
			return state.items;
		},
		getBaseARTemplates(state: any): BaseARTemplate[] {
			return state.baseTemplates;
		},
		getSelectedItem(state: any): ARTemplate | undefined {
			return state.items.find(
				(e: ARTemplate) => e.id == state.selectedItemID
			);
		}
	},
	actions: {
		blankARTemplate({ commit }: any): Promise<ARTemplate> {
			return new Promise(resolve => {
				const arTemplate: ARTemplate = {
					name: "",
					description: "",
					systemTemplate: false,
					ARType: ARType.SURFACE_TRACKING,
					framework: Framework.BLIPPAR,
					SDKToken: "",
					code: ""
				};
				resolve(arTemplate);
			});
		},
		async dispatchGetItems({ commit }: any): Promise<boolean> {
			const campaignID: RequestByCampaignID = {
				campaignID: store.getters["campaignStore/getSelectedItem"].id
			};
			const response: Response = await api.getARTemplates(campaignID);
			console.log(response);
			if (response.success === true) {
				const items: ARTemplate[] = response.data;
				commit("saveItems", items);
				return true;
			} else {
				return false;
			}
		},
		async dispatchGetBaseTemplates({ commit }: any): Promise<boolean> {
			const response: Response = await api.getBaseARTemplates();
			console.log(response);
			if (response.success === true) {
				const items: BaseARTemplate[] = response.data;
				console.log(items);
				commit("saveBaseTemplates", items);
				return true;
			} else {
				return false;
			}
		},

		async dispatchGetItemByID(
			{ commit }: any,
			params: RequestByID
		): Promise<Response> {
			const response: Response = await api.getARTemplateByID(params);
			if (response.success === true) {
				const item: ARTemplate = response.data;
				commit("commitSaveItem", item);
				commit("commitSelectedItemID", params.id);
			}
			return response;
		},

		async dispatchCreateItem(
			{ commit }: any,
			item: CampaignObjectRequest
		): Promise<Response> {
			console.log(item);
			const itemCreated = await api.createARTemplate(item);
			console.log(itemCreated);
			if (itemCreated.success === true)
				commit("commitSaveItem", itemCreated.data);
			return itemCreated;
		},

		async dispatchUpdateItem(
			{ commit }: any,
			item: ARTemplate
		): Promise<Response> {
			const itemUpdated = await api.updateARTemplate(item);
			console.log(itemUpdated);
			if (itemUpdated.success === true)
				commit("commitSaveItem", itemUpdated.data);
			return itemUpdated;
		},
		async dispatchDeleteItem(
			{ commit }: any,
			params: RequestByID
		): Promise<Response> {
			const itemDeleted = await api.deleteARTemplate(params);
			if (itemDeleted.success === true)
				commit("commitDeleteItem", params.id);
			return itemDeleted;
		}
	}
};
