
import {
	defineComponent,
	ref,
	computed,
	ComputedRef,
	onBeforeMount
} from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import WalletItemComponent from "@/components/wallet/WalletItemComponent.vue";
import UpdateCreateWalletForm from "@/components/forms/UpdateCreateWalletForm.vue";
import {
	allWallets,
	blankWallet,
	getWallets
} from "@/composables/models/use-wallets";
import Search from "@/components/utils/Search.vue";
import { Wallet } from "@/types/wallet";
import { useRoute } from "vue-router";

export default defineComponent({
	setup() {
		//variables
		const showModal = ref(false);
		const route = useRoute();
		let campaignId = ref(route.params.campaignID.toString());
		let wallets: ComputedRef<Wallet[]> = computed(() => []);
		let filteredWallets = ref(wallets.value);
		let newWallet = blankWallet();
		const wallet = computed(() => newWallet);
		let filterString = ref("");
		//dispatchers
		onBeforeMount(async () => {
			await getWallets({ campaignID: campaignId.value });
			wallets = allWallets();
			filteredWallets.value = ref(wallets.value).value;
		});

		//getters

		//functions
		const createWallet = () => {
			showModal.value = true;
		};
		const onCancel = () => {
			showModal.value = false;
		};
		const filtered = (searchString: string) => {
			filterString.value = searchString;
			filteredWallets.value = wallets.value.filter(e => {
				return e.name
					.toLowerCase()
					.includes(searchString.toLowerCase());
			});
		};
		const modifiedWallet = () => {
			showModal.value = false;
			filterString.value = "";
			filteredWallets.value = ref(wallets.value).value;
		};

		return {
			showModal,
			onCancel,
			createWallet,
			wallet,
			wallets,
			filtered,
			filteredWallets,
			modifiedWallet,
			filterString,
			campaignId
		};
	},
	components: {
		WalletItemComponent,
		Scroller,
		UpdateCreateWalletForm,
		Search
	}
});
