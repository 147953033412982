
import {
	dropLocationValidationRules,
	updateDropPool,
} from "@/composables/models/use-dropPools";
import { DropLocation, DropPool } from "@/types/dropPool";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
	components: {},
	props: {
		dropLocation: {
			type: Object,
			required: true,
		},
		method: {
			type: String,
			required: true,
			default: "Create",
		},
		dropPool: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	emits: ["done"],
	setup(props, { emit }) {
		//variables
		const formRef = ref();
		const dropLocationRef = ref(JSON.parse(JSON.stringify(props.dropLocation)));
		const dropPoolRef = ref(props.dropPool);
		let showDeleteModal = ref(false);
		const methodRef = ref(props.method);
		const rules = dropLocationValidationRules();
		//dispatchers
		watch(dropLocationRef.value, () => {
			formRef.value.validate();
		});
		//getters

		//functions
		const onCancel = () => {
			emit("done");
		};
		const onSubmit = () => {
			formRef.value
				.validate()
				.then(() => {
					if (props.method == "Create") {
						dropPoolRef.value.dropLocations.push(dropLocationRef.value);
					} else if (props.method == "Update") {
						dropPoolRef.value.dropLocations[props.index] =
							dropLocationRef.value;
					}
					updateDropPool(dropPoolRef.value as DropPool).then(
						(response: Response) => {
							if (response.success === true) {
								message.success(response.message);
							} else {
								message.error(response.message);
							}
							emit("done");
						}
					);
				})
				.catch((error: ValidateErrorEntity<DropLocation>) => {
					console.log("error", error);
				});
		};
		const handleDelete = () => {
			dropPoolRef.value.dropLocations.splice(props.index, 1);
			showDeleteModal.value = false;
			updateDropPool(dropPoolRef.value as DropPool).then(
				(response: Response) => {
					if (response.success === true) {
						message.success(response.message);
					} else {
						message.error(response.message);
					}
					emit("done");
				}
			);
		};

		return {
			formRef,
			onCancel,
			onSubmit,
			dropLocationRef,
			rules,
			methodRef,
			handleDelete,
			showDeleteModal,
		};
	},
});
