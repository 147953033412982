
import {
	blankContainer,
	blankElement,
	deleteSeclectedContainer,
	saveSelectedContainer,
	selectContainer,
	selectedContainer
} from "@/composables/models/use-containers";
import {
	selectedCustomPage,
	updateCustomPage
} from "@/composables/models/use-customPages";
import router from "@/router";
import { Container, CustomPage } from "@/types/customPage";
import { defineComponent, onMounted, ref, watch } from "vue";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";
import { useRoute } from "vue-router";
import { Asset } from "@/types/asset";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
	props: {
		campaignID: {
			type: String,
			required: true
		}
	},
	components: { AssetManagerComponent },
	emits: ["update"],
	setup(props, { emit }) {
		let container = selectedContainer();
		let selectedPage = selectedCustomPage();
		let containerRef = ref<Container | null>();
		let selectedPageRef = ref<CustomPage | null>();
		const elementsVisible = ref(false);
		const strokeVisible = ref(false);
		const showModal = ref(false);

		onMounted(() => {
			containerRef.value = container.value;
			selectedPageRef.value = selectedPage.value;
		});

		watch(container, () => {
			containerRef.value = container.value;
		});

		const onDelete = () => {
			deleteSeclectedContainer(containerRef.value!._id);
			updatePage();
		};
		const onAddContainer = () => {
			containerRef.value!.containers!.push(blankContainer());
			saveSelectedContainer(containerRef.value!);
			elementsVisible.value = false;
			updatePage();
		};

		function toggleFlexDirection() {
			if (containerRef.value?.styles.flexDirection == "row") {
				containerRef.value.styles.flexDirection = "column";
			} else {
				containerRef.value!.styles.flexDirection = "row";
			}
			updatePage();
		}
		function setBorderStyle(value: string) {
			containerRef.value!.styles.borderStyle = value;
			updatePage();
		}

		function setAsset(asset: Asset) {
			containerRef.value!.styles.backgroundImageAsset = asset;
			containerRef.value!.styles.backgroundImage = `url('${asset.url}')`;
			showModal.value = false;
			updatePage();
		}

		const elements = ref<Container[]>([
			blankElement(
				"Header",
				"Header",
				"Header",
				{ type: "", value: "" },
				{ id: "" },
				{
					color: "#000",
					fontSize: "23px"
				}
			),
			blankElement(
				"Paragraph",
				"Paragraph",
				"Paragraph",
				{ type: "", value: "" },
				{ text: "Paragraph" },
				{
					color: "#000",
					fontSize: "12px"
				}
			),
			blankElement(
				"Icon",
				"Icon",
				"Icon",
				{ type: "", value: "" },
				{ icon: "times" },
				{
					color: "#000",
					fontSize: "12px"
				}
			),
			blankElement(
				"Checkbox",
				"Checkbox",
				"Checkbox",
				{ type: "", value: "" },
				{ id: "", label: "checkbox" },
				{
					color: "#000",
					fontSize: "12px"
				}
			),
			blankElement(
				"Input Text",
				"Input",
				"",
				{ type: "", value: "" },
				{ id: "", placeholder: "Placeholder", type: "text" },
				{
					fontSize: "12px"
				}
			),
			blankElement(
				"Text Area",
				"TextArea",
				"",
				{ type: "", value: "" },
				{ id: "", placeholder: "Placeholder", type: "text" },
				{
					fontFamily: "Arial, Helvetica, sans-serif",
					fontSize: "12px"
				}
			),
			blankElement(
				"Button",
				"Button",
				"Click me",
				{ type: "", value: "" },
				{ id: "" },
				{
					borderStyle: "solid",
					borderWidth: "1px",
					borderRadius: "20px"
				}
			),
			blankElement(
				"Scroller",
				"Scroller",
				"Click me",
				{ type: "", value: "" },
				{ id: "" },
				{
					borderStyle: "solid",
					borderWidth: "1px",
					borderRadius: "20px"
				}
			),
			blankElement("AR", "AR", "", { type: "", value: "" }, {}, {}),
			blankElement("Map", "Map", "", { type: "", value: "" }, {}, {}),
			blankElement(
				"Video",
				"Video",
				"",
				{ type: "", value: "" },
				{
					id: "",
					src: "http://media.w3.org/2010/05/sintel/trailer.mp4",
					type: "video/mp4"
				},
				{
					width: "100%"
				}
			),
			blankElement(
				"IFrame",
				"IFrame",
				"",
				{ type: "", value: "" },
				{
					id: "",
					src: "",
					width: "100%",
					height: "100%"
				},
				{}
			)
		]);

		//dispatchers

		//getters

		//functions
		const onAddElement = (element: Container) => {
			elementsVisible.value = false;
			element._id = uuidv4();
			if (containerRef.value) {
				containerRef.value!.elements!.push(
					JSON.parse(JSON.stringify(element)) as Container
				);
				saveSelectedContainer(containerRef.value!);
				updatePage();
			}
		};

		function updatePage() {
			emit("update");
		}

		return {
			onDelete,
			onAddContainer,
			toggleFlexDirection,
			elementsVisible,
			strokeVisible,
			showModal,
			containerRef,
			setBorderStyle,
			setAsset,
			elements,
			onAddElement,
			updatePage
		};
	}
});
