
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import UpdateCreateDropLocationForm from "@/components/forms/UpdateCreateDropLocationForm.vue";
import Scroller from "@/components/utils/Scroller.vue";
import {
	blankDropLocation,
	updateDropPool
} from "@/composables/models/use-dropPools";
import { DropLocation, DropPool } from "@/types/dropPool";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import store from "@/store";

export default defineComponent({
	components: { UpdateCreateDropLocationForm, Scroller },
	data() {
		return {
			method: "Create",
			section: "map",
			showModal: false,
			locationIndex: 0,
			showCSVModal: false,
			csv: [] as DropLocation[],
			dropLocation: null as DropLocation | null
		};
	},
	methods: {
		gotoDropLocation(res: any) {
			this.method = "Create";
			const place: DropLocation = {
				placeName: res.place_name,
				latitude: res.center[1],
				longitude: res.center[0]
			};
			this.dropLocation = place;
			this.showModal = true;
		},
		closeModal() {
			this.method = "Create";
			this.showModal = false;
			this.showCSVModal = false;
		},
		onUpdate(index: number) {
			this.method = "Update";
			this.locationIndex = index;
			this.dropLocation = this.selectedDropPool.dropLocations[index];
			this.showModal = true;
		},
		dragMarker(result: any, index: number) {
			this.selectedDropPool.dropLocations[index].placeName = "";
			this.selectedDropPool.dropLocations[index].latitude =
				result.target._lngLat.lat;
			this.selectedDropPool.dropLocations[index].longitude =
				result.target._lngLat.lng;
		},
		addDroplocation(result: any) {
			if (this.method != "Update") {
				const place: DropLocation = {
					placeName: "",
					latitude: result.lngLat.lat,
					longitude: result.lngLat.lng
				};
				this.dropLocation = place;
				this.showModal = true;
			}
		},
		deleteAllLocation() {
			this.selectedDropPool.dropLocations = [];
			updateDropPool(this.selectedDropPool as DropPool).then(
				(response: Response) => {
					if (response.success === true) {
						message.success(response.message);
					} else {
						message.error(response.message);
					}
				}
			);
		},
		createDropLocationsFromCSV() {
			this.selectedDropPool.dropLocations = [];
			for (let i = 1; i < this.csv.length; i++) {
				this.selectedDropPool.dropLocations.push(
					this.csv[i] as DropLocation
				);
			}
			this.showCSVModal = false;
			updateDropPool(this.selectedDropPool as DropPool).then(
				(response: Response) => {
					const input = document.querySelector(
						'input[type="file"]'
					) as HTMLInputElement;

					if (input) {
						input.value = "";
					}

					if (response.success === true) {
						message.success(response.message);
					} else {
						message.error(response.message);
					}
				}
			);
		}
	},
	computed: {
		selectedDropPool(): DropPool {
			return store.getters["dropPoolStore/getSelectedItem"];
		}
	}
});
