import { voucherCodesStore } from "@/store/voucherCodesStore";
import { VoucherCodes } from "@/types/voucherCodes";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { v4 as uuidv4 } from "uuid";
import { RequestByCampaignID } from "@/types/requests";

export function allVoucherCodes(): ComputedRef<VoucherCodes[]> {
	const items = voucherCodesStore.getItems;
	return items;
}
export function selectedVoucherCode(): ComputedRef<VoucherCodes | undefined> {
	const item = voucherCodesStore.getItemByPrizeID;
	return item;
}
export async function getVoucherCodes(
	params: RequestByCampaignID
): Promise<boolean> {
	return voucherCodesStore.dispatchGetItems(params);
}
export async function createVoucherCodes(
	item: CampaignObjectRequest
): Promise<Response> {
	return await voucherCodesStore.dispatchCreateItem(item);
}

export async function updateVoucherCodes(
	item: VoucherCodes
): Promise<Response> {
	return await voucherCodesStore.dispatchUpdateItem(item);
}

export async function deleteVoucherCodes(
	delObj: RequestByID
): Promise<Response> {
	return await voucherCodesStore.dispatchDeleteItem(delObj);
}

export function blankVoucherCodes(prizeID: string): VoucherCodes {
	const voucherCodes: VoucherCodes = {
		prizeID: prizeID,
		total: 0,
		remaining: 0,
		codes: []
	};
	return voucherCodes;
}

export function validationRules() {
	const rules = {
		// name: [
		// 	{ required: true, message: "Name is required", trigger: "onBlur" },
		// 	{
		// 		min: 1,
		// 		max: 50,
		// 		message: "Length should be 1 to 50",
		// 		trigger: "blur"
		// 	}
		// ]
	};
	return rules;
}
