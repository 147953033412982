
import { selectedContainer } from "@/composables/models/use-containers";
import { selectedCustomPage } from "@/composables/models/use-customPages";
import { Container, CustomPage } from "@/types/customPage";
import { Asset } from "@/types/asset";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
	name: "StylesEditor",
	props: {
		campaignID: {
			type: String,
			required: true
		}
	},
	components: { AssetManagerComponent },
	emits: ["update"],
	setup(props, { emit }) {
		//variables
		let container = selectedContainer();
		let selectedPage = selectedCustomPage();
		let containerRef = ref<Container | null>();
		let selectedPageRef = ref<CustomPage | null>();
		const selectedAssetField = ref<string>("");

		const showModal = ref(false);

		onMounted(() => {
			containerRef.value = container.value;
			selectedPageRef.value = selectedPage.value;
		});

		watch(container, () => {
			containerRef.value = container.value;
		});

		//dispatchers

		//getters

		//functions
		function setAsset(asset: Asset) {
			containerRef.value!.styles.backgroundImageAsset = asset;
			containerRef.value!.styles.backgroundImage = `url('${asset.url}')`;
			showModal.value = false;
			updatePage();
		}
		function chooseBackgroundImage(field: string) {
			showModal.value = true;
		}
		function removeImage() {
			containerRef.value!.styles.backgroundImageAsset = undefined;
			containerRef.value!.styles.backgroundImage = undefined;
			updatePage();
		}

		function updatePage() {
			console.log(selectedPageRef.value);
			emit("update");
		}

		return {
			containerRef,
			showModal,
			setAsset,
			chooseBackgroundImage,
			removeImage,
			updatePage
		};
	}
});
