import { managerStore } from "@/store/managerStore";
import { Manager } from "@/types/manager";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import {
	ChallengeRequest,
	ConfirmForgotPasswordRequest,
	ForgotPasswordRequest,
	LoginRequest
} from "@/types/requests";

export function allManagers(): ComputedRef<Manager[]> {
	const items = managerStore.getItems;
	return items;
}
export function selectedManager(
	itemID: string
): ComputedRef<Manager | undefined> {
	managerStore.commitSelectedItemID(itemID);
	const item = managerStore.getSelectedItem;
	return item;
}

export async function getManagers(): Promise<boolean> {
	return managerStore.dispatchGetItems();
}

export async function createManager(item: Manager): Promise<Response> {
	return await managerStore.dispatchCreateItem(item);
}

export async function updateManager(item: Manager): Promise<Response> {
	return await managerStore.dispatchUpdateItem(item);
}

export async function deleteManager(id: string): Promise<Response> {
	return await managerStore.dispatchDeleteItem(id);
}

export async function loginManager(item: LoginRequest): Promise<Response> {
	return await managerStore.managerLogin(item);
}

export async function forgotPasswordManager(
	item: ForgotPasswordRequest
): Promise<boolean> {
	return await managerStore.forgotPassword(item);
}

export async function confirmPasswordManager(
	item: ConfirmForgotPasswordRequest
): Promise<boolean> {
	return await managerStore.confirmForgotPassword(item);
}

export async function respondToChallenge(
	item: ChallengeRequest
): Promise<Response> {
	return await managerStore.respondToChallenge(item);
}

export function blankManager(): Manager {
	const manager: Manager = {
		name: "",
		email: ""
	};
	return manager;
	"";
}

export function blankLogin(): LoginRequest {
	const login: LoginRequest = {
		email: "",
		password: ""
	};
	return login;
}

export function validationRules() {
	const rules = {
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 3,
				max: 50,
				message: "Length should be 3 to 50",
				trigger: "blur"
			}
		],
		email: [
			{ required: true, message: "Email is required", trigger: "onBlur" }
		]
	};
	return rules;
}
