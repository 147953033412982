<template>
	<router-view />
</template>

<style lang="scss">
html {
	height: 100%;
}

body {
	background-color: #fff;
	height: 100%;
	#app {
		height: 100%;
	}
	.layout {
		height: 100%;
	}
}
.content-main {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
	height: 100%;
}
.panel {
	border-radius: $radius-medium;
	background-color: $white;
	padding: $medium;
	overflow: hidden;
}
.ant-col {
	height: 100%;
}
.custom-input-style {
	margin-bottom: 10px !important;
}

.small-nav {
	.small-nav-btn {
		width: 24px;
		text-align: center !important;
		padding: 0 3px;
		margin-bottom: 3px;
	}
}
.ant-upload {
	margin-bottom: 10px !important;
}
</style>
