import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", {
    style: _normalizeStyle(_ctx.elementRef.styles),
    controls: false
  }, [
    _createElementVNode("source", {
      src: _ctx.elementRef.attributes.src,
      type: _ctx.elementRef.attributes.type
    }, null, 8, _hoisted_1)
  ], 4))
}