import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06e6124e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_HeaderTextComponent = _resolveComponent("HeaderTextComponent")!
  const _component_ParagraphComponent = _resolveComponent("ParagraphComponent")!
  const _component_InputTextComponent = _resolveComponent("InputTextComponent")!
  const _component_InputTextAreaComponent = _resolveComponent("InputTextAreaComponent")!
  const _component_ScrollerComponent = _resolveComponent("ScrollerComponent")!
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_CheckboxComponent = _resolveComponent("CheckboxComponent")!
  const _component_ARComponent = _resolveComponent("ARComponent")!
  const _component_MapComponent = _resolveComponent("MapComponent")!
  const _component_VideoComponent = _resolveComponent("VideoComponent")!
  const _component_IFrameComponent = _resolveComponent("IFrameComponent")!
  const _component_Container = _resolveComponent("Container", true)!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doAction(_ctx.containerRef.action))),
    style: _normalizeStyle(_ctx.containerRef.styles),
    class: _normalizeClass({ container: true, editable: _ctx.editable })
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["select-container", { active: _ctx.containerRef.active }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectContainer()))
        }, null, 2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.containerRef.elements, (element, index) => {
      return (_openBlock(), _createElementBlock("div", {
        style: _normalizeStyle(
				`z-index:${index};height:${
					element.type == 'Map' ||
					element.type == 'Scroller' ||
					element.type == 'IFrame'
						? '100%'
						: 'auto'
				}`
			),
        onClick: ($event: any) => (_ctx.onSelectElement(element)),
        key: element._id + index + _ctx.selectedpage?.name
      }, [
        (element.type == 'Button')
          ? (_openBlock(), _createBlock(_component_ButtonComponent, {
              key: 0,
              element: element,
              editable: _ctx.editable,
              onUpdate: ($event: any) => (_ctx.onUpdateElement(element, index))
            }, null, 8, ["element", "editable", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Header')
          ? (_openBlock(), _createBlock(_component_HeaderTextComponent, {
              key: 1,
              element: element,
              editable: _ctx.editable,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "editable", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Paragraph')
          ? (_openBlock(), _createBlock(_component_ParagraphComponent, {
              key: 2,
              element: element,
              editable: _ctx.editable,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "editable", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Input')
          ? (_openBlock(), _createBlock(_component_InputTextComponent, {
              key: 3,
              element: element,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'TextArea')
          ? (_openBlock(), _createBlock(_component_InputTextAreaComponent, {
              key: 4,
              element: element,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Scroller')
          ? (_openBlock(), _createBlock(_component_ScrollerComponent, {
              key: 5,
              element: element,
              editable: false,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Icon')
          ? (_openBlock(), _createBlock(_component_IconComponent, {
              key: 6,
              element: element,
              editable: false,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Checkbox')
          ? (_openBlock(), _createBlock(_component_CheckboxComponent, {
              key: 7,
              element: element,
              editable: false,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'AR')
          ? (_openBlock(), _createBlock(_component_ARComponent, {
              key: 8,
              element: element,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Map')
          ? (_openBlock(), _createBlock(_component_MapComponent, {
              key: 9,
              element: element,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'Video')
          ? (_openBlock(), _createBlock(_component_VideoComponent, {
              key: 10,
              element: element,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true),
        (element.type == 'IFrame')
          ? (_openBlock(), _createBlock(_component_IFrameComponent, {
              key: 11,
              element: element,
              onUpdate: ($event: any) => (_ctx.onUpdateElement($event, index))
            }, null, 8, ["element", "onUpdate"]))
          : _createCommentVNode("", true)
      ], 12, _hoisted_1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.containerRef.containers, (container, index) => {
      return (_openBlock(), _createBlock(_component_Container, {
        editable: _ctx.editable,
        key: container._id + index,
        container: container
      }, null, 8, ["editable", "container"]))
    }), 128))
  ], 6))
}