import { Campaign, DuplicateCampaignDTO } from "@/types/campaign";
import { Response } from "@/utils/response";
import ApiProtected from "@/utils/api-protected";
import { Stats } from "@/types/Stats";
const api = ApiProtected.getInstance();

export const campaignStore = {
	namespaced: true,
	state: {
		items: [] as Campaign[],
		selectedItemID: ""
	},
	mutations: {
		saveItems(state: any, items: Campaign[]) {
			state.items = items;
		},
		commitSaveItem(state: any, item: Campaign) {
			const index = state.items.findIndex(
				(e: Campaign) => e.id == item.id
			);
			if (index == -1) {
				state.items.push(item);
			} else {
				state.items[index] = item;
			}
			console.log(state.items);
		},

		commitDeleteItem(state: any, itemID: string) {
			const index = state.items.findIndex(
				(e: Campaign) => e.id == itemID
			);
			if (index != -1) {
				state.items.splice(index, 1);
			}
		},

		commitSelectedItemID(state: any, itemID: string) {
			state.selectedItemID = itemID;
		}
	},
	getters: {
		getItems(state: any): Campaign[] {
			return state.items;
		},
		getSelectedItem(state: any): Campaign | undefined {
			return state.items.find(
				(e: Campaign) => e.id == state.selectedItemID
			);
		},
		getSelectedItemID(state: any): string | undefined {
			return state.selectedItemID;
		}
	},
	actions: {
		async dispatchGetItems({ commit }: any): Promise<boolean> {
			const response: Response = await api.getCampaigns();
			if (response.success === true) {
				const items: Campaign[] = response.data;
				commit("saveItems", items);
				return true;
			} else {
				return false;
			}
		},

		async dispatchGetItemByID(
			{ commit }: any,
			itemID: string
		): Promise<Response> {
			const response: Response = await api.getCampaignByID(itemID);
			if (response.success === true) {
				const item: Campaign = response.data;
				commit("commitSaveItem", item);
				commit("commitSelectedItemID", itemID);
			}
			return response;
		},

		async dispatchGetCampaignStats(
			{ commit }: any,
			itemID: string
		): Promise<Stats> {
			const response: Response = await api.getCampaignStats(itemID);
			return response.data;
		},

		async dispatchCreateItem(
			{ commit }: any,
			item: Campaign
		): Promise<Response> {
			const itemCreated = await api.createCampaign(item);
			console.log(itemCreated);
			if (itemCreated.success === true)
				commit("commitSaveItem", itemCreated.data);
			return itemCreated;
		},

		async dispatchUpdateItem(
			{ commit }: any,
			item: Campaign
		): Promise<Response> {
			const itemUpdated = await api.updateCampaign(item);
			console.log(itemUpdated);
			if (itemUpdated.success === true)
				commit("commitSaveItem", itemUpdated.data);
			return itemUpdated;
		},

		async dispatchDeleteItem(
			{ commit }: any,
			itemID: string
		): Promise<Response> {
			const itemDeleted = await api.deleteCampaign(itemID);
			if (itemDeleted.success === true)
				commit("commitDeleteItem", itemID);
			return itemDeleted;
		},

		async dispatchDuplicateItem(
			{ commit }: any,
			item: DuplicateCampaignDTO
		): Promise<Response> {
			const duplicatedItem = await api.duplicateCampaign(item);
			if (duplicatedItem.success === true)
				commit("commitSaveItem", duplicatedItem.data);
			return duplicatedItem;
		}
	}
};
