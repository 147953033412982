
import { defineComponent, ref, computed, Ref } from "vue";
import { deleteAsset } from "@/composables/models/use-assets";
import { Asset } from "@/types/asset";

export default defineComponent({
	props: {
		asset: {
			type: Object,
			required: true
		},
		campaignID: {
			type: String,
			required: true
		}
	},
	emits: ["delete", "update", "use"],
	setup(props, { emit }) {
		//variables
		let assetRef = ref(props.asset);
		const showModal = ref(false);
		let showDeleteModal = ref(false);
		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			showModal.value = false;
		};
		const onCancelDelete = () => {
			showDeleteModal.value = false;
		};
		const onDelete = () => {
			showDeleteModal.value = true;
		};
		const handleDelete = (id: string) => {
			showDeleteModal.value = false;
			deleteAsset({ id: id, campaignID: props.campaignID });
			emit("delete");
		};
		const changesSaved = () => {
			showModal.value = false;
			emit("update");
		};
		const updateAsset = (asset: any) => {
			//console.log(asset);
			emit("update", asset);
		};
		function useAsset(asset: any) {
			console.log(asset);
			emit("use", asset);
		}

		return {
			assetRef,
			onCancel,
			showModal,
			onDelete,
			showDeleteModal,
			handleDelete,
			onCancelDelete,
			changesSaved,
			updateAsset,
			useAsset
		};
	},
	components: {}
});
