
import { defineComponent, ref } from "vue";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";
import { blankCampaign } from "../../composables/models/use-campaigns";
import moment, { Moment } from "moment";
import { Campaign } from "@/types/campaign";
import { message } from "ant-design-vue";
import { Response } from "@/utils/response";
import { Asset } from "@/types/asset";
import { CampaignObjectRequest } from "@/types/requests";
import { createCustomPage } from "@/composables/models/use-customPages";
import { pages } from "@/templates/customPages";
import { customComponentTypes } from "@/templates/customComponents";
import { createCustomComponent } from "@/composables/models/use-customComponents";
import { CustomComponent } from "@/types/customComponent";
import { CustomPage } from "@/types/customPage";
import store from "@/store";

export default defineComponent({
	components: { AssetManagerComponent },
	props: {
		campaign: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	data() {
		return {
			form: ref(),
			loading: false,
			showAssetManager: false,
			campaignData: {} as Campaign
		};
	},
	mounted() {
		console.log("mounted");
		this.campaignData = JSON.parse(JSON.stringify(this.campaign));
	},
	beforeUpdate() {
		console.log("before update");
		this.campaignData = JSON.parse(JSON.stringify(this.campaignData));
	},
	unmounted() {
		console.log("unmounted");
	},

	methods: {
		onCancel() {
			if (this.method === "Create") {
				this.campaignData = blankCampaign();
				this.$emit("saved");
			} else {
				this.$emit("saved");
			}
		},
		onStartChange(date: Moment) {
			this.campaignData.startDate = `${moment(date).format(
				"YYYY-MM-DD"
			)}`;
		},
		onEndChange(date: Moment) {
			this.campaignData.endDate = `${moment(date).format("YYYY-MM-DD")}`;
		},
		setAsset(asset: Asset) {
			this.campaignData.logo = asset.url;
			this.showAssetManager = false;
		},
		createCustomPageTemplates(campaign: Campaign) {
			for (let i = 0; i < pages.length; i++) {
				const page = pages[i];

				const campaignObj: CampaignObjectRequest = {
					campaignID: campaign.id!,
					data: page as CustomPage
				};

				createCustomPage(campaignObj).then((response: Response) => {
					// console.log(response);
				});
			}
		},
		createCustomComponents(campaign: Campaign) {
			const keys: string[] = Object.keys(customComponentTypes);
			for (let i = 0; i < keys.length; i++) {
				const key: string = keys[i];
				const customComponent = customComponentTypes[key];
				const popup: CustomComponent = {
					type: "popup",
					name: key,
					isSystem: true,
					container: customComponent
				};
				const campaignObj: CampaignObjectRequest = {
					campaignID: campaign.id!,
					data: popup
				};
				createCustomComponent(campaignObj).then(
					(response: Response) => {
						// console.log(response);
					}
				);
			}
		},
		async onSubmit() {
			this.loading = true;
			if (this.method === "Create") {
				const response = await store.dispatch(
					"campaignStore/dispatchCreateItem",
					this.campaignData
				);
				if (response.success) {
					this.createCustomPageTemplates(response.data);
					this.createCustomComponents(response.data);
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				this.$emit("saved");
				this.loading = false;
				this.campaignData = blankCampaign();
			} else if (this.method == "Update") {
				const response = await store.dispatch(
					"campaignStore/dispatchUpdateItem",
					this.campaignData
				);
				if (response.success) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				this.$emit("saved");
				this.loading = false;
			}
		}
	},
	computed: {
		startDate() {
			return moment(this.campaignData.startDate);
		},
		endDate() {
			return moment(this.campaignData.endDate);
		}
	}
});
