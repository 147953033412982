
import { defineComponent, ref } from "vue";

export default defineComponent({
	components: {},
	props: {},
	setup() {
		//variables
		const scroller = ref<HTMLElement>();
		let showTop = ref<boolean>(false);
		let showBottom = ref<boolean>(false);
		//dispatchers

		//getters

		//functions
		const checkScroll = () => {
			if (scroller.value && scroller.value?.scrollHeight > 0) {
				if (scroller.value?.scrollTop == 0) {
					showTop.value = false;
				} else {
					showTop.value = true;
				}
				if (
					scroller.value?.scrollTop !=
					scroller.value?.scrollHeight - scroller.value?.clientHeight
				) {
					showBottom.value = true;
				} else {
					showBottom.value = false;
				}
			}
		};
		return { scroller, checkScroll, showTop, showBottom };
	},
});
