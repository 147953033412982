
import passwordValidator from "password-validator";
import { Response } from "@/utils/response";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { ChallengeRequest, LoginRequest } from "@/types/requests";
import { respondToChallenge } from "@/composables/models/use-managers";

export default defineComponent({
	props: {
		challenge: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		//variables
		const router = useRouter();
		let challengeData = ref(props.challenge);
		let password = ref("");
		let confirmPassword = ref("");
		const passwordSchema = new passwordValidator();

		passwordSchema
			.is()
			.min(8)
			.is()
			.max(100)
			.has()
			.uppercase() // Must have uppercase letters
			.has()
			.lowercase() // Must have lowercase letters
			.has()
			.digits(1) // Must have at least 2 digits
			.has()
			.not()
			.spaces();

		//dispatchers

		//getters

		//functions
		const login = () => {
			console.log(passwordSchema.validate(password.value));
			if (passwordSchema.validate(password.value)) {
				challengeData.value.password = password.value;
				respondToChallenge(
					challengeData.value as ChallengeRequest
				).then((response: Response) => {
					console.log(response);
					router.push("/console/campaigns");
				});
			} else {
				message.error(
					"Password must be 8 characters or longer. It must contain 1 uppercase and lowercase character and a number."
				);
			}
		};
		const cancel = () => {
			console.log();
		};

		return { challengeData, login, cancel, password, confirmPassword };
	}
});
