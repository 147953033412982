import axios, { AxiosInstance } from "axios";
import { Container } from "../types/customPage";
import { Brand } from "../types/brand";
import { Response } from "./response";

declare module "axios" {
	interface AxiosResponse
		extends Promise<Container & Container[] & Brand & Brand[] & Response> {}
}

export default abstract class HttpClient {
	protected instance: AxiosInstance;

	public constructor(baseURL: string) {
		this.instance = axios.create({
			baseURL
		});
	}
}
