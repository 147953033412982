import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import Console from "../views/Console.vue";
import Campaigns from "../views/Campaigns.vue";
import CampaignManager from "../views/CampaignManager.vue";
import Artwork from "../views/Artwork.vue";
import CustomComponent from "../views/CustomComponent.vue";
import Merchants from "../views/Merchants.vue";
import Assets from "../views/Assets.vue";
import AREditor from "../views/AREditor.vue";
import Prizes from "../views/Prizes.vue";
import VATs from "../views/VATs.vue";
import Manager from "../views/Manager.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Login",
		component: Login
	},
	{
		path: "/console",
		name: "Console",
		component: Console,
		children: [
			{
				path: "/console/campaigns",
				name: "Campaigns",
				component: Campaigns
			},
			{
				path: "/console/:campaignID/campaign-manager",
				name: "Campaign Manager",
				component: CampaignManager
			},
			{
				path: "/console/:campaignID/artwork",
				name: "ArtworkRoot",
				component: Artwork
			},
			{
				path: "/console/:campaignID/artwork/:custompage",
				name: "Artwork",
				component: Artwork,
				props: true
			},
			{
				path: "/console/:campaignID/customcomponent",
				name: "CustomComponent",
				component: CustomComponent
			},
			{
				path: "/console/:campaignID/prizes",
				name: "Prizes",
				component: Prizes
			},
			{
				path: "/console/:campaignID/vats",
				name: "VATs",
				component: VATs
			},
			{
				path: "/console/manager",
				name: "Manager",
				component: Manager
			},
			{
				path: "/console/:campaignID/merchants",
				name: "Merchants",
				component: Merchants,
				props: true
			},
			{
				path: "/console/:campaignID/ar-editor",
				name: "AREditor",
				component: AREditor
			},
			{
				path: "/console/:campaignID/assets",
				name: "Assets",
				component: Assets,
				props: true
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
