
import {
	defineComponent,
	ref,
	watch,
	watchEffect,
	Ref,
	onBeforeMount,
	ComputedRef,
	computed
} from "vue";
import PoolPrizeSplit from "@/components/pool/PoolPrizeSplit.vue";
import { Prize } from "@/types/prize";
import { allPrizes, getPrizes } from "@/composables/models/use-prizes";
import { CategorySplit, PrizeSplit } from "@/types/dropPool";
import { useRoute } from "vue-router";

export default defineComponent({
	props: {
		categorySplit: {
			type: Object,
			required: true
		}
	},
	components: {
		PoolPrizeSplit
	},
	emits: ["changed"],
	setup(props, { emit }) {
		let categorySplitRef = ref(props.categorySplit);
		let prizesList = ref<Prize[]>([]);
		const route = useRoute();

		watchEffect(() => {
			categorySplitRef.value = props.categorySplit;
		});
		//dispatchers
		onBeforeMount(async () => {
			await getPrizes({ campaignID: route.params.campaignID.toString() });
			prizesList.value = allPrizes().value;
		});

		const changed = () => {
			console.log(categorySplitRef.value);
			emit("changed", categorySplitRef.value);
		};
		const prizeSplitRef: ComputedRef<string[][]> = computed(() => {
			const catArr = [];

			for (let i = 0; i < categorySplitRef.value.length; i++) {
				const category = categorySplitRef.value[i];
				const arr: string[] = [];
				for (let j = 0; j < category.prizeSplit.length; j++) {
					arr.push(
						category.prizeSplit[j].prizeID +
							"_" +
							category.prizeSplit[j].name +
							"_" +
							route.params.campaignID.toString()
					);
				}
				catArr.push(arr);
			}

			return catArr;
		});

		const handlePrizeChange = (
			selectedItems: any,
			category: CategorySplit
		) => {
			let selectedPrizes: string[] = [];
			for (let i = 0; i < selectedItems.length; i++) {
				const prizeID = selectedItems[i].split("_")[0];
				selectedPrizes.push(prizeID);

				const index = category.prizeSplit.findIndex(
					(e: PrizeSplit) => e.prizeID == prizeID
				);
				if (index < 0) {
					const prizeSplit: PrizeSplit = {
						prizeID: selectedItems[i].split("_")[0],
						name: selectedItems[i].split("_")[1]
					};
					category.prizeSplit.push(prizeSplit);
				}
			}
			//Remove if not there
			const filteredPrizeSplits = category.prizeSplit.filter(
				(e: PrizeSplit) => {
					return selectedPrizes.includes(e.prizeID);
				}
			);
			category.prizeSplit = filteredPrizeSplits;
		};

		const savePrizeSplit = (prizeSplitRef: PrizeSplit[]) => {
			for (let i = 0; i < prizeSplitRef.length; i++) {
				categorySplitRef.value.prizeSplit[i].number =
					prizeSplitRef[i].number;
			}
		};

		return {
			categorySplitRef,
			changed,
			prizesList,
			savePrizeSplit,
			handlePrizeChange,
			prizeSplitRef
		};
	}
});
