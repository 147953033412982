import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"position":"relative","height":"100%"} }
const _hoisted_2 = {
  key: 0,
  class: "showTop"
}
const _hoisted_3 = {
  key: 1,
  class: "showBottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showTop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "scroller",
      onMousewheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkScroll && _ctx.checkScroll(...args))),
      class: "scroller"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 544),
    (_ctx.showBottom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}