import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c69d84b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "small-nav position-small-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "Pool settings",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoPage('Campaign Manager'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "cog" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "Page Artwork",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotoPage('ArtworkRoot'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "palette" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "Custom Components",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.gotoPage('CustomComponent'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "boxes" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "VATs",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.gotoPage('VATs'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "gem" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "Prizes",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.gotoPage('Prizes'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "gift" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "Merchants",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.gotoPage('Merchants'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "wallet" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "Assets Manager",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.gotoPage('Assets'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "image" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_tooltip, {
        title: "AR Editor",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.gotoPage('AREditor'))),
            size: "small",
            class: "small-nav-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "vr-cardboard" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}