export const pages = [
	//Register
	{
		layout: {
			elements: [],
			active: false,
			action: { type: "none", value: "" },
			styles: {
				backgroundColor: "#081f28ff",
				alignItems: "center",
				flexWrap: "no-wrap",
				flexDirection: "column",
				display: "flex",
				width: "100%",
				justifyContent: "center",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						flexWrap: "no-wrap",
						backgroundImageAsset: {
							createdAt: "2021-11-26T08:54:28.133Z",
							campaignID: "undefined",
							name: "logo",
							id: "7678c550-4e96-11ec-95a2-5fae02a3ac6b",
							type: "image/png",
							category: "Category 1",
							url:
								"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png",
							key: "vlogo-type.png",
							updatedAt: "2021-11-26T08:54:28.133Z"
						},
						flexDirection: "column",
						backgroundImage:
							"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png')",
						display: "flex",
						width: "200px",
						flexOrder: "3",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						height: "100px"
					},
					attributes: {},
					containers: [],
					_id: "cb6beab2-9339-4388-8425-f9f32d420f64",
					type: "Container"
				},
				{
					elements: [
						{
							elements: [],
							name: "Input Text",
							active: false,
							action: { type: "", value: "" },
							styles: {
								width: "200px",
								padding: "0 0 0 10px",
								fontSize: "12px",
								borderRadius: "5px",
								borderStyle: "none",
								height: "35px"
							},
							attributes: {
								type: "text",
								id: "email",
								placeholder: "Email Address"
							},
							containers: [],
							_id: "6dc5ab62-7b94-440b-aefe-b47845acfd8a",
							text: "",
							type: "Input"
						},
						{
							elements: [],
							name: "Button",
							active: false,
							action: { type: "signup", value: "signup" },
							styles: {
								backgroundColor: "#3d9268ff",
								margin: "10px 0 0 0",
								borderRadius: "3px",
								color: "#ffffffff",
								borderWidth: "0px",
								width: "200px",
								borderStyle: "none",
								height: "35px"
							},
							attributes: { text: "Click me!", id: "sign-up" },
							containers: [],
							_id: "026e3c21-9046-445c-99ee-155a9c4f6f1a",
							text: "SIGNUP",
							type: "Button"
						},
						{
							elements: [],
							name: "Paragraph",
							active: false,
							action: { type: "", value: "" },
							styles: {
								padding: "20px",
								fontSize: "8PX",
								alignSelf: "auto",
								color: "#ffffffff"
							},
							attributes: { text: "Paragraph" },
							containers: [],
							_id: "26e7fe19-c17e-4efd-a37e-54b8de1e822f",
							text: "© Copyright Virtuoso 2021",
							type: "Paragraph"
						}
					],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						alignItems: "center",
						flexWrap: "no-wrap",
						flexDirection: "column",
						display: "flex",
						width: "100%",
						justifyContent: "center",
						height: "200px"
					},
					attributes: {},
					containers: [],
					_id: "b2b5f91d-e0a9-4b94-bc88-1bd5db25c3a7",
					type: "Container"
				}
			],
			_id: "09602b7a-d0c8-4fd8-8a88-b12897a72acc",
			type: "Container"
		},
		name: "Registration Page",
		url: "registration-page"
	},
	//Sign-in
	{
		name: "Signin Page",
		url: "sign-in",
		layout: {
			elements: [
				{
					elements: [],
					name: "Header",
					active: false,
					action: { type: "", value: "" },
					styles: {
						color: "#ffffffff",
						top: "0",
						left: "0",
						width: "10px",
						fontSize: "23px",
						position: "absolute",
						opacity: "0"
					},
					attributes: { id: "site-title" },
					containers: [],
					_id: "28800f3c-b091-4e85-baf9-e352f0719be0",
					text: "Cool",
					type: "Header"
				}
			],
			active: false,
			action: { type: "none", value: "" },
			styles: {
				backgroundColor: "#081f28ff",
				alignItems: "center",
				flexWrap: "no-wrap",
				flexDirection: "column",
				display: "flex",
				rowGap: "20px",
				width: "100%",
				justifyContent: "center",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						flexWrap: "no-wrap",
						backgroundImageAsset: {
							createdAt: "2021-11-26T08:54:28.133Z",
							campaignID: "undefined",
							name: "logo",
							id: "7678c550-4e96-11ec-95a2-5fae02a3ac6b",
							type: "image/png",
							category: "Category 1",
							url:
								"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png",
							updatedAt: "2021-11-26T08:54:28.133Z"
						},
						flexDirection: "column",
						backgroundImage:
							"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png')",
						display: "flex",
						width: "200px",
						flexOrder: "3",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						height: "100px"
					},
					attributes: {},
					containers: [],
					_id: "cb6beab2-9339-4388-8425-f9f32d420f64",
					type: "Container"
				},
				{
					elements: [
						{
							elements: [],
							name: "Input Text",
							active: false,
							action: { type: "", value: "" },
							styles: {
								width: "200px",
								padding: "0 0 0 10px",
								fontSize: "12px",
								borderRadius: "5px",
								borderStyle: "none",
								height: "35px"
							},
							attributes: {
								type: "text",
								id: "email",
								placeholder: "Email Address"
							},
							containers: [],
							_id: "6dc5ab62-7b94-440b-aefe-b47845acfd8a",
							text: "",
							type: "Input"
						},
						{
							elements: [],
							name: "Button",
							active: false,
							action: { type: "signin-request-code", value: "signin-request-code" },
							styles: {
								backgroundColor: "#05a045ff",
								borderRadius: "20px",
								color: "#ffffffff",
								borderWidth: "",
								width: "200px",
								borderStyle: "none",
								height: "35px"
							},
							attributes: { id: "signin-btn" },
							containers: [],
							_id: "4c666759-4add-470c-ae9b-bccd4a7259e0",
							text: "Submit",
							type: "Button"
						}
					],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						alignItems: "center",
						flexWrap: "no-wrap",
						flexDirection: "column",
						display: "flex",
						rowGap: "10px",
						width: "100%",
						justifyContent: "center",
						height: "auto"
					},
					attributes: {},
					containers: [],
					_id: "b2b5f91d-e0a9-4b94-bc88-1bd5db25c3a7",
					type: "Container"
				}
			],
			_id: "09602b7a-d0c8-4fd8-8a88-b12897a72acc",
			type: "Container"
		}
	},
	//Enter Pin
	{
		layout: {
			elements: [],
			active: false,
			action: { type: "none", value: "" },
			styles: {
				backgroundColor: "#081f28ff",
				alignItems: "center",
				flexWrap: "no-wrap",
				flexDirection: "column",
				display: "flex",
				width: "100%",
				justifyContent: "center",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						flexWrap: "no-wrap",
						backgroundImageAsset: {
							createdAt: "2021-11-26T08:54:28.133Z",
							campaignID: "undefined",
							name: "logo",
							id: "7678c550-4e96-11ec-95a2-5fae02a3ac6b",
							type: "image/png",
							category: "Category 1",
							url:
								"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png",
							key: "vlogo-type.png",
							updatedAt: "2021-11-26T08:54:28.133Z"
						},
						flexDirection: "column",
						backgroundImage:
							"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png')",
						display: "flex",
						width: "200px",
						flexOrder: "3",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						height: "100px"
					},
					attributes: {},
					containers: [],
					_id: "cb6beab2-9339-4388-8425-f9f32d420f64",
					type: "Container"
				},
				{
					elements: [
						{
							elements: [],
							name: "Paragraph",
							active: false,
							action: { type: "", value: "" },
							styles: {
								color: "#ffffffff",
								fontSize: "12px",
								backgroundColor: ""
							},
							attributes: { text: "Paragraph" },
							containers: [],
							_id: "235c8dc1-62f1-40fe-91d8-a5ba3ef6fe1a",
							text: "Please check your email for your login pin",
							type: "Paragraph"
						},
						{
							elements: [],
							name: "Input Text",
							active: false,
							action: { type: "", value: "" },
							styles: {
								padding: "0 0 0 10px",
								backgroundColor: "",
								margin: "10px",
								borderRadius: "5px",
								width: "200px",
								fontSize: "12px",
								borderStyle: "none",
								height: "35px"
							},
							attributes: {
								type: "text",
								id: "pin",
								placeholder: "Enter Pin"
							},
							containers: [],
							_id: "057ae5a8-46ac-4b83-9e3d-d018f764d74b",
							text: "",
							type: "Input"
						},
						{
							elements: [],
							name: "Button",
							active: false,
							action: { type: "submit-pin", value: "submit-pin" },
							styles: {
								backgroundColor: "#3d9268ff",
								borderRadius: "3px",
								color: "#ffffffff",
								borderWidth: "1px",
								width: "200px",
								borderStyle: "none",
								height: "35px"
							},
							attributes: { id: "submit-pin", text: "Click me!" },
							containers: [],
							_id: "d8b158f1-dd84-4924-aa9f-2bd54998ce0a",
							text: "SUBMIT",
							type: "Button"
						},
						{
							elements: [],
							name: "Paragraph",
							active: true,
							action: { type: "resend-pin", value: "resend-pin" },
							styles: {
								fontSize: "14px",
								margin: "20px",
								color: "#ffffffff",
								fontWeight: ""
							},
							attributes: { text: "Paragraph", id: "resend-pin" },
							containers: [],
							_id: "3a2a6b5b-63c8-4383-a14d-293a2833f243",
							text: "Resend pin",
							type: "Paragraph"
						},
						{
							_id: "b724d919-aa0a-47dc-b418-ee1c40304fa9",
							name: "Paragraph",
							active: false,
							type: "Paragraph",
							text: "© Copyright Virtuoso 2021",
							action: { type: "", value: "" },
							styles: { color: "#ffffffff", fontSize: "8px" },
							attributes: { text: "Paragraph" },
							containers: [],
							elements: []
						}
					],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						alignItems: "center",
						flexWrap: "no-wrap",
						flexDirection: "column",
						display: "flex",
						width: "100%",
						justifyContent: "center",
						height: "250px"
					},
					attributes: {},
					containers: [],
					_id: "b2b5f91d-e0a9-4b94-bc88-1bd5db25c3a7",
					type: "Container"
				}
			],
			_id: "09602b7a-d0c8-4fd8-8a88-b12897a72acc",
			type: "Container"
		},
		name: "Enter Pin",
		url: "enter-pin"
	},
	//Wallet
	{
		layout: {
			elements: [],
			active: false,
			action: { type: "none", value: "" },
			styles: {
				width: "100%",
				flexWrap: "no-wrap",
				flexDirection: "column",
				display: "flex",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						width: "100%",
						backgroundColor: "#081f28ff",
						flexWrap: "no-wrap",
						flexDirection: "row",
						display: "flex",
						height: "80px"
					},
					attributes: {},
					containers: [
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								width: "100%",
								flexWrap: "no-wrap",
								flexDirection: "column",
								justifyContent: "center",
								display: "flex",
								height: "100%"
							},
							attributes: {},
							containers: [],
							_id: "f00a9f57-c2fe-42ba-80b6-0a9755c1d7a0",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								padding: "",
								margin: "",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "100%",
								justifyContent: "center",
								height: "100%"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T09:25:44.651Z",
											campaignID: "undefined",
											name: "logo2",
											id:
												"d4f6b1b0-4e9a-11ec-95a2-5fae02a3ac6b",
											type: "image/png",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo.png",
											key: "vlogo.png",
											updatedAt:
												"2021-11-26T09:25:44.651Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo.png')",
										display: "flex",
										width: "100%",
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "60%"
									},
									attributes: {},
									containers: [],
									_id: "78699144-868f-4793-a9de-83e270df9112",
									type: "Container"
								}
							],
							_id: "bcf433f1-ffe5-4a85-8f2c-ad4f6c5639ff",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								alignItems: "flex-end",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "100%",
								justifyContent: "center",
								height: "100%"
							},
							attributes: {},
							containers: [],
							_id: "2f6a9c47-05e0-46ee-a0e6-edcfd96eb25e",
							type: "Container"
						}
					],
					_id: "2ffa5199-c6ad-462e-8ae3-3cc97b15d1e3",
					type: "Container"
				},
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						width: "100%",
						flexWrap: "no-wrap",
						flexDirection: "row",
						display: "flex",
						height: "60px"
					},
					attributes: {},
					containers: [
						{
							elements: [],
							active: false,
							action: { type: "", value: "" },
							styles: {
								backgroundColor: "#70f99eff",
								alignItems: "center",
								flexDirection: "column",
								backgroundImage: "url('')",
								display: "flex",
								justifyContent: "center",
								flexWrap: "no-wrap",
								backgroundImageAsset: {
									createdAt: "2021-11-26T09:41:40.295Z",
									campaignID:
										"28f1a6c0-4e92-11ec-95a2-5fae02a3ac6b",
									name: "wallet",
									id: "0e926570-4e9d-11ec-95a2-5fae02a3ac6b",
									type: "image/svg+xml",
									category: "Category 1",
									url: "",
									key: "",
									updatedAt: "2021-11-26T09:41:40.295Z"
								},
								width: "100%",
								backgroundSize: "",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								height: "100%"
							},
							attributes: { id: "" },
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T09:51:00.894Z",
											campaignID: "undefined",
											name: "money",
											id:
												"5cb703e0-4e9e-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/money-bill-wave-solid.svg",
											key: "money-bill-wave-solid.svg",
											updatedAt:
												"2021-11-26T09:51:00.894Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/money-bill-wave-solid.svg')",
										display: "flex",
										width: "50%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "60%"
									},
									attributes: {},
									containers: [],
									_id: "2b30bee3-1f63-490f-853b-46f663c750d9",
									type: "Container"
								}
							],
							_id: "cd08451f-705c-4a75-9d7c-19637df39a25",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "go-to-page", value: "map" },
							styles: {
								backgroundColor: "#3d9268ff",
								alignItems: "center",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "100%",
								justifyContent: "center",
								height: "100%"
							},
							attributes: { id: "" },
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										alignItems: "center",
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T09:48:28.776Z",
											campaignID: "undefined",
											name: "map",
											id:
												"020b7f70-4e9e-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/map-marked-alt-solid.svg",
											key: "map-marked-alt-solid.svg",
											updatedAt:
												"2021-11-26T09:48:28.776Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/map-marked-alt-solid.svg')",
										display: "flex",
										width: "50%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										justifyContent: "center",
										height: "60%"
									},
									attributes: {},
									containers: [],
									_id: "997aa5d9-ac68-4bca-bb1c-10447662d25f",
									type: "Container"
								}
							],
							_id: "8c30b9da-42bf-4f51-b61f-9339b5714923",
							type: "Container"
						}
					],
					_id: "d38fdb57-53a9-4165-aa0d-3d7b2e08fb92",
					type: "Container"
				},
				{
					elements: [
						{
							elements: [],
							name: "Scroller",
							active: false,
							action: { type: "", value: "" },
							styles: {
								borderStyle: "solid",
								borderRadius: "20px",
								borderWidth: "1px"
							},
							attributes: { id: "", text: "Click me!" },
							containers: [],
							_id: "c558a3bf-ef92-4230-b19f-f8e321b81d88",
							text: "Click me",
							type: "Scroller"
						}
					],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						overflowX: "hidden",
						overflow: "hidden",
						overflowY: "scroll",
						flexWrap: "no-wrap",
						flexDirection: "column",
						display: "flex",
						width: "100%",
						height: "100%"
					},
					attributes: {},
					containers: [],
					_id: "064ec1a4-5383-4102-8976-6cede850052d",
					type: "Container"
				}
			],
			_id: "03c77a76-049b-42ff-8792-aa21be88ab23",
			type: "Container"
		},
		name: "Vault",
		url: "vault"
	},
	//Map
	{
		layout: {
			elements: [],
			active: false,
			action: { type: "none", value: "" },
			styles: {
				width: "100%",
				flexWrap: "no-wrap",
				flexDirection: "column",
				display: "flex",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						width: "100%",
						backgroundColor: "#081f28ff",
						flexWrap: "no-wrap",
						flexDirection: "row",
						display: "flex",
						height: "80px"
					},
					attributes: {},
					containers: [
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								width: "100%",
								flexWrap: "no-wrap",
								flexDirection: "column",
								justifyContent: "center",
								display: "flex",
								height: "100%"
							},
							attributes: {},
							containers: [],
							_id: "f00a9f57-c2fe-42ba-80b6-0a9755c1d7a0",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								padding: "",
								margin: "",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "100%",
								justifyContent: "center",
								height: "100%"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T09:25:44.651Z",
											campaignID: "undefined",
											name: "logo2",
											id:
												"d4f6b1b0-4e9a-11ec-95a2-5fae02a3ac6b",
											type: "image/png",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo.png",
											key: "vlogo.png",
											updatedAt:
												"2021-11-26T09:25:44.651Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo.png')",
										display: "flex",
										width: "100%",
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "60%"
									},
									attributes: {},
									containers: [],
									_id: "78699144-868f-4793-a9de-83e270df9112",
									type: "Container"
								}
							],
							_id: "bcf433f1-ffe5-4a85-8f2c-ad4f6c5639ff",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								alignItems: "flex-end",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "100%",
								justifyContent: "center",
								height: "100%"
							},
							attributes: {},
							containers: [],
							_id: "2f6a9c47-05e0-46ee-a0e6-edcfd96eb25e",
							type: "Container"
						}
					],
					_id: "2ffa5199-c6ad-462e-8ae3-3cc97b15d1e3",
					type: "Container"
				},
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						width: "100%",
						flexWrap: "no-wrap",
						flexDirection: "row",
						display: "flex",
						height: "60px"
					},
					attributes: {},
					containers: [
						{
							elements: [],
							active: false,
							action: { type: "go-to-page", value: "vault" },
							styles: {
								backgroundColor: "#3d9268ff",
								alignItems: "center",
								flexDirection: "column",
								backgroundImage: "url('')",
								display: "flex",
								justifyContent: "center",
								flexWrap: "no-wrap",
								backgroundImageAsset: {
									createdAt: "2021-11-26T09:41:40.295Z",
									campaignID:
										"28f1a6c0-4e92-11ec-95a2-5fae02a3ac6b",
									name: "wallet",
									id: "0e926570-4e9d-11ec-95a2-5fae02a3ac6b",
									type: "image/svg+xml",
									category: "Category 1",
									url: "",
									key: "",
									updatedAt: "2021-11-26T09:41:40.295Z"
								},
								width: "100%",
								backgroundSize: "",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								height: "100%"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T09:51:00.894Z",
											campaignID: "undefined",
											name: "money",
											id:
												"5cb703e0-4e9e-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/money-bill-wave-solid.svg",
											key: "money-bill-wave-solid.svg",
											updatedAt:
												"2021-11-26T09:51:00.894Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/money-bill-wave-solid.svg')",
										display: "flex",
										width: "50%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "60%"
									},
									attributes: {},
									containers: [],
									_id: "2b30bee3-1f63-490f-853b-46f663c750d9",
									type: "Container"
								}
							],
							_id: "cd08451f-705c-4a75-9d7c-19637df39a25",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								backgroundColor: "#70f99eff",
								alignItems: "center",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "100%",
								justifyContent: "center",
								height: "100%"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										alignItems: "center",
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T09:48:28.776Z",
											campaignID: "undefined",
											name: "map",
											id:
												"020b7f70-4e9e-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/map-marked-alt-solid.svg",
											key: "map-marked-alt-solid.svg",
											updatedAt:
												"2021-11-26T09:48:28.776Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/map-marked-alt-solid.svg')",
										display: "flex",
										width: "50%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										justifyContent: "center",
										height: "60%"
									},
									attributes: {},
									containers: [],
									_id: "997aa5d9-ac68-4bca-bb1c-10447662d25f",
									type: "Container"
								}
							],
							_id: "8c30b9da-42bf-4f51-b61f-9339b5714923",
							type: "Container"
						}
					],
					_id: "d38fdb57-53a9-4165-aa0d-3d7b2e08fb92",
					type: "Container"
				},
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						width: "100%",
						flexWrap: "no-wrap",
						flexDirection: "column",
						display: "flex",
						height: "100%"
					},
					attributes: {},
					containers: [
						{
							elements: [
								{
									elements: [],
									name: "Map",
									active: false,
									action: { type: "", value: "" },
									styles: {},
									attributes: {},
									containers: [],
									_id: "05eb0167-1fff-48a4-8afc-351d8605ff9b",
									text: "",
									type: "Map"
								}
							],
							active: false,
							action: { type: "none", value: "" },
							styles: {
								width: "100%",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								height: "100%"
							},
							attributes: {},
							containers: [],
							_id: "ca5495cf-0ff6-4802-a86e-2d8af0b069d9",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "drop-items", value: "drop-items" },
							styles: {
								top: "10px",
								flexWrap: "no-wrap",
								left: "10px",
								flexDirection: "column",
								display: "flex",
								width: "40px",
								position: "absolute",
								height: "40px"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										backgroundColor: "#ffffffff",
										borderRadius: "100px",
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T10:01:05.969Z",
											campaignID:
												"28f1a6c0-4e92-11ec-95a2-5fae02a3ac6b",
											name: "pin",
											id:
												"c55e2210-4e9f-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/location-dot-solid.svg",
											key: "location-dot-solid.svg",
											updatedAt:
												"2021-11-26T10:01:05.969Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/location-dot-solid.svg')",
										display: "flex",
										width: "40px",
										backgroundSize: "50%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "40px"
									},
									attributes: {},
									containers: [],
									_id: "e8f7b042-1394-4756-be93-8db9e6334a01",
									type: "Container"
								}
							],
							_id: "2fc87485-7ab3-4a0b-b620-1ebf6d384522",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "center-map", value: "center-map" },
							styles: {
								top: "10px",
								flexWrap: "no-wrap",
								flexDirection: "column",
								display: "flex",
								width: "40px",
								position: "absolute",
								right: "10px",
								height: "40px"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										backgroundColor: "#ffffffff",
										borderRadius: "100px",
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T10:03:11.671Z",
											campaignID:
												"28f1a6c0-4e92-11ec-95a2-5fae02a3ac6b",
											name: "cross-hair",
											id:
												"104abc70-4ea0-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/location-crosshairs-solid.svg",
											key:
												"location-crosshairs-solid.svg",
											updatedAt:
												"2021-11-26T10:03:11.671Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/location-crosshairs-solid.svg')",
										display: "flex",
										width: "40px",
										backgroundSize: "70%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "40px"
									},
									attributes: {},
									containers: [],
									_id: "90610400-ab8f-4884-ad9a-68556b8311b5",
									type: "Container"
								}
							],
							_id: "4d676f85-c980-4f92-bf13-72b0294a8492",
							type: "Container"
						},
						{
							elements: [],
							active: false,
							action: { type: "go-to-page", value: "ar-viewer" },
							styles: {
								flexWrap: "no-wrap",
								left: "10px",
								flexDirection: "column",
								bottom: "40px",
								display: "flex",
								width: "40px",
								position: "absolute",
								height: "40px"
							},
							attributes: {},
							containers: [
								{
									elements: [],
									active: false,
									action: { type: "none", value: "" },
									styles: {
										backgroundColor: "#ffffffff",
										borderRadius: "100px",
										flexWrap: "no-wrap",
										backgroundImageAsset: {
											createdAt:
												"2021-11-26T10:10:27.598Z",
											campaignID:
												"28f1a6c0-4e92-11ec-95a2-5fae02a3ac6b",
											name: "ar-icon",
											id:
												"141feae0-4ea1-11ec-95a2-5fae02a3ac6b",
											type: "image/svg+xml",
											category: "Category 1",
											url:
												"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vr-cardboard-solid.svg",
											key: "vr-cardboard-solid.svg",
											updatedAt:
												"2021-11-26T10:10:27.598Z"
										},
										flexDirection: "column",
										backgroundImage:
											"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vr-cardboard-solid.svg')",
										display: "flex",
										width: "40px",
										backgroundSize: "70%",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "40px"
									},
									attributes: {},
									containers: [],
									_id: "ffab01b8-3753-4654-b18d-3fe7a489491c",
									type: "Container"
								}
							],
							_id: "1de5624d-1a1b-4011-9d3a-f142b47129e8",
							type: "Container"
						}
					],
					_id: "064ec1a4-5383-4102-8976-6cede850052d",
					type: "Container"
				}
			],
			_id: "03c77a76-049b-42ff-8792-aa21be88ab23",
			type: "Container"
		},
		name: "Map",
		url: "map"
	},
	//AR
	{
		name: "Ar Viewer",
		url: "ar-viewer",
		layout: {
			_id: "3a6cb1aa-0f17-4df6-98e8-aecc88045e19",
			type: "Container",
			active: false,
			action: { type: "none", value: "" },
			styles: {
				display: "flex",
				flexDirection: "column",
				flexWrap: "no-wrap",
				width: "100%",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					_id: "aaa1e352-5581-4537-9432-6470f907a7c7",
					type: "Container",
					active: false,
					action: { type: "none", value: "" },
					styles: {
						display: "flex",
						flexDirection: "column",
						flexWrap: "no-wrap",
						width: "100%",
						height: "100%"
					},
					attributes: {},
					containers: [],
					elements: [
						{
							_id: "eb937eae-7547-4d3e-8f04-1f35944d5387",
							name: "AR",
							active: false,
							type: "AR",
							text: "",
							action: { type: "", value: "" },
							styles: {},
							attributes: {},
							containers: [],
							elements: []
						}
					]
				},
				{
					_id: "53e76e8c-466a-4062-b533-d9ba8d18de22",
					type: "Container",
					active: false,
					action: { type: "none", value: "" },
					styles: {
						display: "flex",
						flexDirection: "column",
						flexWrap: "no-wrap",
						width: "100%",
						height: "80px",
						position: "absolute",
						bottom: "0",
						justifyContent: "center",
						alignItems: "center"
					},
					attributes: {},
					containers: [
						{
							_id: "2a72fd52-86a0-4a2d-aee8-7440b5cd79f1",
							type: "Container",
							active: false,
							action: { type: "go-to-page", value: "map" },
							styles: {
								display: "flex",
								flexDirection: "column",
								flexWrap: "no-wrap",
								width: "40px",
								height: "40px",
								backgroundColor: "#70f99eff",
								borderRadius: "100px",
								backgroundImageAsset: {
									id: "c5dd46b0-4eab-11ec-95a2-5fae02a3ac6b",
									name: "close",
									campaignID:
										"823ac820-4eaa-11ec-95a2-5fae02a3ac6b",
									type: "image/svg+xml",
									url:
										"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/xmark-solid.svg",
									key: "xmark-solid.svg",
									category: "Category 1",
									createdAt: "2021-11-26T11:27:00.763Z",
									updatedAt: "2021-11-26T11:27:00.763Z"
								},
								backgroundImage:
									"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/xmark-solid.svg')",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "50%"
							},
							attributes: {},
							containers: [],
							elements: []
						}
					],
					elements: []
				}
			],
			elements: []
		}
	},
	//Campaign over
	{
		layout: {
			elements: [],
			active: false,
			action: { type: "none", value: "" },
			styles: {
				backgroundColor: "#081f28ff",
				alignItems: "center",
				flexWrap: "no-wrap",
				flexDirection: "column",
				display: "flex",
				width: "100%",
				justifyContent: "center",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					elements: [],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						flexWrap: "no-wrap",
						backgroundImageAsset: {
							createdAt: "2021-11-26T08:54:28.133Z",
							campaignID: "undefined",
							name: "logo",
							id: "7678c550-4e96-11ec-95a2-5fae02a3ac6b",
							type: "image/png",
							category: "Category 1",
							url:
								"https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png",
							key: "vlogo-type.png",
							updatedAt: "2021-11-26T08:54:28.133Z"
						},
						flexDirection: "column",
						backgroundImage:
							"url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png')",
						display: "flex",
						width: "200px",
						flexOrder: "3",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						height: "100px"
					},
					attributes: {},
					containers: [],
					_id: "cb6beab2-9339-4388-8425-f9f32d420f64",
					type: "Container"
				},
				{
					elements: [
						{
							elements: [],
							name: "Paragraph",
							active: false,
							action: { type: "", value: "" },
							styles: { color: "#ffffffff", fontSize: "14px" },
							attributes: { text: "Paragraph" },
							containers: [],
							_id: "8eaedc80-5c7c-4e11-a33f-1e0cc52af34e",
							text: "This campaign has come to a close",
							type: "Paragraph"
						}
					],
					active: false,
					action: { type: "none", value: "" },
					styles: {
						alignItems: "center",
						flexWrap: "no-wrap",
						flexDirection: "column",
						display: "flex",
						width: "100%",
						justifyContent: "center",
						height: "200px"
					},
					attributes: {},
					containers: [],
					_id: "b2b5f91d-e0a9-4b94-bc88-1bd5db25c3a7",
					type: "Container"
				}
			],
			_id: "09602b7a-d0c8-4fd8-8a88-b12897a72acc",
			type: "Container"
		},
		name: "Campaign over",
		url: "campaign-over"
	},
	// Not For Desktop
	{
		name: "Not For Desktop",
		url: "not-for-desktop",
		layout: {
			elements: [],
			active: false,
			action: {
				type: "none",
				value: null
			},
			styles: {
				backgroundColor: "#081f28ff",
				alignItems: "center",
				color: "#fff",
				flexWrap: "nowrap",
				flexDirection: "row",
				display: "flex",
				width: "100%",
				justifyContent: "center",
				height: "100%"
			},
			attributes: {},
			containers: [
				{
					_id: "0a6675f7-5537-4545-9623-64a1d279c089",
					type: "Container",
					active: false,
					action: {
						type: "none",
						value: ""
					},
					styles: {
						display: "flex",
						flexDirection: "column",
						flexWrap: "no-wrap",
						width: "100%",
						height: "100%",
						position: "relative",
						backgroundRepeat: "no-repeat",
						borderStyle: "none",
						fontStyle: "normal",
						textAlign: "left",
						backgroundImageAsset: {
							id: "791d3170-1d57-11ed-9cd5-23b458a66700",
							campaignID: "6bedd370-1897-11ed-aa5a-45c2eaef0ba8",
							name: "Background",
							type: "image/jpeg",
							key: "6bedd370-1897-11ed-aa5a-45c2eaef0ba8/picsum_103.jpg",
							url: "https://dev-virtuoso-assets.s3.eu-west-1.amazonaws.com/6bedd370-1897-11ed-aa5a-45c2eaef0ba8/picsum_103.jpg",
							category: "Image",
							createdAt: "2022-08-16T11:35:04.967Z",
							updatedAt: "2022-08-16T11:35:04.967Z"
						},
						backgroundImage: "url('https://dev-virtuoso-assets.s3.eu-west-1.amazonaws.com/6bedd370-1897-11ed-aa5a-45c2eaef0ba8/picsum_103.jpg')",
						backgroundSize: "cover",
						backgroundPosition: "bottom center",
						padding: "2rem"
					},
					attributes: {
						id: ""
					},
					containers: [
						{
							_id: "cc2f24b2-608c-4413-bdf2-d574dcfe08c3",
							type: "Container",
							active: false,
							action: {
								type: "none",
								value: ""
							},
							styles: {
								display: "flex",
								flexDirection: "column",
								flexWrap: "no-wrap",
								width: "100%",
								height: "100%",
								position: "absolute",
								backgroundRepeat: "no-repeat",
								borderStyle: "none",
								fontStyle: "normal",
								textAlign: "left",
								backgroundImageAsset: {
									id: "acb73170-1d66-11ed-b3d9-837913bebee7",
									campaignID: "6bedd370-1897-11ed-aa5a-45c2eaef0ba8",
									name: "Iphone Mockup",
									type: "image/png",
									key: "6bedd370-1897-11ed-aa5a-45c2eaef0ba8/iphone-cropped.png",
									url: "https://dev-virtuoso-assets.s3.eu-west-1.amazonaws.com/6bedd370-1897-11ed-aa5a-45c2eaef0ba8/iphone-cropped.png",
									category: "Image",
									createdAt: "2022-08-16T13:23:53.991Z",
									updatedAt: "2022-08-16T13:23:53.991Z"
								},
								backgroundImage: "url('https://dev-virtuoso-assets.s3.eu-west-1.amazonaws.com/6bedd370-1897-11ed-aa5a-45c2eaef0ba8/iphone-cropped.png')",
								backgroundSize: "contain",
								backgroundPosition: "center",
								top: "0",
								left: "0",
								bottom: "0",
								right: "0"
							},
							attributes: {
								id: ""
							},
							containers: [],
							elements: []
						},
						{
							_id: "60ba9bb5-f897-4350-a240-1cf0449d4f78",
							type: "Container",
							active: true,
							action: {
								type: "none",
								value: ""
							},
							styles: {
								display: "flex",
								flexDirection: "column",
								flexWrap: "no-wrap",
								width: "100%",
								height: "100%",
								position: "relative",
								backgroundRepeat: "no-repeat",
								borderStyle: "none",
								fontStyle: "normal",
								textAlign: "left",
								backgroundSize: "200px",
								backgroundPosition: "center",
								backgroundImage: "url('https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png')",
								backgroundImageAsset: {
									createdAt: "2021-11-26T08:54:28.133Z",
									campaignID: "undefined",
									name: "logo",
									id: "7678c550-4e96-11ec-95a2-5fae02a3ac6b",
									type: "image/png",
									category: "Category 1",
									url: "https://test-virtuoso-assets.s3.eu-west-1.amazonaws.com/vlogo-type.png",
									updatedAt: "2021-11-26T08:54:28.133Z"
								},
							},
							attributes: {
								id: ""
							},
							containers: [],
							elements: []
						}
					],
					elements: []
				},
				{
					elements: [
						{
							_id: "28a581d0-0e3f-44e6-a98e-90e88952f6ed",
							name: "Header",
							active: false,
							type: "Header",
							text: "We are doing some cool things in AR!",
							action: {
								type: "",
								value: ""
							},
							styles: {
								color: "#ffffffff",
								fontSize: "23px"
							},
							attributes: {
								id: ""
							},
							containers: [],
							elements: []
						},
						{
							_id: "b294f1f1-506b-4e9e-8b44-961543d8e856",
							name: "Paragraph",
							active: false,
							type: "Paragraph",
							text: "This means that some features wont work on desktop and laptop browsers.",
							action: {
								type: "",
								value: ""
							},
							styles: {
								color: "#edededff",
								fontSize: "12px"
							},
							attributes: {
								text: "Paragraph"
							},
							containers: [],
							elements: []
						},
						{
							_id: "f40fd38d-4a06-452e-8408-f3104fc1e83d",
							name: "Paragraph",
							active: false,
							type: "Paragraph",
							text: "Please open this link on your mobile or tablet to get the full experience of this campaign\n\n ",
							action: {
								type: "",
								value: ""
							},
							styles: {
								color: "#ffffffff",
								fontSize: "12px"
							},
							attributes: {
								text: "Paragraph"
							},
							containers: [],
							elements: []
						}
					],
					active: false,
					action: {
						type: "none",
						value: null
					},
					styles: {
						flexWrap: "no-wrap",
						textAlign: "center",
						flexDirection: "column",
						display: "flex",
						width: "100%",
						position: "relative",
						borderStyle: "none",
						fontStyle: "normal",
						backgroundRepeat: "no-repeat",
						height: "100%",
						color: "#fff",
						justifyContent: "center",
						alignItems: "center",
						padding: "3rem"
					},
					attributes: {
						id: null
					},
					containers: [],
					_id: "cdb71d91-1da9-4bdf-b927-cba57235c1c9",
					type: "Container"
				}

			],
			_id: "09602b7a-d0c8-4fd8-8a88-b12897a72acc",
			type: "Container"
		}
	}
];
