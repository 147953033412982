
import { defineComponent, ref } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	setup(props, { emit }) {
		const elementRef = ref(props.element);

		return { elementRef };
	}
});
