
import { defineComponent } from "vue";
import moment from "moment";
import UpdateCreateCampaignForm from "@/components/forms/UpdateCreateCampaignForm.vue";
import { message } from "ant-design-vue";
import { Stats } from "@/types/Stats";
// import { campaignStore } from "@/store/campaignStore";
import { DuplicateCampaignDTO } from "@/types/campaign";
import store from "@/store";

export default defineComponent({
	props: {
		campaign: {
			type: Object,
			required: true
		}
	},
	emits: ["update"],
	data() {
		return {
			campaignStats: {} as Stats,
			campaignOver: false,
			userAppURL: process.env.VUE_APP_USER_URL,
			permanentString: "",
			moment: moment,
			menuVisible: false,
			showModal: false,
			showDeleteModal: false,
			loading: false
		};
	},
	mounted() {
		this.getCampaignStats();
	},
	methods: {
		gotoCampaign(id: string) {
			store.commit("campaignStore/commitSelectedItemID", id);
			this.$router.push({
				name: "Campaign Manager",
				params: { campaignID: id }
			});
		},
		async getCampaignStats() {
			this.campaignStats = await store.dispatch(
				"campaignStore/dispatchGetCampaignStats",
				this.campaign.id
			);
			console.log(this.campaignStats);
		},
		openUpdateModal() {
			this.menuVisible = false;
			this.showModal = true;
		},
		async duplicateCampaign() {
			if (!this.loading) {
				this.loading = true;
				const duplicateCampaignDto: DuplicateCampaignDTO = {
					campaignID: this.campaign.id,
					name: `${this.campaign.name}_copy`,
					startDate: this.campaign.startDate,
					endDate: this.campaign.endDate
				};
				await store.dispatch(
					"campaignStore/dispatchDuplicateItem",
					duplicateCampaignDto
				);
				this.loading = false;
			}
		},
		onDelete() {
			this.menuVisible = false;
			this.showDeleteModal = true;
		},
		checkDeadline() {
			if (moment().isAfter(this.momentDeadline)) {
				this.campaignOver = true;
			} else {
				this.campaignOver = false;
			}
		},
		changesSaved() {
			this.showModal = false;
			this.checkDeadline();
			this.$emit("update");
		},
		onCancelDelete() {
			this.showDeleteModal = false;
			this.permanentString = "";
		},
		async handleDelete(id: string) {
			this.showDeleteModal = false;
			this.permanentString = "";
			const response = await store.dispatch(
				"campaignStore/dispatchDeleteItem",
				id
			);
			if (response.success === true) {
				message.success(response.message);
			} else {
				message.error(response.message);
			}
			this.$emit("update");
		}
	},
	computed: {
		endDate() {
			return moment(this.campaign.endDate);
		},
		momentDeadline() {
			return this.endDate.add(1, "day");
		}
	},
	components: {
		UpdateCreateCampaignForm
	}
});
