
import { selectLayout } from "@/composables/models/use-containers";
import { selectedCustomPageByURL } from "@/composables/models/use-customPages";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true
		}
	},
	emits: ["update"],
	setup(props, { emit }) {
		//variables
		const elementRef = ref(props.element);
		const edit = ref(false);

		function updateElement() {
			edit.value = false;
			emit("update", elementRef.value);
		}
		function editElement($event: any) {
			edit.value = true;
			setTimeout(() => {
				const elem = document.querySelector(
					"#textearea-input"
				) as HTMLInputElement;
				elem.select();
			}, 100);
		}

		return { elementRef, edit, updateElement, editElement };
	}
});
