import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { Asset } from "../types/asset";
import ApiProtected from "../utils/api-protected";
import { ComputedRef, computed } from "vue";

VuexModule.__useHotUpdate = true;

@Module
class AssetStore extends VuexModule {
	api = ApiProtected.getInstance();
	// state
	items: Asset[] = [];
	selectedItemID = "";

	// getters
	get getItems(): ComputedRef<Asset[]> {
		return computed(() => this.items);
	}
	get getSelectedItem(): ComputedRef<Asset | undefined> {
		return computed(() =>
			this.items.find(e => e.id == this.selectedItemID)
		);
	}

	// mutations
	@Mutation
	commitSaveItems(items: Asset[]) {
		this.items = items.reverse();
	}
	@Mutation
	commitSaveItem(item: Asset) {
		const index = this.items.findIndex(e => e.id == item.id);
		if (index == -1) {
			this.items.push(item);
		} else {
			this.items[index] = item;
		}
	}
	@Mutation
	commitDeleteItem(itemID: string) {
		const index = this.items.findIndex(e => e.id == itemID);
		if (index != -1) {
			this.items.splice(index, 1);
		}
	}
	@Mutation
	commitSelectedItemID(itemID: string) {
		this.selectedItemID = itemID;
	}

	//actions;
	@Action
	async dispatchGetItems(params: RequestByCampaignID): Promise<boolean> {
		const response: Response = await this.api.getAssets(params);
		if (response.success === true) {
			const items: Asset[] = response.data;
			this.commitSaveItems(items);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchGetItemByID(params: RequestByID): Promise<boolean> {
		const response: Response = await this.api.getAssetByID(params);
		if (response.success === true) {
			const item: Asset = response.data;
			this.commitSaveItem(item);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchCreateItem(item: CampaignObjectRequest): Promise<Response> {
		console.log(item);
		const itemCreated = await this.api.createAsset(item);
		console.log(itemCreated);
		if (itemCreated.success === true) this.commitSaveItem(itemCreated.data);
		return itemCreated;
	}
	@Action
	async dispatchUpdateItem(item: CampaignObjectRequest): Promise<Response> {
		const itemUpdated = await this.api.updateAsset(item);
		if (itemUpdated.success === true) this.commitSaveItem(itemUpdated.data);
		return itemUpdated;
	}
	@Action
	async dispatchDeleteItem(delObject: RequestByID): Promise<Response> {
		const itemDeleted = await this.api.deleteAsset(delObject);
		console.log(itemDeleted);
		if (itemDeleted.success === true) this.commitDeleteItem(delObject.id);
		return itemDeleted;
	}
	@Action
	async dispatchUploadFile(item: {
		fileName: string;
		base: string | ArrayBuffer | null;
		campaignID: string;
	}): Promise<Response> {
		const itemCreated = await this.api.uploadFile(
			{ fileName: item.fileName, base: item.base },
			item.campaignID
		);
		return itemCreated;
	}
}

// register module (could be in any file)
import store from "./";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { RequestByCampaignID } from "@/types/requests";
export const assetStore = new AssetStore({ store, name: "asset" });
