
import {
	selectContainer,
	selectLayout
} from "@/composables/models/use-containers";
import {
	selectedCustomPageByURL,
	updateCustomPage
} from "@/composables/models/use-customPages";
import { Action, Container } from "@/types/customPage";
import ButtonComponent from "@/components/artwork/element-components/ButtonComponent.vue";
import HeaderTextComponent from "@/components/artwork/element-components/HeaderTextComponent.vue";
import ParagraphComponent from "@/components/artwork/element-components/ParagraphComponent.vue";
import InputTextComponent from "@/components/artwork/element-components/InputTextComponent.vue";
import InputTextAreaComponent from "@/components/artwork/element-components/InputTextAreaComponent.vue";
import VideoComponent from "@/components/artwork/element-components/VideoComponent.vue";
import ARComponent from "@/components/artwork/element-components/ARComponent.vue";
import MapComponent from "@/components/artwork/element-components/MapComponent.vue";
import IFrameComponent from "@/components/artwork/element-components/IFrameComponent.vue";
import IconComponent from "@/components/artwork/element-components/IconComponent.vue";
import CheckboxComponent from "@/components/artwork/element-components/CheckboxComponent.vue";
import ScrollerComponent from "@/components/artwork/element-components/ScrollerComponent.vue";
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	components: {
		ButtonComponent,
		HeaderTextComponent,
		ParagraphComponent,
		InputTextComponent,
		VideoComponent,
		IFrameComponent,
		ARComponent,
		MapComponent,
		IconComponent,
		ScrollerComponent,
		CheckboxComponent,
		InputTextAreaComponent
	},
	props: {
		container: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ["update"],

	setup(props, { emit }) {
		//variables
		const router = useRouter();
		const containerRef = ref(props.container);
		const selectedpage = selectedCustomPageByURL();
		//dispatchers

		//getters

		//functions
		watch(props, () => {
			containerRef.value = props.container;
		});

		const onSelectContainer = () => {
			// const customPage = selectedCustomPageByURL();
			// if (customPage.value) {
			// 	selectLayout(customPage.value!.layout);
			// }
			selectContainer(containerRef.value as Container);
		};

		const onSelectElement = (element: Container) => {
			// const customPage = selectedCustomPageByURL();
			// if (customPage.value) {
			// 	selectLayout(customPage.value!.layout!);
			// }
			selectContainer(element as Container);
		};

		function onUpdateElement(element: Container, index: number) {
			containerRef.value.elements[index] = element;
			savePage();
		}

		async function savePage() {
			if (selectedpage.value) {
				updateCustomPage(selectedpage.value);
			}
		}

		function gotoPage(page: string) {
			router.push({
				name: `Artwork`,
				params: { custompage: page }
			});
		}
		function doAction(action: Action) {
			if (!props.editable) {
				if (action.type == "go-to-page") {
					gotoPage(action.value);
				}
			}
		}

		return {
			containerRef,
			selectedpage,
			onSelectContainer,
			onSelectElement,
			doAction,
			onUpdateElement
		};
	}
});
