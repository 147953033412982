
import { defineComponent, ref } from "vue";
import {
	blankManager,
	createManager,
	validationRules,
} from "@/composables/models/use-managers";
import { Manager } from "@/types/manager";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

export default defineComponent({
	emits: ["saved", "signin"],
	props: {},
	setup(props, { emit }) {
		//variables
		const formSignUpRef = ref();
		const router = useRouter();
		let managerSignUpRef = ref(blankManager());
		let showModal = ref(false);

		const rules = validationRules();
		//dispatchers

		//getters

		//functions
		const createManagers = () => {
			formSignUpRef.value.validate().then(() => {
				createManager(managerSignUpRef.value).then((response: Response) => {
					if (response.success === true) {
						showModal.value = true;
					} else {
						message.error(response.message);
					}
				});
			});
		};
		const onOK = () => {
			showModal.value = false;
			emit("saved");
		};

		return {
			createManagers,
			managerSignUpRef,
			showModal,
			onOK,
			formSignUpRef,
			rules,
		};
	},
});
