import { assetStore } from "@/store/assetStore";
import { Asset } from "@/types/asset";
import { ComputedRef } from "vue";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { RequestByCampaignID } from "@/types/requests";

export function allAssets(): ComputedRef<Asset[]> {
	const items = assetStore.getItems;
	return items;
}
export function selectedAsset(itemID: string): ComputedRef<Asset | undefined> {
	assetStore.commitSelectedItemID(itemID);
	const item = assetStore.getSelectedItem;
	return item;
}
export async function getAssets(params: RequestByCampaignID): Promise<boolean> {
	return assetStore.dispatchGetItems(params);
}

export async function createAsset(
	item: CampaignObjectRequest
): Promise<Response> {
	return await assetStore.dispatchCreateItem(item);
}

export async function updateAsset(
	item: CampaignObjectRequest
): Promise<Response> {
	return await assetStore.dispatchUpdateItem(item);
}

export async function deleteAsset(
	deleteObject: RequestByID
): Promise<Response> {
	return await assetStore.dispatchDeleteItem(deleteObject);
}

export async function uploadFile(item: {
	fileName: string;
	base: string | ArrayBuffer | null;
	campaignID: string;
}): Promise<Response> {
	return await assetStore.dispatchUploadFile(item);
}

export function blankAsset(): Asset {
	const asset: Asset = {
		name: "",
		type: "",
		key: "",
		url: "",
		category: ""
	};
	return asset;
}

export function validationRules() {
	const rules = {
		name: [
			{ required: true, message: "Name is required", trigger: "onBlur" },
			{
				min: 1,
				max: 35,
				message: "Length should be 1 to 35",
				trigger: "onBlur"
			}
		]
	};
	return rules;
}
