
import { defineComponent, ref, computed, onMounted, watchEffect } from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import AssetItemComponent from "@/components/assets/AssetItemComponent.vue";
import UpdateCreateAssetForm from "@/components/forms/UpdateCreateAssetForm.vue";
import {
	allAssets,
	blankAsset,
	getAssets
} from "@/composables/models/use-assets";
import Search from "@/components/utils/Search.vue";
import { Asset } from "@/types/asset";
import { useRoute } from "vue-router";

export default defineComponent({
	props: {
		campID: {
			type: String,
			required: false
		}
	},
	emits: ["setAsset"],
	setup(props, { emit }) {
		//variables
		let campaignID = "";
		if (props.campID) {
			// eslint-disable-next-line vue/no-setup-props-destructure
			campaignID = props.campID;
		} else {
			campaignID = useRoute().params.campaignID.toString();
		}
		getAssets({ campaignID: campaignID });
		let assets = allAssets();
		let filteredAssets = ref<Asset[]>(assets.value);
		let newAsset = ref(blankAsset());
		let asset = computed(() => newAsset.value);
		let filterString = ref("");
		let showCreateForm = ref(false);
		let title = ref("Create");
		//dispatchers

		//getters

		//functions
		onMounted(() => {
			console.log(assets);
			filteredAssets.value = assets.value;
		});
		watchEffect(() => {
			filteredAssets.value = assets.value;

			// console.log(assets.value.length);
			// filteredAssets.value = filtered("a");
		});

		const createAsset = () => {
			title.value = "Create";
			newAsset.value = ref(blankAsset()).value;
			asset = computed(() => newAsset.value);
			showCreateForm.value = true;
		};
		const onCancel = () => {
			showCreateForm.value = false;
		};
		const modifiedAsset = () => {
			showCreateForm.value = false;
			filterString.value = "";
			filteredAssets.value = ref(assets.value).value;
		};
		const updateAsset = (assetRef: Asset) => {
			title.value = "Update";
			newAsset.value = assetRef;
			asset = computed(() => newAsset.value);
			showCreateForm.value = true;
		};
		function filtered(searchString: string): Asset[] {
			filterString.value = searchString;
			filteredAssets.value = assets.value.filter(e => {
				return e.name
					.toLowerCase()
					.includes(searchString.toLowerCase());
			});
			return assets.value;
		}
		function useAsset(asset: Asset) {
			console.log(asset);
			emit("setAsset", asset);
		}

		function filterCategory(category: string) {
			return filteredAssets.value.filter(e => e.category == category);
		}

		return {
			createAsset,
			onCancel,
			asset,
			assets,
			filtered,
			filteredAssets,
			modifiedAsset,
			filterString,
			showCreateForm,
			updateAsset,
			title,
			useAsset,
			filterCategory,
			campaignID
		};
	},
	components: {
		Scroller,
		AssetItemComponent,
		UpdateCreateAssetForm,
		Search
	}
});
