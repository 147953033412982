
import { ref, computed } from "vue";
import { defineComponent } from "vue";
import UpdateCreateCustomComponentForm from "@/components/forms/UpdateCreateCustomComponentForm.vue";
import {
	deleteCustomComponent,
	selectCustomComponent
} from "@/composables/models/use-customComponents";
import { RequestByID } from "@/types/requests";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { selectLayout } from "@/composables/models/use-containers";

export default defineComponent({
	props: {
		customComponent: {
			type: Object,
			required: true
		}
	},
	emits: ["delete", "update"],
	setup(props, { emit }) {
		//variables
		const showModal = ref(false);
		const router = useRouter();
		const customComponentRef = computed(() => props.customComponent);
		let showDeleteModal = ref(false);
		const method = ref("Update");
		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			showModal.value = false;
		};
		const onCancelDelete = () => {
			showDeleteModal.value = false;
		};
		const onDelete = () => {
			showDeleteModal.value = true;
		};
		const handleDelete = (id: string, campaignID: string) => {
			showDeleteModal.value = false;
			const delCustomComponent: RequestByID = {
				id: id,
				campaignID: campaignID
			};
			deleteCustomComponent(delCustomComponent).then(
				(response: Response) => {
					if (response.success === true) {
						message.success(response.message);
					} else {
						message.error(response.message);
					}
					emit("delete");
				}
			);
		};
		const changesSaved = () => {
			showModal.value = false;
			emit("update");
		};

		const onDuplicate = () => {
			method.value = "Duplicate";
			showModal.value = true;
		};
		const onEdit = () => {
			method.value = "Update";
			showModal.value = true;
		};

		function gotoCustomComponentConfigurator(customComponentID: string) {
			selectCustomComponent(customComponentID);
			selectLayout(customComponentRef.value!.container!);

			// router.push({
			// 	name: "CustomComponents",
			// 	params: { campaignID: customComponentRef.value.campaignID }
			// });
		}

		return {
			onCancel,
			showModal,
			customComponentRef,
			showDeleteModal,
			method,
			handleDelete,
			onDelete,
			onDuplicate,
			onEdit,
			onCancelDelete,
			changesSaved,
			gotoCustomComponentConfigurator
		};
	},
	components: {
		UpdateCreateCustomComponentForm
	}
});
