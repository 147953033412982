import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { Prize } from "../types/prize";
import ApiProtected from "../utils/api-protected";
import { ComputedRef, computed } from "vue";

VuexModule.__useHotUpdate = true;

@Module
class PrizeStore extends VuexModule {
	api = ApiProtected.getInstance();
	//state
	items: Prize[] = [];
	selectedItemID = "";

	//getter
	get getItems(): ComputedRef<Prize[]> {
		return computed(() => this.items);
	}
	get getSelectedItem(): ComputedRef<Prize | undefined> {
		return computed(() =>
			this.items.find(e => e.id == this.selectedItemID)
		);
	}

	// mutations
	@Mutation
	commitSaveItems(items: Prize[]) {
		this.items = items;
	}
	@Mutation
	commitSaveItem(item: Prize) {
		const index = this.items.findIndex(e => e.id == item.id);
		if (index == -1) {
			this.items.push(item);
		} else {
			this.items[index] = item;
		}
	}
	@Mutation
	commitDeleteItem(itemID: string) {
		const index = this.items.findIndex(e => e.id == itemID);
		if (index != -1) {
			this.items.splice(index, 1);
		}
	}
	@Mutation
	commitSelectedItemID(itemID: string) {
		this.selectedItemID = itemID;
	}

	//actions
	@Action
	async dispatchGetItems(params: RequestByCampaignID): Promise<boolean> {
		const response: Response = await this.api.getPrizes(params);
		console.log(response);
		if (response.success === true) {
			const items: Prize[] = response.data;
			this.commitSaveItems(items);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchGetItemByID(params: RequestByID): Promise<boolean> {
		const response: Response = await this.api.getPrizeByID(params);
		if (response.success === true) {
			const item: Prize = response.data;
			this.commitSaveItem(item);
			return true;
		} else {
			return false;
		}
	}
	@Action
	async dispatchCreateItem(item: CampaignObjectRequest): Promise<Response> {
		const itemCreated: Response = await this.api.createPrize(item);
		console.log(itemCreated);
		if (itemCreated.success === true) this.commitSaveItem(itemCreated.data);
		return itemCreated;
	}
	@Action
	async dispatchUpdateItem(item: Prize): Promise<Response> {
		const itemUpdated = await this.api.updatePrize(item);
		if (itemUpdated.success === true) {
			return itemUpdated;
		} else {
			return {} as Response;
		}
	}
	@Action
	async dispatchDeleteItem(delObject: RequestByID): Promise<Response> {
		const itemDeleted = await this.api.deletePrize(delObject);
		if (itemDeleted.success === true) this.commitDeleteItem(delObject.id);
		return itemDeleted;
	}
}

// register module (could be in any file)
import store from "./";
import { Response } from "@/utils/response";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { RequestByCampaignID } from "@/types/requests";
export const prizeStore = new PrizeStore({
	store,
	name: "prize"
});
