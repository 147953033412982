
import router from "@/router";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	setup(props) {
		const route = useRoute();
		const campaignId = ref(route.params.campaignID.toString());

		const gotoPage = (name: string) => {
			router.push({
				name: name,
				params: { campaignID: campaignId.value }
			});
		};

		return { gotoPage };
	}
});
