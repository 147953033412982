
import { defineComponent, ref } from "vue";

import { message } from "ant-design-vue";
import store from "@/store";
import { ARTemplate, BaseARTemplate } from "@/types/arTemplate";

export default defineComponent({
	components: {},
	props: {
		arTemplate: {
			type: Object,
			required: false
		},
		method: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	data() {
		return {
			form: ref(),
			loading: false,
			showAssetManager: false,
			arTemplateData: {} as ARTemplate
		};
	},
	async mounted() {
		await store.dispatch("arTemplateStore/dispatchGetBaseTemplates");
		if (this.arTemplate) {
			this.arTemplateData = JSON.parse(JSON.stringify(this.arTemplate));
		} else {
			this.arTemplateData = await store.dispatch(
				"arTemplateStore/blankARTemplate"
			);
		}
	},
	beforeUpdate() {
		console.log("before update");
		this.arTemplateData = JSON.parse(JSON.stringify(this.arTemplateData));
	},
	unmounted() {
		console.log("unmounted");
	},

	methods: {
		setTemplate(type: string) {
			const temp = this.baseARTemplates.find(e => e.type == type);
			this.arTemplateData.code = temp!.code;
		},
		async onCancel() {
			if (this.method === "Create") {
				this.arTemplateData = await store.dispatch(
					"arTemplateStore/blankARTemplate"
				);
				this.$emit("saved");
			} else {
				this.$emit("saved");
			}
		},
		async onSubmit() {
			this.loading = true;
			if (this.method === "Create") {
				console.log(this.arTemplateData);
				const response = await store.dispatch(
					"arTemplateStore/dispatchCreateItem",
					{
						data: this.arTemplateData,
						campaignID:
							store.getters["campaignStore/getSelectedItemID"]
					}
				);
				if (response.success) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				this.$emit("saved");
				this.loading = false;
				this.arTemplateData = await store.dispatch(
					"arTemplateStore/blankARTemplate"
				);
			} else if (this.method == "Update") {
				const response = await store.dispatch(
					"arTemplateStore/dispatchUpdateItem",
					this.arTemplateData
				);
				if (response.success) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				this.$emit("saved");
				this.loading = false;
			}
		}
	},
	computed: {
		baseARTemplates(): BaseARTemplate[] {
			return store.getters["arTemplateStore/getBaseARTemplates"];
		}
	}
});
