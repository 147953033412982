import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse
} from "axios";
import HttpClient from "./http-client";

import { User } from "../types/user";
import { Campaign, DuplicateCampaignDTO } from "../types/campaign";
import { CustomPage, Container } from "../types/customPage";
import { DropPool } from "../types/dropPool";
import { Prize } from "../types/prize";
import { CustomComponent } from "@/types/customComponent";
import { VItem } from "../types/vItem";
import { Category } from "../types/vItemCategory";
import { Wallet } from "../types/wallet";
import { Brand } from "@/types/brand";
import { Response } from "./response";
import {
	CampaignObjectRequest,
	ChallengeRequest,
	ConfirmForgotPasswordRequest,
	RequestByID,
	ForgotPasswordRequest,
	LoginRequest
} from "@/types/requests";
import { Manager } from "@/types/manager";
import router from "@/router";
import { VoucherCodes } from "@/types/voucherCodes";
import { RequestByCampaignID } from "@/types/requests";
import { ARTemplate } from "@/types/arTemplate";

export default class ApiProtected extends HttpClient {
	private static classInstance?: ApiProtected;

	private constructor() {
		super(process.env.VUE_APP_BACKEND_URL);
		this._initializeRequestInterceptor();
	}
	public static getInstance(): ApiProtected {
		if (!this.classInstance) {
			this.classInstance = new ApiProtected();
		}

		return this.classInstance;
	}

	private setInstance(config: AxiosRequestConfig): void {
		this.instance = axios.create(config);
		this._initializeRequestInterceptor();
	}

	private _initializeRequestInterceptor = () => {
		this.instance.interceptors.request.use(this._handleRequest);
		this.instance.interceptors.response.use(
			this._handleResponse,
			this._handleError
		);
	};

	private _handleRequest = (config: AxiosRequestConfig) => {
		config.headers["Authorization"] = localStorage.getItem("AccessToken");
		config.headers["auth_type"] = "manager";
		return config;
	};

	private _handleResponse = ({ data }: AxiosResponse) => data;

	protected _handleError = async (
		err: AxiosError
	): Promise<AxiosError | AxiosInstance> => {
		const originalConfig = err.config;
		if (originalConfig.url !== "/managers/login" && err.response) {
			// Access Token was expired
			if (
				err.response.status === 401 &&
				originalConfig.url !== "/managers/refresh"
			) {
				try {
					const refreshToken = localStorage.getItem("RefreshToken");
					if (refreshToken) {
						const res = await this.refreshToken(refreshToken);
						if (res.data.AuthenticationResult) {
							localStorage.setItem(
								"AccessToken",
								res.data.AuthenticationResult.AccessToken
							);
							localStorage.setItem(
								"IdToken",
								res.data.AuthenticationResult.IdToken
							);
							this.setInstance(originalConfig);
							return Promise.resolve(this.instance);
						}
					}
					this.clearTokensLogout();
				} catch (error) {
					console.error(error);
					this.clearTokensLogout();
				}
			} else if (
				err.response.status === 401 &&
				originalConfig.url == "/managers/refresh"
			) {
				this.clearTokensLogout();
			}
		}
		return Promise.reject(err);
	};

	public clearTokensLogout(): void {
		localStorage.removeItem("AccessToken");
		localStorage.removeItem("IdToken");
		localStorage.removeItem("RefreshToken");
		router.push("/");
	}

	public getUsers = (params?: {
		[key: string]: string;
	}): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>("/user", params);
	public getUserByID = (id: string): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/user/${id}`);
	public createUser = (data: User): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/user", data);
	public updateUser = (data: User): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>("/user", data);
	public deleteUser = (obj: RequestByID): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>("/user", { data: obj });

	public getAssets = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/campaigns/${params.campaignID}/assets`);
	public getAssetByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/assets/${params.id}`
		);
	public createAsset = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/assets`,
			data.data
		);
	public updateAsset = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/assets`,
			data.data
		);
	public deleteAsset = (obj: RequestByID): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/assets/${obj.id}`
		);

	public uploadFile = (
		data: {
			fileName: string;
			base: string | ArrayBuffer | null;
		},
		campaignID: string
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${campaignID}/assets/uploadfile`,
			data
		);

	public getCampaigns = (params?: {
		[key: string]: string;
	}): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>("/campaigns", params);
	public getCampaignByID = (id: string): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/campaigns/${id}`);
	public getCampaignStats = (id: string): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/campaigns/${id}/stats`);
	public createCampaign = (
		data: Campaign
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/campaigns", data);
	public updateCampaign = (
		data: Campaign
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>("/campaigns", data);
	public deleteCampaign = (id: string): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(`/campaigns/${id}`);
	public duplicateCampaign = (
		data: DuplicateCampaignDTO
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/campaigns/duplicate", data);

	public getCustomPages = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/customPages`
		);
	public getCustomPageByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/customPages/${params.id}`
		);
	public createCustomPage = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/customPages`,
			data.data
		);
	public updateCustomPage = (
		data: CustomPage
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/customPages`,
			data
		);
	public deleteCustomPage = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/customPages/${obj.id}`
		);

	public getDropPools = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/dropPools`
		);
	public getDropPoolByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/dropPoolS/${params.id}`
		);
	public createDropPool = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/dropPoolS`,
			data.data
		);
	public updateDropPool = (
		data: DropPool
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/dropPools`,
			data
		);
	public deleteDropPool = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/dropPools/${obj.id}`
		);

	public getPrizes = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/campaigns/${params.campaignID}/prizes`);
	public getPrizeByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/prizes/${params.id}`
		);
	public createPrize = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/prizes`,
			data.data
		);
	public updatePrize = (data: Prize): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/prizes`,
			data
		);
	public deletePrize = (obj: RequestByID): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/prizes/${obj.id}`
		);

	public getCustomComponents = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/customComponents`
		);
	public getCustomComponentByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/customComponents/${params.id}`
		);
	public createCustomComponent = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/customComponents`,
			data.data
		);
	public updateCustomComponent = (
		data: CustomComponent
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/customComponents`,
			data
		);
	public deleteCustomComponent = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/customComponents/${obj.id}`
		);

	public getVItems = (params?: {
		[key: string]: string;
	}): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>("/vitem", params);
	public getVItemByID = (id: string): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/vitem/${id}`);
	public createVItem = (data: VItem): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/vitem", data);
	public updateVItem = (data: VItem): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>("/vitem", data);
	public deleteVItem = (obj: RequestByID): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>("/vitem", { data: obj });

	public getCategories = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params!.campaignID}/categories`
		);
	public getCategoryByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/categories/${params.id}`
		);
	public createCategory = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/categories`,
			data.data
		);
	public updateCategory = (
		data: Category
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/categories`,
			data
		);
	public deleteCategory = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/categories/${obj.id}`
		);

	public getWallets = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/wallets/type/merchant`
		);
	public getWalletByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/wallets/${params.id}`
		);
	public createWallet = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/wallets`,
			data.data
		);
	public updateWallet = (data: Wallet): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/wallets`,
			data
		);
	public deleteWallet = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/wallets/${obj.id}`
		);

	public getBaseARTemplates = (): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(`/baseartemplates`);

	public getARTemplates = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/artemplates`
		);

	public getARTemplateByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/artemplates/${params.id}`
		);
	public createARTemplate = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/artemplates`,
			data.data
		);
	public updateARTemplate = (
		data: ARTemplate
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/artemplates`,
			data
		);
	public deleteARTemplate = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/artemplates/${obj.id}`
		);

	public getVoucherCodes = (
		params: RequestByCampaignID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/voucherconfig`
		);
	public getVoucherCodesByID = (
		params: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.get<Response>(
			`/campaigns/${params.campaignID}/voucherconfig/${params.id}`
		);
	public createVoucherCodes = (
		data: CampaignObjectRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>(
			`/campaigns/${data.campaignID}/voucherconfig`,
			data.data
		);
	public updateVoucherCodes = (
		data: VoucherCodes
	): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>(
			`/campaigns/${data.campaignID}/voucherconfig`,
			data
		);
	public deleteVoucherCodes = (
		obj: RequestByID
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(
			`/campaigns/${obj.campaignID}/voucherconfig/${obj.id}`
		);

	public getCustomElements = (params?: {
		[key: string]: string;
	}): Promise<AxiosResponse<Container[]>> =>
		this.instance.get<Container[]>("/customElements", params);
	public getCustomElementByID = (
		id: string
	): Promise<AxiosResponse<Container>> =>
		this.instance.get<Container>(`/customElements/${id}`);
	public createCustomElement = (
		data: Container
	): Promise<AxiosResponse<Container[]>> =>
		this.instance.post<Container[]>("/customElements", data);
	public updateCustomElement = (
		data: Container
	): Promise<AxiosResponse<Container[]>> =>
		this.instance.put<Container[]>(`/customElements/${data._id}`, data);
	public deleteCustomElement = (
		id: string
	): Promise<AxiosResponse<Container[]>> =>
		this.instance.delete<Container[]>(`/customElements/${id}`);

	public getBrands = (params?: {
		[key: string]: string;
	}): Promise<AxiosResponse<Brand[]>> =>
		this.instance.get<Brand[]>("/brands", params);
	public getBrandByID = (id: string): Promise<AxiosResponse<Brand>> =>
		this.instance.get<Brand>(`/brands/${id}`);
	public createBrand = (data: Brand): Promise<AxiosResponse<Brand[]>> =>
		this.instance.post<Brand[]>("/brands", data);
	public updateBrand = (data: Brand): Promise<AxiosResponse<Brand[]>> =>
		this.instance.put<Brand[]>(`/brands/${data.id}`, data);
	public deleteBrand = (id: string): Promise<AxiosResponse<Brand[]>> =>
		this.instance.delete<Brand[]>(`/brands/${id}`);

	public getManagers = (params?: {
		[key: string]: string;
	}): Promise<AxiosResponse<Manager[]>> =>
		this.instance.get<Manager[]>("/managers", params);
	public createManager = (data: {
		name: string;
		email: string;
	}): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/managers", data);
	public updateManager = (data: Manager): Promise<AxiosResponse<Response>> =>
		this.instance.put<Response>("/managers", data);
	public deleteManager = (
		idDeleted: string
	): Promise<AxiosResponse<Response>> =>
		this.instance.delete<Response>(`/managers/${idDeleted}`);
	public loginManager = (
		data: LoginRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/managers/login", data);
	public refreshToken = (token: string): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/managers/refresh", { token: token });
	public respondToChallenge = (
		data: ChallengeRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("managers/challenge", data);
	public forgotPassword = (
		data: ForgotPasswordRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/managers/forgot", data);
	public confirmForgotPassword = (
		data: ConfirmForgotPasswordRequest
	): Promise<AxiosResponse<Response>> =>
		this.instance.post<Response>("/managers/confirm", data);
}
