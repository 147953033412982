export const customComponentTypes: { [key: string]: any } = {
	"claim-item": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Claim Item",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: true,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"Are you sure you would like to claim this item?",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "claim-item",
									value: "claim-item"
								},
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: {
									id: "claim-item",
									text: "Click me!"
								},
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"redeem-qr-success": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "QR Scan Success",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"You have successfully redeemed your prize!",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: true,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"incorrect-qr-code": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Error",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "Incorrect QR code",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"already-redeemed-qr": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "VAT Already Redeemed",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "The QR code has already been scanned",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"redeem-momint-nft-success": {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Momint NFT redemption success",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"You have successfully redeemed your prize!",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"already-redeemed-momint-nft": {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "VAT Already Redeemed",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "Your NFT has already been redeemed",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"redeem-voucher-prompt": {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Redeem Voucher Code",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"Are you sure you would like to redeem this voucher code?",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "redeem-item",
									value: "redeem-item"
								},
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: {
									id: "redeem-item",
									text: "Click me!"
								},
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"redeem-voucher-success": {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Redemption Success",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"Your voucher code has be redeemed successfully.",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"already-redeemed-voucher": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Already redeemed",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "15px" },
								attributes: { id: "voucher-code" },
								containers: [],
								_id: "21629aeb-2bf1-4df3-bd61-9831acf23bf1",
								text: "{voucher-code}",
								type: "Header"
							},
							{
								_id: "05bac8dc-21b5-4896-a2f7-bd01d4d5d7db",
								name: "Paragraph",
								active: false,
								type: "Paragraph",
								text:
									"This voucher code has already been redeemed.",
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								elements: []
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: true,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"redeem-url-voucher-prompt": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Redeem URL Voucher Code",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"Are you sure you would like to redeem this URL voucher code?",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: true,
								action: {
									type: "redeem-url-item",
									value:
										"url=takealot.com?voucher={voucher-code}, target=_blank"
								},
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"already-redeemed-url-voucher": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Voucher Code URL Already Redeemed",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "15px" },
								attributes: { id: "voucher-code" },
								containers: [],
								_id: "c09b03b2-d579-4afa-88ec-6ee5fe1f7878",
								text: "{voucher-code}",
								type: "Header"
							},
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "807ef4df-3c0e-471e-b539-ea3c82627198",
								text: "has already been redeemed.",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: true,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"tap-to-redeem-prompt": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: true,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Tap to Redeem",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"Are you sure you would like to redeem this prize?",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "redeem-item",
									value: "redeem-item"
								},
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: {
									id: "redeem-item",
									text: "Click me!"
								},
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"tap-to-redeem-success": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Redemption Successful",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "Your prize has been redeemed.",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: true,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"already-redeemed-tap-to-redeem": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Already redeemed",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: true,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "05bac8dc-21b5-4896-a2f7-bd01d4d5d7db",
								text: "This prize has already been redeemed.",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"out-of-drop-zone": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Sorry",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "No VATs within your current location.",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: true,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"max-items-claimed": {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Maximum VATs Claimed",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text:
									"You have already claimed the maximum number of VATs",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	"out-of-items": {
		elements: [],
		active: false,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Out of Items",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "All items have already been claimed. ",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: { type: "go-to-page", value: "vault" },
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	},
	popup: {
		elements: [],
		active: true,
		action: { type: "none", value: "" },
		styles: {
			backgroundColor: "#00000014",
			alignItems: "center",
			textAlign: "left",
			flexDirection: "column",
			display: "flex",
			fontStyle: "normal",
			justifyContent: "center",
			flexWrap: "no-wrap",
			width: "100%",
			position: "relative",
			borderStyle: "none",
			backgroundRepeat: "no-repeat",
			height: "100%"
		},
		attributes: { id: "" },
		containers: [
			{
				elements: [],
				active: false,
				action: { type: "none", value: "" },
				styles: {
					padding: "20px",
					backgroundColor: "#ffffffff",
					textAlign: "left",
					flexDirection: "column",
					display: "flex",
					fontStyle: "normal",
					borderRadius: "20px",
					flexWrap: "no-wrap",
					width: "80%",
					position: "relative",
					borderStyle: "none",
					backgroundRepeat: "no-repeat",
					height: "auto"
				},
				attributes: {},
				containers: [
					{
						elements: [
							{
								elements: [],
								name: "Header",
								active: false,
								action: { type: "", value: "" },
								styles: {
									color: "#000",
									borderStyle: "none",
									fontSize: "20px"
								},
								attributes: {},
								containers: [],
								_id: "ce6145c0-7e5c-4fc9-bb6a-ee3dbb0a5008",
								text: "Popup Header",
								type: "Header"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							borderColor: "#dededeff",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							borderWidth: "0 0 1px 0",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "e4b2ffb4-0a54-45e6-ae24-6cde1c021052",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Paragraph",
								active: false,
								action: { type: "", value: "" },
								styles: { color: "#000", fontSize: "12px" },
								attributes: { text: "Paragraph" },
								containers: [],
								_id: "1d954b20-412c-4c04-b768-6e840989bf3f",
								text: "Popup content",
								type: "Paragraph"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0px",
							flexWrap: "no-wrap",
							textAlign: "left",
							flexDirection: "column",
							display: "flex",
							width: "100%",
							position: "relative",
							borderStyle: "none",
							fontStyle: "normal",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "d45afc1e-dcb7-4975-8fb9-c89d91950d79",
						type: "Container"
					},
					{
						elements: [
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "close-popup",
									value: "close-popup"
								},
								styles: {
									backgroundColor: "#ffffffff",
									borderStyle: "solid",
									borderRadius: "20px",
									borderWidth: "1px"
								},
								attributes: { id: "", text: "Click me!" },
								containers: [],
								_id: "56977302-dc6e-4d63-b851-1c99392818bd",
								text: "Cancel",
								type: "Button"
							},
							{
								elements: [],
								name: "Button",
								active: false,
								action: {
									type: "claim-item",
									value: "claim-item"
								},
								styles: {
									backgroundColor: "#5ab5faff",
									borderColor: "#5ab5faff",
									borderStyle: "solid",
									borderRadius: "20px",
									color: "#ffffffff",
									borderWidth: "1px"
								},
								attributes: {
									id: "claim-item",
									text: "Click me!"
								},
								containers: [],
								_id: "796ee73d-25c6-4358-8395-22a4350b5b54",
								text: "OK",
								type: "Button"
							}
						],
						active: false,
						action: { type: "none", value: "" },
						styles: {
							padding: "10px 0 0 0",
							borderColor: "#dededeff",
							alignItems: "center",
							textAlign: "left",
							flexDirection: "row",
							display: "flex",
							fontStyle: "normal",
							justifyContent: "flex-end",
							columnGap: "10px",
							flexWrap: "no-wrap",
							borderWidth: "1px 0 0 0 ",
							width: "100%",
							position: "relative",
							borderStyle: "solid",
							backgroundRepeat: "no-repeat",
							height: "100%"
						},
						attributes: {},
						containers: [],
						_id: "452335ec-6af4-40e5-81c1-b14df19fdd4e",
						type: "Container"
					}
				],
				_id: "299f93c2-77e8-4734-848c-64fedf37ffc2",
				type: "Container"
			}
		],
		_id: "462fcde1-b592-4929-bbb2-df3622f69ebf",
		type: "Container"
	}
};
