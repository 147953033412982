import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { Manager } from "../types/manager";
import ApiProtected from "../utils/api-protected";
import { ComputedRef, computed } from "vue";

VuexModule.__useHotUpdate = true;

@Module
class ManagerStore extends VuexModule {
	api = ApiProtected.getInstance();
	// state
	items: Manager[] = [];
	selectedItemID = "";

	// getters
	get getItems(): ComputedRef<Manager[]> {
		return computed(() => this.items);
	}
	get getSelectedItem(): ComputedRef<Manager | undefined> {
		return computed(() =>
			this.items.find(e => e.id == this.selectedItemID)
		);
	}

	// mutations
	@Mutation
	commitSaveItems(items: Manager[]) {
		this.items = items;
		console.log(this.items);
	}
	@Mutation
	commitSaveItem(item: Manager) {
		const index = this.items.findIndex(e => e.id == item.id);
		if (index == -1) {
			this.items.push(item);
		} else {
			this.items[index] = item;
		}
	}
	@Mutation
	commitDeleteItem(itemID: string) {
		const index = this.items.findIndex(e => e.id == itemID);
		if (index != -1) {
			this.items.splice(index, 1);
		}
	}
	@Mutation
	commitSelectedItemID(itemID: string) {
		this.selectedItemID = itemID;
	}

	// actions
	@Action
	async dispatchGetItems(): Promise<boolean> {
		const response: Response = await this.api.getManagers();
		console.log(response);
		if (response.success === true) {
			const items: Manager[] = [];
			for (const item of response.data as CognitoUser[]) {
				const manager: Manager = {
					id: item.Username,
					status: item.UserStatus,
					createdAt: item.UserCreateDate,
					enabled: item.Enabled
				};
				for (const element of item.Attributes!) {
					switch (element.Name) {
						case "name":
							manager.name = element.Value;
							break;
						case "email":
							manager.email = element.Value;
							break;
					}
				}
				items.push(manager);
			}
			console.log(items);
			this.commitSaveItems(items);
			return true;
		} else {
			return false;
		}
	}

	@Action
	async dispatchCreateItem(item: Manager): Promise<Response> {
		console.log("Creating", item);
		const itemCreated = await this.api.createManager({
			name: item.name!,
			email: item.email!.toLowerCase()
		});
		console.log(itemCreated);
		if (itemCreated.success === true) this.commitSaveItem(itemCreated.data);
		return itemCreated;
	}

	@Action
	async dispatchUpdateItem(item: Manager): Promise<Response> {
		const itemUpdated = await this.api.updateManager(item);
		console.log(itemUpdated);
		if (itemUpdated.success === true) this.commitSaveItem(itemUpdated.data);
		return itemUpdated;
	}

	@Action
	async dispatchDeleteItem(itemID: string): Promise<Response> {
		const itemDeleted = await this.api.deleteManager(itemID);
		if (itemDeleted.success === true) this.commitDeleteItem(itemID);
		return itemDeleted;
	}
	@Action
	async managerLogin(item: LoginRequest): Promise<Response> {
		item.email = item.email.toLowerCase();
		const loginResponse = await this.api.loginManager(item);
		console.log(loginResponse);
		if (loginResponse.data.AuthenticationResult) {
			localStorage.setItem(
				"AccessToken",
				loginResponse.data.AuthenticationResult.AccessToken
			);
			localStorage.setItem(
				"IdToken",
				loginResponse.data.AuthenticationResult.IdToken
			);
			localStorage.setItem(
				"RefreshToken",
				loginResponse.data.AuthenticationResult.RefreshToken
			);
		}
		return loginResponse;
	}
	@Action
	async respondToChallenge(item: ChallengeRequest): Promise<Response> {
		const challengeResponse = await this.api.respondToChallenge(item);
		if (challengeResponse.data.AuthenticationResult) {
			localStorage.setItem(
				"AccessToken",
				challengeResponse.data.AuthenticationResult.AccessToken
			);
			localStorage.setItem(
				"IdToken",
				challengeResponse.data.AuthenticationResult.IdToken
			);
			localStorage.setItem(
				"RefreshToken",
				challengeResponse.data.AuthenticationResult.RefreshToken
			);
		}
		return challengeResponse;
	}

	@Action
	async forgotPassword(data: ForgotPasswordRequest): Promise<boolean> {
		const response = await this.api.forgotPassword(data)
		return response.success
	}

	@Action
	async confirmForgotPassword(data: ConfirmForgotPasswordRequest): Promise<boolean> {
		const response = await this.api.confirmForgotPassword(data)
		return response.success
	}
}

// register module (could be in any file)
import store from "./";
import { Response } from "@/utils/response";
import { ChallengeRequest, ConfirmForgotPasswordRequest, ForgotPasswordRequest, LoginRequest } from "@/types/requests";
import { CognitoUser } from "@/types/cognitoUser";
export const managerStore = new ManagerStore({ store, name: "manager" });
