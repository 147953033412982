
import { Action } from "@/types/customPage";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	props: {
		element: {
			type: Object,
			required: true,
		},
		editable: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	setup(props, { emit }) {
		//variables
		const router = useRouter();
		const elementRef = ref(props.element);
		const edit = ref(false);
		const inputWidth = ref("0px");

		function gotoPage(page: string) {
			router.push({
				path: page,
			});
		}
		function doAction(action: Action) {
			if (action.type == "go-to-page") {
				gotoPage(action.value);
			}
		}
		function editElement($event: any) {
			inputWidth.value = `${$event.target.clientWidth}px`;
			edit.value = true;
		}

		return { elementRef, edit, doAction, editElement, inputWidth };
	},
});
