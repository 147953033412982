
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import {
	blankWallet,
	createWallet,
	updateWallet,
	validationRules
} from "@/composables/models/use-wallets";
import { Wallet } from "@/types/wallet";
import { defineComponent, ref, watch } from "vue";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { CampaignObjectRequest } from "@/types/requests";
import { Asset } from "@/types/asset";
import AssetManagerComponent from "@/components/assets/AssetManagerComponent.vue";
import { useRoute } from "vue-router";

export default defineComponent({
	components: { AssetManagerComponent },
	props: {
		wallet: {
			type: Object,
			required: true
		},
		method: {
			type: String,
			required: true
		}
	},
	emits: ["saved"],
	setup(props, { emit }) {
		//variables
		const route = useRoute();
		const formRef = ref();
		let walletRef = ref(JSON.parse(JSON.stringify(props.wallet)));
		const rules = validationRules();
		const showAssetManager = ref(false);
		const campaignID = route.params.campaignID.toString();
		//dispatchers

		//getters

		//functions
		watch(walletRef.value, () => {
			formRef.value.validate();
		});

		const onCancel = () => {
			if (props.method == "Create") walletRef.value = blankWallet();
			formRef.value.resetFields();
			emit("saved");
		};
		const onSubmit = () => {
			formRef.value
				.validate()
				.then(() => {
					if (props.method == "Create") {
						const campaignObj: CampaignObjectRequest = {
							campaignID: campaignID,
							data: walletRef.value as Wallet
						};
						console.log(campaignObj);
						createWallet(campaignObj).then((response: Response) => {
							if (response.success === true) {
								message.success(response.message);
							} else {
								message.error(response.message);
							}
							walletRef.value = blankWallet();
							emit("saved");
						});
					} else if (props.method == "Update") {
						const updatedWallet = {
							id: walletRef.value.id,
							campaignID: walletRef.value.campaignID,
							name: walletRef.value.name,
							userID: walletRef.value.userID,
							type: walletRef.value.type,
							image: walletRef.value.image,
							vItems: walletRef.value.vItem
						};
						updateWallet(updatedWallet as Wallet).then(
							(response: Response) => {
								if (response.success === true) {
									message.success(response.message);
								} else {
									message.error(response.message);
								}
								emit("saved");
							}
						);
					}
				})
				.catch((error: ValidateErrorEntity<Wallet>) => {
					console.log("error", error);
				});
		};

		function setAsset(asset: Asset) {
			walletRef.value.image = asset.url;
			showAssetManager.value = false;
		}

		return {
			onCancel,
			onSubmit,
			walletRef,
			formRef,
			rules,
			showAssetManager,
			setAsset,
			campaignID
		};
	}
});
