import { dropPoolStore } from "@/store/dropPoolStore";
import {
	DropInterval,
	DropLocation,
	DropPool,
	DropType
} from "@/types/dropPool";
import { ComputedRef } from "vue";
import moment from "moment";
import { CampaignObjectRequest, RequestByID } from "@/types/requests";
import { Response } from "@/utils/response";
import { RequestByCampaignID } from "@/types/requests";

export function allDropPools(): ComputedRef<DropPool[]> {
	const items = dropPoolStore.getItems;
	return items;
}
export function selectedDropPool(
	itemID: string
): ComputedRef<DropPool | undefined> {
	dropPoolStore.commitSelectedItemID(itemID);
	const item = dropPoolStore.getSelectedItem;
	return item;
}
export async function getDropPool(
	params: RequestByCampaignID
): Promise<boolean> {
	return dropPoolStore.dispatchGetItems(params);
}
export async function createDropPool(
	item: CampaignObjectRequest
): Promise<Response> {
	// const uuid = uuidv4();
	// item.id = uuid;
	// dropPoolStore.commitSaveItem(item);
	// return item;
	return await dropPoolStore.dispatchCreateItem(item);
}

export async function updateDropPool(item: DropPool): Promise<Response> {
	return await dropPoolStore.dispatchUpdateItem(item);
}

export async function deleteDropPool(delObj: RequestByID): Promise<Response> {
	// dropPoolStore.commitDeleteItem(itemID);
	// return true;
	return await dropPoolStore.dispatchDeleteItem(delObj);
}

export function blankDropPool(): DropPool {
	const startDate = moment().format("");
	const endDate = moment()
		.add(1, "week")
		.format("");
	const dropPool: DropPool = {
		name: "",
		dropType: DropType.PIN,
		startDate: startDate,
		endDate: endDate,
		dropInterval: DropInterval.SINGLE, //single or daily
		dropIntervalStart: "00:00", //time start
		dropIntervalEnd: "23:59", //time end
		dropUserRadius: 20, //radius to drop to user
		minDistanceFromUser: 5,
		dropLocationRadius: 200, //radius to for active gps drop location
		dropSize: 5, //Number of items to drop to the user
		maxClaimSize: 1,
		maxClaimInterval: "day",
		dropLocations: [],
		categorySplit: [],
		prizeSplit: [],
		triggeredOn: null,
		triggerCount: 0,
		useEnhancedAI: false,
		ARTemplateID: ""
	};
	return dropPool;
}

export function blankDropLocation(): DropLocation {
	const dropLocation: DropLocation = {
		placeName: "",
		latitude: "",
		longitude: ""
	};
	return dropLocation;
}

export function dropLocationValidationRules() {
	const rules = {
		placeName: [
			{ required: true, message: "Name is required", trigger: "blur" }
		]
		// latitude: [
		// 	{ required: true, message: "Latitude is required", trigger: "blur" },
		// ],
		// longitude: [
		// 	{ required: true, message: "Longitude is required", trigger: "blur" },
		// ],
	};
	return rules;
}
