
import { defineComponent, ref, watch } from "vue";
import Container from "@/components/artwork/Container.vue";
import { selectLayout } from "@/composables/models/use-containers";

export default defineComponent({
	components: {
		Container
	},
	emits: ["update"],
	props: {
		layout: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup(props, { emit }) {
		//variables
		let layoutRef = ref(props.layout);

		watch(props, () => {
			layoutRef.value = props.layout;
		});
		function updatePage() {
			console.log("emitting update");
			emit("update");
		}
		//dispatchers

		//getters

		//functions

		return { layoutRef, updatePage };
	}
});
