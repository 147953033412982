import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    autocomplete: "off",
    style: _normalizeStyle(_ctx.elementRef.styles),
    placeholder: _ctx.elementRef.attributes.placeholder
  }, null, 12, _hoisted_1))
}