
import {
	defineComponent,
	ref,
	computed,
	onBeforeMount,
	ComputedRef,
} from "vue";
import Scroller from "@/components/utils/Scroller.vue";
import Search from "@/components/utils/Search.vue";
import {
	allManagers,
	blankManager,
	getManagers,
} from "../composables/models/use-managers";
import { Manager } from "@/types/manager";
import ManagerItemComponent from "@/components/manager/ManagerItemComponent.vue";
import UpdateCreateManagerForm from "@/components/forms/UpdateCreateManagerForm.vue";

export default defineComponent({
	setup() {
		//variables
		const showModal = ref(false);
		let managers: ComputedRef<Manager[]> = computed(() => []);
		let filteredManagers = ref(managers.value);
		let newManager = blankManager();
		const manager = computed(() => newManager);
		let filterString = ref("");
		//dispatchers
		onBeforeMount(async () => {
			await getManagers();
			managers = allManagers();
			console.log(managers);
			filteredManagers.value = ref(managers.value).value;
		});

		//getters

		//functions
		const createManager = () => {
			showModal.value = true;
		};
		const onCancel = () => {
			showModal.value = false;
		};
		const filtered = (searchString: string) => {
			filterString.value = searchString;
			filteredManagers.value = managers.value.filter((e) => {
				return e.name?.toLowerCase().includes(searchString.toLowerCase());
			});
		};
		const modifiedManager = async () => {
			showModal.value = false;
			await getManagers();
			managers = allManagers();
			filterString.value = "";
			filteredManagers.value = ref(managers.value).value;
		};

		return {
			showModal,
			createManager,
			managers,
			filteredManagers,
			filtered,
			manager,
			onCancel,
			modifiedManager,
			filterString,
		};
	},
	components: {
		UpdateCreateManagerForm,
		ManagerItemComponent,
		Scroller,
		Search,
	},
});
