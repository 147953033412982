
import { defineComponent, ref, watch } from "vue";
import Container from "@/components/artwork/Container.vue";

export default defineComponent({
	components: {
		Container
	},
	props: {
		container: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup(props) {
		//variables
		let containerRef = ref(props.container);

		watch(props, () => {
			containerRef.value = props.container;
		});

		//dispatchers

		//getters

		//functions

		return { containerRef };
	}
});
