
import { defineComponent } from "vue";
// import Gantt from "@/components/gantt/Gantt.vue";
// import GanttHeader from "@/components/gantt/GanttHeader.vue";
// import GanttBody from "@/components/gantt/GanttBody.vue";
// import GanttSide from "@/components/gantt/GanttSide.vue";
import CreateDropPoolForm from "@/components/forms/CreateDropPoolForm.vue";
import PoolEditor from "@/components/pool/PoolEditor.vue";
import PoolInfo from "@/components/pool/PoolInfo.vue";
import { blankDropPool } from "@/composables/models/use-dropPools";
import { DropPool, DropType } from "@/types/dropPool";
import SideNavigation from "@/components/utils/SideNavigation.vue";
import { message } from "ant-design-vue";
import { RequestByID } from "@/types/requests";
import store from "@/store";
import { Campaign } from "@/types/campaign";
import moment from "moment";
import { GanttBarObject } from "@infectoone/vue-ganttastic";

export default defineComponent({
	data() {
		return {
			saving: false,
			showDeleteModal: false,
			moment: moment,
			gantRows: [] as GanttBarObject[][],
			rowIndex: 0,
			showModal: false,
			dropPool: null as DropPool | null
		};
	},
	mounted() {
		this.init();
	},
	computed: {
		dropPools(): DropPool[] {
			return store.getters["dropPoolStore/getItems"];
		},
		selectedDropPool(): DropPool {
			return store.getters["dropPoolStore/getSelectedItem"];
		},
		selectedCampaign(): Campaign {
			return store.getters["campaignStore/getSelectedItem"];
		}
	},
	methods: {
		async init() {
			await store.dispatch("dropPoolStore/dispatchGetItems");
			this.gantRows = this.constructGanttRows();
		},
		createDropPool() {
			this.dropPool = blankDropPool();
			this.dropPool.startDate = this.selectedCampaign.startDate;
			this.dropPool.endDate = this.selectedCampaign.endDate;
			this.showModal = true;
		},
		async handleSubmit() {
			const dropPool = store.getters["dropPoolStore/getSelectedItem"];
			this.saving = true;
			const response = await store.dispatch(
				"dropPoolStore/dispatchUpdateItem",
				dropPool
			);
			this.saving = false;
			if (response.success === true) {
				message.success(response.message);
			} else {
				message.error(response.message);
			}
		},
		async handleDelete() {
			this.showDeleteModal = false;
			if (this.selectedDropPool.id && this.selectedCampaign.id) {
				const delPool: RequestByID = {
					id: this.selectedDropPool.id,
					campaignID: this.selectedCampaign.id
				};
				const response = await store.dispatch(
					"dropPoolStore/dispatchDeleteItem",
					delPool
				);
				if (response.success === true) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				this.gantRows = this.constructGanttRows();
			}
		},
		gotoPage(name: string) {
			this.$router.push({
				name: name,
				params: { campaignID: this.selectedCampaign.id }
			});
		},
		selectDropPool(index: number) {
			this.rowIndex = index;
			store.commit(
				"dropPoolStore/commitSelectedItemID",
				this.dropPools[index].id
			);
		},
		constructGanttRows(): any[][] {
			const rows = [];
			for (let i = 0; i < this.dropPools.length; i++) {
				const pool = this.dropPools[i];
				let row = {
					myBeginDate: moment(pool.startDate).format("YYYY-MM-DD"),
					myEndDate: moment(pool.endDate).format("YYYY-MM-DD"),
					ganttBarConfig: {
						id: `row-${i}`,
						hasHandles: true,
						label: `${pool.name} - ${pool.dropType}`,
						style: {
							content: "test",
							background: this.barStyles(pool.dropType),
							borderRadius: "5px",
							color: "black"
						}
					}
				};
				rows.push([row]);
			}
			return rows;
		},
		barStyles(dropType: DropType): string {
			switch (dropType) {
				case "pin":
					return "#bbbbbb";
				case "gps":
					return "#98ec89";
				case "prepopulatedVat":
					return "#89cbec";
				case "shareReward":
					return "#ecc189";
				default:
					return "#bbbbbb";
			}
		},
		onDragendBar(bar: any, $event: any) {
			const pool = this.dropPools[this.rowIndex];
			pool.startDate = moment(bar.myBeginDate).format("YYYY-MM-DD");
			pool.endDate = moment(bar.myEndDate).format("YYYY-MM-DD");
			store.commit("dropPoolStore/commitSaveItem", pool);
			this.gantRows = this.constructGanttRows();
		},
		modifiedDropPool() {
			this.showModal = false;
			console.log("saved!!!!!");
			console.log(this.dropPools);
			this.gantRows = this.constructGanttRows();
		},
		getLabel(index: number): string {
			if (this.dropPools[index]) {
				return this.dropPools[index].name;
			} else {
				return "";
			}
		}
	},

	components: {
		PoolEditor,
		PoolInfo,
		SideNavigation,
		CreateDropPoolForm
	}
});
