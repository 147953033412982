
import { ref, computed } from "vue";
import { defineComponent } from "vue";
import UpdateCreateVItemCategoryForm from "@/components/forms/UpdateCreateVItemCategoryForm.vue";
import {
	deleteCategory,
	selectCategory
} from "@/composables/models/use-vItemCategories";
import { RequestByID } from "@/types/requests";
import { Response } from "@/utils/response";
import { message } from "ant-design-vue";
import { selectLayout } from "@/composables/models/use-containers";

export default defineComponent({
	props: {
		category: {
			type: Object,
			required: true
		}
	},
	emits: ["delete", "update"],
	setup(props, { emit }) {
		//variables
		const showModal = ref(false);
		const categoryRef = computed(() => props.category);
		let showDeleteModal = ref(false);
		//dispatchers

		//getters

		//functions
		const onCancel = () => {
			showModal.value = false;
		};
		const onCancelDelete = () => {
			showDeleteModal.value = false;
		};
		const onDelete = () => {
			showDeleteModal.value = true;
		};
		const handleDelete = (id: string, campaignID: string) => {
			showDeleteModal.value = false;
			const delCategory: RequestByID = {
				id: id,
				campaignID: campaignID
			};
			deleteCategory(delCategory).then((response: Response) => {
				if (response.success === true) {
					message.success(response.message);
				} else {
					message.error(response.message);
				}
				emit("delete");
			});
		};
		const changesSaved = () => {
			showModal.value = false;
			emit("update");
		};
		function gotoVATConfigurator(categoryID: string) {
			selectCategory(categoryID);
			selectLayout(categoryRef.value!.unAllocatedComponent!);

			// router.push({
			// 	name: "Prizes",
			// 	params: { campaignID: prizeRef.value.campaignID }
			// });
		}

		return {
			onCancel,
			showModal,
			categoryRef,
			showDeleteModal,
			handleDelete,
			onDelete,
			onCancelDelete,
			changesSaved,
			gotoVATConfigurator
		};
	},
	components: {
		UpdateCreateVItemCategoryForm
	}
});
