
import { defineComponent, onMounted, ref } from "vue";
// import PrizeManagerComponent from "@/components/prize/PrizeManagerComponent.vue";
import WalletManagerComponent from "@/components/wallet/WalletManagerComponent.vue";
import { useRoute } from "vue-router";
import SideNavigation from "@/components/utils/SideNavigation.vue";

export default defineComponent({
	props: {},
	setup() {
		//variables
		const route = useRoute();

		//dispatchers

		//getters

		//functions

		return {};
	},
	components: {
		WalletManagerComponent,
		SideNavigation
	}
});
