
import { Response } from "@/utils/response";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import {
	blankLogin,
	confirmPasswordManager,
	forgotPasswordManager,
	loginManager,
	validationRules
} from "@/composables/models/use-managers";
import {
	ChallengeRequest,
	ConfirmForgotPasswordRequest,
	ForgotPasswordRequest,
	LoginRequest
} from "@/types/requests";

export default defineComponent({
	emits: ["signup", "newPassword"],
	props: {},
	setup(props, { emit }) {
		//variables
		let forgotModalVisible = ref(false);
		let forgotModalLoading = ref(false);
		let forgotModalRef = ref({ email: "" } as ForgotPasswordRequest);

		let confirmModalVisible = ref(false);
		let confirmModalLoading = ref(false);
		let confirmModal = ref({
			email: "",
			confirmationCode: "",
			password: ""
		} as ConfirmForgotPasswordRequest);

		const formLoginRef = ref();
		const router = useRouter();
		let managerLoginRef = ref(blankLogin());

		const rules = validationRules();
		//dispatchers

		//getters

		//functions
		const signin = () => {
			formLoginRef.value.validate().then(() => {
				loginManager(managerLoginRef.value as LoginRequest).then(
					(response: Response) => {
						if (response.success === true) {
							if (response.data.ChallengeName) {
								const challengeData: ChallengeRequest = {
									ChallengeName: response.data.ChallengeName,
									Session: response.data.Session,
									email: managerLoginRef.value.email,
									password: ""
								};
								emit("newPassword", challengeData);
							} else {
								message.success(response.message);
								router.push("/console/campaigns");
							}
						} else {
							if (response.success === false) {
								message.error(response.message);
							} else {
								message.error(response.message);
							}
						}
					}
				);
			});
		};

		const openForgotModal = () => {
			forgotModalVisible.value = true;
		};

		const forgotCancelHandle = () => {
			forgotModalVisible.value = false;
			forgotModalRef.value = { email: "" } as ForgotPasswordRequest;
		};

		const confirmCancelHandle = () => {
			confirmModalVisible.value = false;
			confirmModal.value = {
				email: "",
				confirmationCode: "",
				password: ""
			} as ConfirmForgotPasswordRequest;
		};

		const confirmOkHandle = () => {
			confirmModalLoading.value = true;
			confirmModal.value.email = forgotModalRef.value.email;
			confirmPasswordManager(confirmModal.value)
				.then(response => {
					confirmModalLoading.value = false;
					confirmModalVisible.value = false;
					if (!response) {
						message.error("Unauthorised");
					}
				})
				.catch(error => {
					confirmModalLoading.value = false;
					message.error(error.response.data.DisplayText);
				});
		};

		const forgotPasswordHandle = () => {
			forgotModalLoading.value = true;
			forgotModalRef.value.email = forgotModalRef.value.email.toLowerCase();
			forgotPasswordManager(forgotModalRef.value)
				.then((response: boolean) => {
					forgotModalLoading.value = false;
					forgotModalVisible.value = false;
					confirmModalVisible.value = true;
					if (!response) {
						message.error("something went wrong");
					}
				})
				.catch(error => {
					forgotModalLoading.value = false;
					console.log(error);
					message.error("Invalid email address");
				});
		};

		return {
			signin,
			openForgotModal,
			forgotPasswordHandle,
			forgotCancelHandle,
			confirmCancelHandle,
			confirmOkHandle,
			confirmModal,
			confirmModalVisible,
			confirmModalLoading,
			managerLoginRef,
			rules,
			formLoginRef,
			forgotModalVisible,
			forgotModalLoading,
			forgotModalRef
		};
	}
});
