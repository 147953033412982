import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-330a001b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "content-main",
  style: {"padding-top":"20px"}
}
const _hoisted_2 = {
  key: 0,
  class: "bread-crumb"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "panel stretch-height" }
const _hoisted_6 = { class: "panel stretch-height" }
const _hoisted_7 = {
  key: 0,
  style: {"padding":"0px 20px 50px 40px","position":"relative"},
  class: "panel stretch-height"
}
const _hoisted_8 = { style: {"position":"absolute","top":"-20px","left":"-10px"} }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNavigation = _resolveComponent("SideNavigation")!
  const _component_VItemCategoryManagerComponent = _resolveComponent("VItemCategoryManagerComponent")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_StylesEditor = _resolveComponent("StylesEditor")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_AttributesEditor = _resolveComponent("AttributesEditor")!
  const _component_ActionsEditor = _resolveComponent("ActionsEditor")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_Scroller = _resolveComponent("Scroller")!
  const _component_ShortCuts = _resolveComponent("ShortCuts")!
  const _component_ArtworkComponent = _resolveComponent("ArtworkComponent")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_ctx.campaignID)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SideNavigation),
        (_ctx.campaignRef)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, "Campaigns / " + _toDisplayString(_ctx.campaignRef.name) + " / VATs", 1),
              (_ctx.vItem)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.vItem.name ? ` / ${_ctx.vItem.name}` : ``), 1))
                : _createCommentVNode("", true),
              (_ctx.component)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.component ? ` / ${_ctx.component}` : ``), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_row, {
          gutter: 10,
          style: {"height":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 8 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_VItemCategoryManagerComponent)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 16 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_row, {
                    gutter: 10,
                    style: {"height":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Scroller, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_collapse, {
                                activeKey: _ctx.activeKey,
                                "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_collapse_panel, {
                                    key: "2",
                                    header: "Styles"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_StylesEditor, { campaignID: _ctx.campaignID }, null, 8, ["campaignID"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_collapse_panel, {
                                    key: "3",
                                    header: "Attributes"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_AttributesEditor)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_collapse_panel, {
                                    key: "4",
                                    header: "Actions"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ActionsEditor)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["activeKey"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          (_ctx.layout)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                  (_ctx.campaignID)
                                    ? (_openBlock(), _createBlock(_component_ShortCuts, {
                                        key: 0,
                                        campaignID: _ctx.campaignID
                                      }, null, 8, ["campaignID"]))
                                    : _createCommentVNode("", true)
                                ]),
                                (_ctx.vItem?.unAllocatedComponent?._id)
                                  ? (_openBlock(), _createBlock(_component_ArtworkComponent, {
                                      editable: _ctx.editable,
                                      key: _ctx.vItem?.unAllocatedComponent?._id,
                                      container: _ctx.vItem?.unAllocatedComponent
                                    }, null, 8, ["editable", "container"]))
                                  : _createCommentVNode("", true),
                                (!_ctx.vItem?.unAllocatedComponent?._id)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Select prize to start editing "))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_a_button, {
                                  loading: _ctx.saving,
                                  style: {"margin-top":"10px","margin-right":"10px"},
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveVItem()))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Save")
                                  ]),
                                  _: 1
                                }, 8, ["loading"]),
                                (_ctx.layout._id)
                                  ? (_openBlock(), _createBlock(_component_a_button, {
                                      key: 2,
                                      style: {"margin-top":"10px"},
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (
										_ctx.editable
											? (_ctx.editable = false)
											: (_ctx.editable = true)
									))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.editable ? "Preview" : "Edit"), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}